import React from 'react'
import './index.less'

interface TableLoadingProps {
    title?: string
}


const TableLoading: React.FC<TableLoadingProps> = (props) => {

    return (
        <div className='tableloading'>
            <div className='tableloading-line'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <img src={require(`../../assets/images/loading1.png`)} alt="" />
            <p>{ props?.title ? props.title : '加载中…'}</p>
        </div>
    )
}

export default TableLoading