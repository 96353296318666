
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  routes: [],
  admin: [] as any
};

export const breadcrumbSlice = createSlice({
  name: "breadcrumbSpace", // 命名空间，在调用action的时候会默认的设置为action的前缀,保证唯一.不重名
  initialState,
  reducers: {
    // reducer函数 state当前组件的数据 
    //第二个参数为{payload:{},type:"""} 
    // 更新路由
    updateRoutes(state, { payload }) {
      state.routes = payload.routes
    },
    setAdminCrumb(state, { payload }) {
      state.admin = payload || []
    },
    delAdminCrumb(state, { payload }) {
      state.admin = state.admin.slice(0, state.admin?.length - 1)
    },
    addAdminCrumb(state, { payload }) {
      if (state?.admin?.some((item: any) => item.title === payload?.title)) return
      state.admin = [...state.admin, payload]
    }
  },
});
export const { updateRoutes, setAdminCrumb, delAdminCrumb, addAdminCrumb } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
