import React, { useEffect, useState } from 'react'
import './index.less'

type LoginHeaderProps = {
    style?: React.CSSProperties
    className?: string
    type?: 'login' | 'register' | 'all'   // 默认all
    checked?: 'login' | 'register'        // 当type为all时，初始时默认选中哪个 默认选中 login
    onChange?: (type: 'login' | 'register') => void
}

const LoginHeader: React.FC<LoginHeaderProps> = (props) => {

    const { style, className = '', type = 'all', checked = 'login', onChange } = props

    const [nav, setNav] = useState<any>([])
    const [current, setCurrent] = useState<'login' | 'register'>()

    useEffect(() => {
        const list = [
            {
                id: 'login',
                title: '登录'
            },
            {
                id: 'register',
                title: '注册'
            }
        ]
        switch (type) {
            case 'all':
                setNav(list)
                setCurrent(checked)
                break;
            case 'login':
                setNav([list[0]])
                setCurrent('login')
                break;
            case 'register':
                setNav([list[1]])
                setCurrent('register')
                break;
        }
    }, [type, checked])

    
    return (
        <div style={style} className={className ? `${className} login-header` : 'login-header'}>
            {
                nav?.map((item: any) => {
                    return <span
                        className={item?.id === current ? 'active' : ''}
                        onClick={() => {
                            setCurrent(item?.id)
                            onChange?.(item?.id)
                        }}
                        key={item?.id}
                    >{item?.title}</span>
                })
            }
        </div>
    )
}

export default LoginHeader