import http from "../index";

import config from '../../config'
import axios from "axios";

const ojURL = config?.ojURL

// 题目列表
export async function getExamQuestionList(params: any) {
  return http.post(`/exam/question/page`, {
    category: params?.category,
    current: params?.current,
    difficulty: params?.difficulty,
    examCode: params?.examCode,
    keyword: params?.keyword,
    size: params?.size,
    type: params?.type
  })
}

// 删除题目
export async function deleteQuestion(params: any) {
  return http.delete(`/exam/question/delete/${params?.questionCode}`)
}

// 移动题目
export async function moveQuestion(params: any) {
  return http.post(`/exam/question/move`, {
    questionCode: params?.questionCode,
    index: params?.index
  })
}

// 获取考试详情
export async function getExamDetail(examCode: string) {
  return http.get(`/exam/${examCode}`)
}

// 保存考试详情
export async function saveExamDetail(params: any) {
  return http.post(`/exam/saveOrUpdate`, params)
}

//开始考试
export async function beginExam(examCode: string) {
  return http.post(`exam/study/begin/${examCode}`)
}

//考试交卷
export async function endExam(examRecordCode: string) {
  return http.post(`exam/study/end/${examRecordCode}`)
}
// 保存考试题目
export async function saveExamQues(params: any) {
  return http.post(`/exam/question/save`, params)
}

// 发布下架考试
export async function publishExam(params: any) {
  return http.post(`/exam/publish`, params)
}

// 考试列表
export async function getExamList(params: any) {
  return http.post(`/exam/list`, params)
}

// 点对点考试列表
export async function getPointExamList(params: any) {
  return http.post(`/exam/point/page`, params)
}

// 可复制考试列表查询
export async function getCopyExamList(params: any, cancelToken?: any) {
  return http.post(`/exam/copyPageList`, params, {cancelToken: cancelToken ? new axios.CancelToken(cancelToken) : undefined})
}

// 复制考试题目
export async function copyExamQues(params: any) {
  return http.post(`/exam/question/copyFromExam`, params)
}

// 点对点考试学生详情
export async function getPointExamDetail(examCode: string) {
  return http.get(`/exam/point/${examCode}`)
}

// 点对点考试登录
export async function pointExamLogin(data: any) {
  return http.post(`/exam/study/point/begin`, data)
}




// 考试删除
export async function delExam(examCode: string) {
  return http.delete(`/exam/${examCode}`)
}

// 考卷题目评分
export async function scoreAnswerQuestion(params: any) {
  return http.post(`/exam/question/answer/score`, {
    examRecordCode: params?.examRecordCode,
    questionCode: params?.questionCode,
    score: params?.score
  })
}

// 完成考卷判分
export async function scoreFinish(params: any) {
  return http.post(`/exam/finish/score/${params?.examRecordCode}`)
}

// 答卷列表
export async function getUserRecordList(params: any) {
  return http.post(`/exam/userRecordList`, {
    pageNo: params?.pageNo,
    pageSize: params?.pageSize,
    examCode: params?.examCode
  })
}

// 考试分析-考试信息
export async function getExamAnalysisDetail(examCode: string) {
  return http.get(`/exam/analysis/${examCode}`)
}

// 考试分析-成绩总览
export async function getExamAnalysisRank(params: any) {
  return http.post(`/exam/analysis/examRank`, {
    pageNo: params?.pageNo,
    pageSize: params?.pageSize,
    examCode: params?.examCode,
    desc: params?.desc
  })
}

// 判分题目列表（判分、查看考卷）
export async function getAnswerQuestionPage(params: any) {
  return http.post(`/exam/question/answer/page`, {
    current: params?.current,
    examCode: params?.examCode,
    examRecordCode: params?.examRecordCode,
    size: params?.size
  })
}

// 考题题目列表（考试、预览）
export async function getStudyQuestionPage(params: any) {
  return http.post(`/exam/question/study/page`, {
    current: params?.current,
    examCode: params?.examCode,
    examRecordCode: params?.examRecordCode,
    size: params?.size
  })
}

// 考试中 题目答案保存
export async function saveStudyQuestion(params: any) {
  return http.post(`/exam/question/study/save`, {
    answer: params?.answer,
    examRecordCode: params?.examRecordCode,
    questionCode: params?.questionCode,
    language: params?.language,
    tempAnswer: params?.tempAnswer,
  })
}

// 考试记录查询
export async function getExamRecord(params: any) {
  return http.get(`/exam/record/${params?.examRecordCode}`)
}

// 考试判分-考卷&用户信息
export async function getExamScoreInfo(params: any) {
  return http.get(`/exam/scoreInfo`, {
    examCode: params?.examCode,
    examRecordCode: params?.examRecordCode
  })
}

// 完成所有考试判分
export async function scoreFinishAll(params: any) {
  return http.post(`/exam/finishAll/score/${params?.examCode}`)
}

// 考试判分-获取考卷判分记录列表
export async function getExamPaperList(params: any) {
  return http.get(`/exam/examPaperList`, {
    examCode: params?.examCode
  })
}

// 考试判分-获取考生的考卷详情，是否已经判分
export async function getExamPaperDetail(params: any) {
  return http.get(`/exam/record/detail/${params?.examRecordCode}`)  
}

// 管理实操题参数申请
export async function applyPractice(params: any) {
  return http.post(`/exam/question/practice/apply`, params)
}

// 管理实操题参数申请
export async function applyStudyPractice(params: any) {
  return http.post(`/exam/question/study/practice/apply`, params)
}

// 查询单个题目
export async function getExamOne(params: any) {
  return http.post(`/exam/question/study/detail`, params)
}

// 从题库导入题目
export async function importFromBank(params: any) {
  return http.post(`/exam/question/importFromBank`, {
    examCode: params?.examCode,
    bankQuestionCodes: params?.bankQuestionCodes
  })
}

//保存题库题目
export async function saveQuestion(params: any) {
  return http.post(`question/bank/save`, params)
}

//获取题库标签
export async function questionInfo(params: any) {
  return http.get(`question/label/list?labCode=${params.labCode}`)
}




// 题库-------------------------------------------------------
// 题库获取列表
export async function getQuesBank(data: any) {
  return http.post(`/question/bank/page`, data)
}

//获取题库标签
export async function questionBankInfo(params: any) {
  return http.get(`question/bank/info?questionCode=${params.questionCode}`)
}
// 题库根据标签查全部
export async function getQuesBankfromLabels(data: any) {
  return http.post(`/question/bank/list`, data)
}

// 题库标签列表查询
export async function getQuesBankLabels(labCode: any) {
  return http.get(`/question/label/list`, { labCode })
}

// 题库标签新建
export async function createQuesBankLabels(data: any) {
  return http.post(`/question/label/save`, data)
}

// 题库标签删除
export async function delQuesBankLabels(labelCode: any) {
  return http.delete(`/question/label/delete/${labelCode}`)
}

// 题目标记标签
export async function setQuesBankTags(data: any) {
  return http.post(`/question/label/tag`, data)
}

// 题目删除
export async function delBankQues(questionCode: any) {
  return http.delete(`/question/bank/delete/${questionCode}`)
}


//题库实操题
export async function applyBankPractice(params: any) {
  return http.post(`question/bank/practice/apply`, params)
}

//获取配置的语言列表
export async function languageList(params: any) {
  return http.post(`question/language/custom/list`, params)
}

//搜索查询
export async function getQuesBankSearch(params: any) {
  const _params: any = {
    current: params?.current,
    difficulties: params?.difficulties,
    labCode: params?.labCode,
    labelPaths: params?.labelPaths,
    size: params?.size,
    types: params?.types,
  }
  if (params?.hasOwnProperty('hasAutoScore')) {
    _params['hasAutoScore'] = params?.hasAutoScore
  }
  return http.post(`/question/bank/search`, _params)
}


// acm相关------------------------------- 

/**
 * 编程语言列表查询  废弃
 * 使用   queryAcmLanguageCustomList
 * @deprecated
 */
export async function queryAcmLanguageList() {
  return http.get(`/question/language/list`)
}

// 编程语言列表查询  查询自定义
export async function queryAcmLanguageCustomList(data: any) {
  return http.post(`/question/language/custom/list`, data)
}

// acm考试题目保存
export async function saveAcmQues(data: any) {
  return http.post(`/exam/question/save`, data)
}

// 获取acm题目详情中的输入输出(考试)
export async function queryAcmDetailExamInpOup(data: any) {
  return http.post(`/exam/question/example/list`, data)
}

// 获取acm题目详情中的输入输出（题库）
export async function queryAcmDetailInpOup(data: any) {
  return http.post(`/question/bank/example/list`, data)
}

// acm 题目录入
export async function saveBankAcm(data: any) {
  return http.post(`/question/bank/save`, data)
}

// 预览题目时查询详情
export async function getExamInfo(data: any) {
  return http.get(`exam/question/info?questionCode=${data.questionCode}`)
}
// 分数保存
export async function saveExamScore(params: any) {
  return http.post(`/exam/question/update/score`, params)
}

// acm编程题运行
export async function runAcm(data: any) {
  return http.post(`${ojURL}/test/run`, data)
}

// 查询运行结果
export async function queryRunAcmResult(id: any) {
  return http.post(`${ojURL}/test/query/${id}`)
}



// 工程项目类实操题---------
// 文件上传
export async function uploadFile(formData: any, onUploadProgress?: (progress: number) => void) {
  return http.post(`/config/file/oss/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      onUploadProgress?.(percentCompleted)
    }
  })
}

// 获取研发模式
export async function getDevMode() {
  return http.get(`/config/enums/DevMode`)
}

// 获取工作台 题库题目
export async function getBankWork(questionCode: string) {
  return http.post(`/question/bank/practice/apply`, { questionCode })
}

// 获取工作台  考试添加
export async function getExamWork(questionCode: string) {
  return http.post(`/exam/question/practice/apply`, { questionCode })
}

// 工程实操题设置分数
export async function saveExamProjectScore(data: any) {
  return http.post(`/exam/question/update/example/score`, data)
}

// 删除刚添加的工程项目实操题   考试
export async function deleteExamQuestion(questionCode: string) {
  return http.delete(`/exam/question/delete/${questionCode}`)
}

// 删除刚添加的工程项目实操题   题库
export async function deleteBankQuestion(questionCode: string) {
  return http.delete(`/question/bank/delete/${questionCode}`)
}


// 项目工程实操题查询主观客观
export async function getExamProjectAns(data: any) {
  return http.post(`/exam/question/answer/example/list`, data)
}

// 项目工程实操题设置分数
export async function saveExamProjectAns(data: any) {
  return http.post(`/exam/question/answer/update/example/score`, data)
}

// 项目工程实操题设置分数
export async function changeScoreView(data: any) {
  return http.post(`/exam/change/score-view`, data)
}


// 考试数据上报服务
export async function repoExamLog(data: any) {
  return http.post(`/exam/study/log/report`, data)
}

// 点对点考试-第三方考试/课程考试
export async function getExamPointPage(data: any) {
  return http.post(`/examPoint/point/query/page`, data)
}

// 点对点考试单个详情-第三方考试/课程考试
export async function getExamPointInfo(data: any) {
  return http.post(`/examPoint/point/query/info`, data)
}
