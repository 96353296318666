import React from 'react';
import { Tooltip } from 'antd';
import './index.less'

type InfoProps = {
  data?: any //课程信息
}


const Info: React.FC<InfoProps> = (props) => {
  const { data } = props
  
  return (
    <div className="blog-info">
      <div className='blog-info-name'>
        {
          data?.template?.experEnv && (
            <Tooltip placement="bottomLeft" title={'已设置环境'} arrow={false} color='white' overlayClassName='platfrom-tooltip-1'>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 1H14.5C14.7652 1 15.0196 1.10536 15.2071 1.29289C15.3946 1.48043 15.5 1.73478 15.5 2V14C15.5 14.2652 15.3946 14.5196 15.2071 14.7071C15.0196 14.8946 14.7652 15 14.5 15H1.5C1.23478 15 0.98043 14.8946 0.792893 14.7071C0.605357 14.5196 0.5 14.2652 0.5 14V2C0.5 1.73478 0.605357 1.48043 0.792893 1.29289C0.98043 1.10536 1.23478 1 1.5 1ZM5.354 11.316L3.716 10L5.354 8.684C5.55 8.526 5.548 8.272 5.35 8.116C5.2463 8.0401 5.12101 7.99944 4.99251 7.99998C4.864 8.00052 4.73905 8.04223 4.636 8.119L2.646 9.718C2.451 9.874 2.451 10.126 2.646 10.282L4.636 11.881C4.73905 11.9578 4.864 11.9995 4.99251 12C5.12101 12.0006 5.2463 11.9599 5.35 11.884C5.548 11.728 5.55 11.474 5.354 11.316ZM10.646 11.316C10.45 11.474 10.452 11.728 10.65 11.884C10.7537 11.9599 10.879 12.0006 11.0075 12C11.136 11.9995 11.2609 11.9578 11.364 11.881L13.354 10.282C13.549 10.126 13.549 9.874 13.354 9.718L11.364 8.119C11.2609 8.04223 11.136 8.00052 11.0075 7.99998C10.879 7.99944 10.7537 8.0401 10.65 8.116C10.452 8.272 10.45 8.526 10.646 8.684L12.284 10L10.646 11.316ZM8.923 6.842L7.026 12.534C7.00233 12.597 6.99159 12.664 6.99445 12.7312C6.99731 12.7984 7.01369 12.8644 7.04262 12.9251C7.07156 12.9858 7.11244 13.0401 7.16283 13.0846C7.21322 13.1291 7.27208 13.1631 7.33589 13.1843C7.39969 13.2056 7.46713 13.2138 7.53418 13.2084C7.60122 13.203 7.66648 13.1841 7.72605 13.1529C7.78563 13.1217 7.83829 13.0788 7.8809 13.0267C7.9235 12.9747 7.95517 12.9146 7.974 12.85L9.872 7.158C9.9139 7.03215 9.9041 6.89482 9.84474 6.7762C9.78539 6.65758 9.68134 6.5674 9.5555 6.5255C9.42966 6.4836 9.29232 6.4934 9.1737 6.55275C9.05508 6.61211 8.9649 6.71615 8.923 6.842ZM2 5H14C14.1326 5 14.2598 4.94732 14.3536 4.85355C14.4473 4.75979 14.5 4.63261 14.5 4.5C14.5 4.36739 14.4473 4.24021 14.3536 4.14645C14.2598 4.05268 14.1326 4 14 4H2C1.86739 4 1.74021 4.05268 1.64645 4.14645C1.55268 4.24021 1.5 4.36739 1.5 4.5C1.5 4.63261 1.55268 4.75979 1.64645 4.85355C1.74021 4.94732 1.86739 5 2 5Z" fill="#73ADFF" />
              </svg>
            </Tooltip>
          )
        }
        <span>{data?.name}</span>
      </div>
      <div className='blog-info-1'>
        <img src={data?.user?.avatar} alt="" />
        <span className='username'>{data?.user?.nickname}</span>
        <span className='time'>{data?.modifyTime}</span>
        <Tooltip placement="bottomRight" title={data?.labName} arrow={false} color='white' overlayClassName='platfrom-tooltip-1'>
          <span className='labname'>{data?.labName}</span>
        </Tooltip>
      </div>
      {
        data?.remark && (
          <div className='blog-info-desc' dangerouslySetInnerHTML={{__html: data?.remark}}></div>
        )
      }
    </div>
  );
}

export default Info;
