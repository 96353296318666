import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Button, Checkbox, Form, Input, Modal, Select, message } from 'antd'
import RichEditor from '../../components/RichEditor'
import { getQuestionAnswerType, publishQuesAndAns } from '../../api/modules/blog'
import { generateLabHomeUrl, getLabCodeFromHost } from '../../utils/url'
import { getLabToken, queryUserToken } from '../../api/modules/github'
import { useParams } from 'react-router-dom'
import { getCourseInfo } from '../../api/modules/study'

type CreateAnswerProps = {
    open: boolean             // 显示与与隐藏
    onClose: () => void       // 关闭显示
    reValue?: () => void       // 关闭显示
}

const CreateAnswer: React.FC<CreateAnswerProps> = (props) => {

    const { open, onClose, reValue } = props

    const [form] = Form.useForm()

    // 实验室Code
    const labCode = getLabCodeFromHost()
    // 课程code
    const { coucode } = useParams()

    // 标题
    const [title, setTitle] = useState('')
    // 编辑器内容
    const [editorValue, setEditorValue] = useState('')
    // 问答类型
    const [quesType, setQuesType] = useState([])

    // 显示发布问答的下拉菜单
    const [showMenus, setShowMenus] = useState(false)
    // 下拉菜单当前选中    true 第一个    false  第二个
    const [lightMenus, setLightMenus] = useState(true)

    // 用户绑定github的相关信息
    const [userGithub, setUserGithub] = useState<any>({})
    // 实验室绑定github的相关信息
    const [labGithub, setLabGithub] = useState<any>({})

    // 当下拉菜单显示的时候，监听关闭时机
    useEffect(() => {
        const handleClick = (event: any) => {
            const menu = document.getElementById('createanswer-main-btn-menus-id') as HTMLDivElement
            if (!menu?.contains(event.target)) {
                setShowMenus(false)
            }
        };
        if (showMenus) {
            const list = document.getElementById('createanswer-main-btn-menus-id') as HTMLDivElement
            list?.scrollIntoView({ behavior: "smooth" })
            setTimeout(() => {
                document.addEventListener('click', handleClick)
            }, 0)
            return () => {
                document.removeEventListener('click', handleClick);
            }
        }
    }, [showMenus])

    // 获取问答类型
    const fetchType = async () => {
        const res = await getQuestionAnswerType().catch(() => { })
        if (!res) return
        setQuesType(res ?? [])
    }

    // 获取实验室信息
    const fetchLabGithub = async () => {
        const res = await getLabToken({
            labCode,
            type: 'github'
        })
        setLabGithub(res)
    }

    // 获取用户相关的信息
    const fetchUserGithub = async () => {
        const token = await queryUserToken('github').catch(() => { })
        setUserGithub(token ?? {})
    }


    // 是否发布接口请求中
    const tamp = useRef<boolean>(false)

    // 发布   only 发布问答   mult 发布并同步issue
    const submit = async (type: 'only' | 'mult') => {
        if (tamp.current) return
        const data = await form?.validateFields().catch(() => { })
        if (!data) return
        if (type === 'mult') {
            // 校验实验室是否已经绑定
            if (!labGithub?.url || !labGithub?.token) {
                message.destroy()
                message.warning('当前实验室未激活issue，无法同步')
                return
            }
            // 校验当前用户是否绑定
            if (!userGithub?.unionId || !userGithub?.token) {
                Modal.confirm({
                    title: '暂无权限',
                    content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
                    okText: '去设置  ',
                    centered: true,
                    width: 400,
                    cancelText: '我再想想',
                    onOk() {
                        window.open('/user/github')
                    },
                    onCancel() { }
                })
                return
            }
        }
        // 设置当前的菜单
        setLightMenus(type === 'only' ? true : false)
        // 拼接地址
        const cooperateUrl = generateLabHomeUrl(labCode ?? '', `os/${coucode}`)
        // 发布
        tamp.current = true
        const res = await publishQuesAndAns({
            chapter: 'summary',
            code: coucode,
            content: editorValue,
            cooperateUrl,
            labCode,
            title: data.title.trim(),
            labels: data?.labels ?? [],
            cooperate: data.permission ?? false,
            sync: type === 'only' ? false : true,
            type: data.type,
            learn: data.learn ?? false
        }).catch((err) => {
            message.destroy()
            // message.error(err?.message ?? '发布问答失败')
            message.error(err?.status === 403 ? err?.message : '发布问答失败')
        }).finally(() => {
            tamp.current = false
        })
        if (!res) return
        message.destroy()
        message.success('问答发布成功！')
        reValue?.()
        onClose?.()
    }

    // 相关数据初始化
    useEffect(() => {
        if (open) {
            fetchType()
            fetchLabGithub()
            fetchUserGithub()
        } else {
            form?.resetFields()
            setTitle('')
            setEditorValue('')
            setLightMenus(true)
        }
    }, [open])

    return <Modal
        open={open}
        wrapClassName={'createanswer-modal'}
        onCancel={onClose}
        footer={false}
        closable={false}
        centered={true}
        width={800}
        maskClosable={false}
        destroyOnClose={true}
    >
        <div className='createanswer-title'>新建问答</div>

        <div className='createanswer-main'>
            <Form
                name="createanswer-main-form"
                colon={false}
                form={form}
                autoComplete="off"
            >
                <Form.Item
                    name='title'
                    rules={[
                        { required: true, message: '请输入标题', validateTrigger: "onSubmit", whitespace: true },
                    ]}
                    validateFirst
                >
                    <Input
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                        maxLength={50}
                        showCount
                        allowClear
                        placeholder='请输入标题'
                    />
                </Form.Item>

                <Form.Item
                    rules={[]}
                    validateFirst
                >
                    <RichEditor
                        editorHeight={170}
                        placeholder='请输入问答内容'
                        onChange={(value: string) => {
                            setEditorValue(value)
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label='问答类型'
                    name='type'
                    rules={[]}
                    validateFirst
                    // style={{ marginBottom: '5px' }}
                >
                    <Select
                        fieldNames={{ label: 'tips', value: 'code' }}
                        placeholder="请选择"
                        options={quesType}
                    />
                </Form.Item>

                <Form.Item
                    label='影响学习进度？'
                    name='learn'
                    rules={[]}
                    validateFirst
                    valuePropName="checked"
                    // style={{ marginBottom: '0px' }}
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    label='问答标签'
                    className='createanswer-main-labels'
                    // style={{ marginBottom: '0px' }}
                >
                    <Form.List name="labels">
                        {
                            (fields, { add, remove }, { errors }) => {
                                return <>
                                    {
                                        fields.map(({ key, name, ...field }, index) => {
                                            return <div className='createanswer-main-labels-item' key={key}>
                                                <Form.Item
                                                    {...field}
                                                    rules={[
                                                        { required: true, message: '请输入标签名称', validateTrigger: "onSubmit", whitespace: true },
                                                    ]}
                                                    name={name}
                                                    // name={[name, 'label']}
                                                    style={{ width: '634px' }}
                                                >
                                                    <Input placeholder="请输入标签名称" maxLength={10} allowClear showCount />
                                                </Form.Item>

                                                <svg className='createanswer-main-labels-remove' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                    remove(name)
                                                }}>
                                                    <circle cx="7.99967" cy="8.35221" r="6.16667" />
                                                    <rect x="11.7598" y="7.75195" width="1.2" height="7.51953" rx="0.6" transform="rotate(90 11.7598 7.75195)" />
                                                </svg>
                                            </div>
                                        })
                                    }
                                    <div className='createanswer-main-labels-add' style={{ color: fields?.length < 10 ? '#1677ff' : '#cccccc', fill: fields?.length < 10 ? '#1677ff' : '#cccccc', cursor: fields?.length < 10 ? 'pointer' : 'default' }} onClick={() => {
                                        if (fields?.length < 10) {
                                            add('')
                                        }
                                    }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.33398 7.33301V4.66634H8.66732V7.33301H11.334V8.66634H8.66732V11.333H7.33398V8.66634H4.66732V7.33301H7.33398ZM8.00065 14.6663C4.31875 14.6663 1.33398 11.6815 1.33398 7.99967C1.33398 4.31777 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31777 14.6673 7.99967C14.6673 11.6815 11.6825 14.6663 8.00065 14.6663ZM8.00065 13.333C10.9462 13.333 13.334 10.9452 13.334 7.99967C13.334 5.05415 10.9462 2.66634 8.00065 2.66634C5.05513 2.66634 2.66732 5.05415 2.66732 7.99967C2.66732 10.9452 5.05513 13.333 8.00065 13.333Z" />
                                        </svg>
                                        <span>添加标签</span>
                                    </div>
                                </>
                            }
                        }
                    </Form.List>
                </Form.Item>

                <Form.Item
                    label='开启协同权限'
                    name='permission'
                    style={{ marginBottom: '0px' }}
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    noStyle
                >
                    <div className='createanswer-main-permission-tip'>
                        <svg style={{ width: '14px', height: '14px', marginTop: '2px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99927 1.00488C10.3102 1.00488 12.9946 3.68931 12.9946 7.00024C12.9946 10.3112 10.3102 12.9956 6.99927 12.9956C3.68833 12.9956 1.00391 10.3112 1.00391 7.00024C1.00391 3.68931 3.68833 1.00488 6.99927 1.00488ZM6.99927 2.00411C4.2404 2.00411 2.00313 4.24138 2.00313 7.00024C2.00313 9.75911 4.2404 11.9964 6.99927 11.9964C9.75813 11.9964 11.9954 9.75911 11.9954 7.00024C11.9954 4.24138 9.75813 2.00411 6.99927 2.00411ZM7.10618 8.71342C7.30494 8.71342 7.49556 8.79238 7.6361 8.93292C7.77665 9.07346 7.85561 9.26408 7.85561 9.46284C7.85561 9.6616 7.77665 9.85221 7.6361 9.99276C7.49556 10.1333 7.30494 10.2123 7.10618 10.2123C6.90743 10.2123 6.71681 10.1333 6.57626 9.99276C6.43572 9.85221 6.35676 9.6616 6.35676 9.46284C6.35676 9.26408 6.43572 9.07346 6.57626 8.93292C6.71681 8.79238 6.90743 8.71342 7.10618 8.71342ZM7.07071 4.00256C7.20322 4.00256 7.3303 4.0552 7.42399 4.1489C7.51769 4.24259 7.57033 4.36967 7.57033 4.50218V7.35697C7.57033 7.48947 7.51769 7.61655 7.42399 7.71025C7.3303 7.80394 7.20322 7.85658 7.07071 7.85658C6.93821 7.85658 6.81113 7.80394 6.71743 7.71025C6.62374 7.61655 6.5711 7.48947 6.5711 7.35697V4.50218C6.5711 4.36967 6.62374 4.24259 6.71743 4.1489C6.81113 4.0552 6.93821 4.00256 7.07071 4.00256Z" fill="#999999" />
                        </svg>
                        <p>开启权限后，该工作台的协同链接将会对他人开放，他人可以通过链接进行工作台对你进行在线协助</p>
                    </div>
                </Form.Item>

                <Form.Item
                    className='createanswer-main-submit'
                    style={{ marginTop: '40px', marginBottom: '56px' }}
                >
                    <div className='createanswer-main-submit-con'>
                        <Button onClick={() => {
                            onClose?.()
                        }}>取消</Button>
                        <div className={(title && editorValue) ? 'createanswer-main-btn' : 'createanswer-main-btn createanswer-main-btn-dark'}>
                            <div className='createanswer-main-btn-left center' onClick={() => {
                                if (tamp.current) return
                                if (lightMenus) {
                                    submit('only')
                                } else {
                                    submit('mult')
                                }
                            }}>
                                <span>{lightMenus ? '发布问答' : '发布并同步issue'}</span>
                            </div>
                            <div className='createanswer-main-btn-right center' onClick={() => {
                                title && editorValue && setShowMenus(true)
                            }}>
                                <svg style={{ width: '14px', height: '14px', transform: 'rotate(-90deg)' }} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.01" width="16" height="16" transform="matrix(0 -1 -1 0 16 16)" />
                                    <path d="M10.7326 4.21988L6.43956 8.18242L10.7326 12.1453C10.9541 12.3497 10.9679 12.6948 10.7636 12.9162C10.5592 13.1377 10.2141 13.1515 9.99265 12.9472L5.26537 8.58351C5.15344 8.48025 5.08978 8.3349 5.08978 8.1826C5.08978 8.03031 5.15344 7.88496 5.26537 7.7817L9.99265 3.41806C10.2141 3.21371 10.5592 3.22755 10.7636 3.44897C10.9679 3.67038 10.9541 4.01553 10.7326 4.21988Z" />
                                </svg>
                            </div>

                            {
                                showMenus ? <div className='createanswer-main-btn-menus' id='createanswer-main-btn-menus-id'>
                                    <div className='createanswer-main-btn-menus-item' onClick={() => {
                                        if (tamp.current) return
                                        submit('only')
                                        setShowMenus(false)
                                    }}>
                                        <span>发布问答</span>
                                        {
                                            lightMenus ? <svg style={{ width: '14px', height: '14px', marginRight: '8px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7779 2.9836C12.6751 2.88388 12.537 2.82903 12.3938 2.83111C12.2507 2.83319 12.1142 2.89203 12.0144 2.99469L5.35367 9.85342L1.98766 6.38881C1.88727 6.28885 1.75163 6.23233 1.60996 6.23144C1.46829 6.23055 1.33194 6.28535 1.2303 6.38403C1.12865 6.48272 1.06985 6.61738 1.06655 6.75902C1.06326 6.90065 1.11574 7.03791 1.21269 7.14121L4.9644 11.0046C5.01474 11.0564 5.07498 11.0976 5.14154 11.1258C5.20809 11.1539 5.27962 11.1684 5.35189 11.1684C5.42415 11.1684 5.49568 11.1539 5.56223 11.1258C5.62879 11.0976 5.68903 11.0564 5.73937 11.0046L12.7882 3.74669C12.8376 3.69585 12.8765 3.63576 12.9027 3.56986C12.9289 3.50397 12.9419 3.43355 12.9408 3.36265C12.9398 3.29175 12.9248 3.22174 12.8967 3.15664C12.8686 3.09153 12.828 3.0326 12.7771 2.9832L12.7779 2.9836Z" fill="#1677FF" />
                                            </svg> : <></>
                                        }
                                    </div>
                                    <div className='createanswer-main-btn-menus-item' onClick={() => {
                                        if (tamp.current) return
                                        submit('mult')
                                        setShowMenus(false)
                                    }}>
                                        <span>发布并同步issue</span>
                                        {
                                            lightMenus ? <></> : <svg style={{ width: '14px', height: '14px', marginRight: '8px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7779 2.9836C12.6751 2.88388 12.537 2.82903 12.3938 2.83111C12.2507 2.83319 12.1142 2.89203 12.0144 2.99469L5.35367 9.85342L1.98766 6.38881C1.88727 6.28885 1.75163 6.23233 1.60996 6.23144C1.46829 6.23055 1.33194 6.28535 1.2303 6.38403C1.12865 6.48272 1.06985 6.61738 1.06655 6.75902C1.06326 6.90065 1.11574 7.03791 1.21269 7.14121L4.9644 11.0046C5.01474 11.0564 5.07498 11.0976 5.14154 11.1258C5.20809 11.1539 5.27962 11.1684 5.35189 11.1684C5.42415 11.1684 5.49568 11.1539 5.56223 11.1258C5.62879 11.0976 5.68903 11.0564 5.73937 11.0046L12.7882 3.74669C12.8376 3.69585 12.8765 3.63576 12.9027 3.56986C12.9289 3.50397 12.9419 3.43355 12.9408 3.36265C12.9398 3.29175 12.9248 3.22174 12.8967 3.15664C12.8686 3.09153 12.828 3.0326 12.7771 2.9832L12.7779 2.9836Z" fill="#1677FF" />
                                            </svg>
                                        }
                                    </div>
                                </div> : <></>
                            }
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    </Modal>
}

export default CreateAnswer