import React, { useState, useEffect } from 'react'
import "./index.less"
import * as url from '../../../../utils/url'
import { Checkbox, Modal, Pagination, Tooltip, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { issueLabels } from '../../../../api/modules/issue';
import { useSearchParams } from 'react-router-dom';
import { getLabDetail } from '../../../../api/modules/product';
import { getUserInfo } from '../../../../api/modules/person';
import { getLabAuth } from '../../../../api/modules/admin';

const { warning, confirm } = Modal;


type RemarkRigthProps = {
  value?: any
  getValue?: () => void //重新请求数据
  joinLab?: () => void   //加入实验室
  gitNo?: () => void    //添加权限
  ifJoin?: any
}



const RemarkRigth: React.FC<RemarkRigthProps> = (props) => {

  const labcode = url.getLabCodeFromHost()
  const [search] = useSearchParams()
  let id = search.get('id') || ''
  const newLabcode = search.get('labCode') || ''

  const { value, getValue, ifJoin, joinLab, gitNo } = props

  const [lableList, setLableList] = useState<any>([])

  //实验室权限


  //设置hover
  const [shezhiHover, setShezhiHover] = useState(false)

  //标签按钮是否在保存中
  const [isSave, setIfSave] = useState(false)

  const [orderClick, setOrderClick] = useState(true)

  //实验室详情
  const [labDetail, setLabDetail] = useState<any>({

  })

  const changeOrder = () => {
    if (ifJoin) {
      getLabAuth(labcode ?? newLabcode).then(res => {
        const { isAdministrator } = res
        if (isAdministrator) {
          setOrderClick(false)
        } else {
          noLimit()
        }

      }).catch(() => { })
    } else {
      joinLab?.()
    }
  }

  const isEqual = (obj1: any, obj2: any) => {
    return obj1.color === obj2.color && obj1.name === obj2.name;
  }

  //保存标签
  const saveLable = () => {
    setIfSave(true)
    let saveList: any[] = []
    lableList.forEach((item: any) => {
      if (item.select) {
        saveList.push(item.name)
      }
    })
    issueLabels({
      code: labcode ?? newLabcode,
      id: id,
      data: saveList
    }).then(() => {
      setIfSave(false)
      message.success('保存成功！')
      setOrderClick(true)
      getValue?.()
    })
  }
  //重新设置lab标签

  const noLimit = () => {
    Modal.warning({
      title: '权限不足，无法操作！',
      icon: <ExclamationCircleFilled />,
      content: '请确认账号并联系该GitHub项目Owner解决此问题。',
      okText: '我知道了',
      centered: true,
      width: 400,
      // cancelText: '取消',
      onOk() {

      },
      onCancel() { }
    })
  }


  useEffect(() => {
    if (value) {
      let list = value?.labelList

      list.forEach((item: any, index: any) => {
        if (value?.showLabelAndColor?.some((obj: any) => isEqual(obj, item))) {
          list[index].select = true

        } else {
          list[index].select = false
        }
      })
      setLableList(list)

    }
  }, [value])

  useEffect(() => {
    if (labcode || newLabcode) {
      getLabDetail({ labCode: labcode ? labcode : newLabcode }).then((res: any) => {
        setLabDetail(res)
      })
    }

  }, [labcode, newLabcode])

  return (
    <div className='remark-rigth'>
      <div className='orderSelect'>
        <div className='orderSelect-top'>
          <div>标签</div>
          {orderClick ?
            <div style={{ cursor: value && value?.statusCode === 'open' ? 'pointer' : '' }} onMouseEnter={() => {
              setShezhiHover(true)
            }}
              onMouseLeave={() => setShezhiHover(false)}
              onClick={() => {
                if (!value) return
                if (value?.statusCode === 'open') {
                  changeOrder()
                }
              }}>
              {shezhiHover && value && value?.statusCode === 'open' ? <img src={require('../../../../assets/images/issue/shezhiHover.png')} alt="" style={{ width: '16px', height: '16px' }} /> : <img src={require('../../../../assets/images/issue/shezhi.png')} alt="" style={{ width: '16px', height: '16px' }} />}
            </div> : <div className='top-btns'>
              <div style={{ marginRight: '10px' }} onClick={() => {
                setOrderClick(true)
                getValue?.()
              }}>取消</div>
              <div onClick={() => {
                if (!isSave) {
                  saveLable()
                }

              }}>保存</div>
            </div>
          }
        </div>

        {orderClick ? <div className='orderList'>
          {value?.showLabelAndColor?.map((item: any) => {
            return (
              <Tooltip placement="bottomRight" title={
                item.name
              } overlayClassName={'order-tooltip'} arrow={false}>
                <span key={item?.color} className='orderItem' style={{ background: item?.color === '#fff' || !item?.color ? ' #63BFDC' : `#${item?.color}` }}>{item.name}</span>
              </Tooltip>
            )
          })}
          {
            value?.showLabelAndColor?.length === 0 && <span style={{ fontSize: '12px', color: '#333333' }}>暂无</span>
          }
        </div> :
          <div className='orderList-select'>
            <div className='orderList-select-top'>
              标签设置
            </div>
            <div className='orderList-select-items'>
              {lableList?.map((item: any, index: any) => {
                return <Tooltip placement="bottomRight" title={
                  <div className='toolTip-div'>
                    <div style={{ width: '14px', height: '14px', borderRadius: '50%', background: `#${item.color}`, marginRight: '4px', marginTop: '2px' }}></div>
                    <div>

                      <div style={{ color: '#333333', fontSize: '12px' }}>{item.name}</div>
                      <div style={{ border: '0.5px dashed #e5e5e5', width: '100%', margin: '4px 0' }}></div>
                      <div style={{ color: '#888888', fontSize: '12px' }}>
                        {item.description}
                      </div>
                    </div>

                  </div>
                } overlayClassName={'order-tooltip'} arrow={false}>
                  <div className='orderList-select-item' key={item?.color}>
                    <div>
                      <Checkbox checked={item?.select} onChange={(e: any) => {
                        console.log(e);

                        let list = JSON.parse(JSON.stringify(lableList))
                        list[index].select = e.target.checked
                        setLableList(list)
                      }}></Checkbox>
                    </div>
                    <div className='item-value'>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <div style={{ width: '14px', height: '14px', borderRadius: '50%', background: `#${item.color}`, marginRight: '4px' }}></div>
                        <div className='order-text1'>{item.name}</div>
                      </div>
                      <div className='order-text2'>
                        {item.description}
                      </div>
                    </div>
                  </div>
                </Tooltip>
              })}
            </div>
          </div>
        }
      </div>
      {value && <div className='orderSelect'>
        <div className='orderSelect-top'>
          <div>来源</div>
        </div>
        <div style={{ fontSize: '12px', color: '#333333' }}>{value?.githubIssueNumber ? 'GitHub' : '开源学堂'}</div>
      </div>}
      {value && <div className='orderSelect'>
        <div className='orderSelect-top'>
          <div>GitHub</div>
        </div>
        {labDetail?.labTokens?.length > 0 &&
          <div className='lianjie' style={{ fontSize: '12px', color: labDetail?.labTokens[0]?.issueUrl ? '#1677FF' : '#333333', textDecoration: labDetail?.labTokens[0]?.issueUrl ? 'underline' : '', wordBreak: 'break-all' }}>
            {labDetail?.labTokens[0]?.issueUrl ? <a href={labDetail?.labTokens[0]?.issueUrl?.replace('{issue_number}', '') + `${value?.githubIssueNumber}`} target="_blank">{labDetail?.labTokens[0]?.issueUrl?.replace('{issue_number}', '') + `${value?.githubIssueNumber}`}</a> : '暂无地址'}
          </div>
        }
      </div>}
    </div>
  )
}

export default RemarkRigth;
