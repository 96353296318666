
import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
  name: "containerSpace", // 命名空间，在调用action的时候会默认的设置为action的前缀,保证唯一.不重名
  initialState: {
    courseSpeed: '',
    courseName:''
  },
  reducers: {
    // reducer函数 state当前组件的数据 
    //第二个参数为{payload:{},type:"""} 
    // 更新路由
    updateCourseSpeed(state, { payload }) {
      state.courseSpeed = payload.courseSpeed
    },
    updateCourseName(state, { payload }) {
      state.courseName = payload.courseName
    }
  },
});
export const { updateCourseSpeed,updateCourseName } = courseSlice.actions;

export default courseSlice.reducer;
