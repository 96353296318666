

export const verifySortNumber = (_: any, value: any) => {
    const re = /^(0|[1-9][0-9]{0,2})$/
    if (re.test(value)) {
        return Promise.resolve()
    } else {
        return Promise.reject('请输入0-999的数字')
    }
}

// 校验手机号是否符合格式
export const verifyPhone = (value: string): boolean => {
    const regx = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    return regx.test(value)
}

// 校验验证码是否符合格式
export const verifyCode = (value: string): boolean => {
    const regx = /^\d{6}$/
    return regx.test(value)
}