import React, { useState } from 'react'
import './index.less'
import LoginHeader from '../../../components/LoginHeader'
import { Form, Input } from 'antd'
import LoginForm from './LoginForm'
import HowGetCode from '../../../components/HowGetCode'
import RegisterForm from './RegisterForm'
import { useSearchParams } from 'react-router-dom'

type AllProps = {
  
}

const All: React.FC<AllProps> = (props) => {

  const { } = props

  const [search] = useSearchParams()

  const _initChecket = search.get('initChecket') || ''

  // 登录还是注册
  const [type, setType] = useState<'login' | 'register'>(() => {
    if (['login', 'register'].includes(_initChecket)) {
      return _initChecket as any
    } else {
      return 'login'
    }
  })


  return (
    <div className='login-all'>
      <div>
        <LoginHeader style={{ marginBottom: '30px' }} checked={type} onChange={(type) => setType(type)} />
        {
          type === 'login' ? <LoginForm /> : <RegisterForm />
        }
      </div>
    </div>
  )
}

export default All