import React, { useEffect, useState } from 'react'
import './index.less'
import NavItem from './NavItem'
import { useLocation } from 'react-router-dom'

// 菜单类型
interface MenuType {
    id: string               // 唯一标识
    icon: React.ReactNode    // 图标
    title: string            // 标题
    highlight?: boolean      // 是否高亮
    disable?: boolean        // 是否禁用
    url?: string             // 地址
}


const menu: MenuType[] = [
    {
        id: '1',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66667 12.6671H12.6667V6.65259L8 3.02296L3.33333 6.65259V12.6671H7.33333V8.66712H8.66667V12.6671ZM14 13.3338C14 13.702 13.7015 14.0004 13.3333 14.0004H2.66667C2.29848 14.0004 2 13.702 2 13.3338V6.32653C2 6.1208 2.09498 5.9266 2.25737 5.8003L7.59073 1.65215C7.83147 1.46491 8.16853 1.46491 8.40927 1.65215L13.7426 5.8003C13.905 5.9266 14 6.1208 14 6.32653V13.3338Z" />
        </svg>,
        title: '主页',
        highlight: false,
        disable: false,
        url: '/'
    },
    {
        id: '2',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.61175 5.35741C2.21989 6.1552 2 7.05248 2 7.99961C2 11.3133 4.68629 13.9996 8 13.9996C8.94773 13.9996 9.8456 13.7794 10.6438 13.387C11.2257 13.6359 11.7899 13.7991 12.3043 13.841C12.9055 13.8901 13.5333 13.7773 13.9879 13.3228C14.4893 12.8213 14.575 12.1123 14.4853 11.4481C14.4035 10.8422 14.1654 10.1688 13.8173 9.47394C13.9367 9.00168 14 8.50761 14 7.99961C14 4.68587 11.3137 1.99958 8 1.99958C7.4926 1.99958 6.99913 2.0627 6.52735 2.1818C5.8316 1.83288 5.15737 1.59416 4.55073 1.5119C3.88598 1.42178 3.17604 1.5072 2.67414 2.0091C2.21913 2.4641 2.10667 3.09272 2.15607 3.69456C2.19835 4.20968 2.36209 4.77466 2.61175 5.35741ZM3.54827 3.97684C3.5156 3.83515 3.49469 3.70444 3.48493 3.5855C3.45285 3.19463 3.54485 3.024 3.61695 2.9519C3.69635 2.87251 3.89865 2.76903 4.37159 2.83315C4.50321 2.851 4.64634 2.88094 4.8 2.92332C4.33576 3.21652 3.91469 3.57157 3.54827 3.97684ZM13.0749 11.2017C13.1167 11.3541 13.1463 11.496 13.1639 11.6266C13.2277 12.0987 13.1243 12.3007 13.0451 12.38C12.973 12.452 12.8027 12.5439 12.4126 12.5121C12.2946 12.5025 12.165 12.4819 12.0245 12.4497C12.4285 12.0842 12.7824 11.6644 13.0749 11.2017ZM10.5875 11.8839C9.46973 11.2985 8.1714 10.3347 6.9168 9.08014C5.6636 7.82701 4.70061 6.53008 4.11494 5.4132C4.70905 4.52278 5.60083 3.84842 6.64622 3.53212C7.07387 3.40272 7.5282 3.33292 8 3.33292C10.5773 3.33292 12.6667 5.42225 12.6667 7.99961C12.6667 8.47188 12.5967 8.92661 12.4671 9.35468C12.1507 10.3991 11.477 11.2901 10.5875 11.8839ZM9.07013 12.5431C8.72673 12.6236 8.36853 12.6663 8 12.6663C5.42267 12.6663 3.33333 10.5769 3.33333 7.99961C3.33333 7.63141 3.37585 7.27361 3.45619 6.93054C4.09922 7.94168 4.9565 9.00548 5.97397 10.0229C6.99267 11.0417 8.05787 11.8998 9.07013 12.5431Z" />
        </svg>,
        title: '广场',
        highlight: false,
        disable: false,
        url: '/center'
    },
    {
        id: '3',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.333 6V3.33333H2.66634V6H13.333ZM13.333 7.33333H2.66634V12.6667H13.333V7.33333ZM1.99967 2H13.9997C14.3679 2 14.6663 2.29848 14.6663 2.66667V13.3333C14.6663 13.7015 14.3679 14 13.9997 14H1.99967C1.63149 14 1.33301 13.7015 1.33301 13.3333V2.66667C1.33301 2.29848 1.63149 2 1.99967 2ZM3.33301 8H5.33301V11.3333H3.33301V8ZM3.33301 4H4.66634V5.33333H3.33301V4ZM5.99967 4H7.33301V5.33333H5.99967V4Z" />
        </svg>,
        title: '模板',
        highlight: false,
        disable: false,
        url: "/template",
    },
    {
        id: '4',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99967 14.6673C4.31777 14.6673 1.33301 11.6825 1.33301 8.00065C1.33301 4.31875 4.31777 1.33398 7.99967 1.33398C11.6815 1.33398 14.6663 4.31875 14.6663 8.00065C14.6663 11.6825 11.6815 14.6673 7.99967 14.6673ZM7.99967 13.334C10.9452 13.334 13.333 10.9462 13.333 8.00065C13.333 5.05513 10.9452 2.66732 7.99967 2.66732C5.05415 2.66732 2.66634 5.05513 2.66634 8.00065C2.66634 10.9462 5.05415 13.334 7.99967 13.334ZM4.66634 8.00065H5.99967C5.99967 9.10525 6.89507 10.0007 7.99967 10.0007C9.10427 10.0007 9.99967 9.10525 9.99967 8.00065H11.333C11.333 9.84158 9.84061 11.334 7.99967 11.334C6.15873 11.334 4.66634 9.84158 4.66634 8.00065Z" />
        </svg>,
        title: '我的',
        highlight: false,
        disable: true
    }
]

const Sider: React.FC = () => {


    const location = useLocation()

    // 导航菜单
    const [navMenu, setNavMenu] = useState(menu)

    // 监听当前路由变化
    useEffect(() => {
        const { pathname } = location
        // 找出最匹配路径（长度最长）
        let current: any
        navMenu?.forEach((item: MenuType) => {
            if (item?.url && pathname?.startsWith(item.url)) {
                // 匹配的路径长度对比
                const currUrl = item.url.split('/').filter((url: string) => url)
                // 需要针对 / 路径做特别处理
                if (currUrl.length > (current?.length ?? 0) || (currUrl?.length === 0 && pathname === item?.url)) {
                    current = {
                        id: item?.id,
                        length: currUrl.length
                    }
                }
            }
        })
        const newMenu = navMenu?.map((item: MenuType) => {
            return current?.id === item?.id ? { ...item, highlight: true } : { ...item, highlight: false }
        })
        setNavMenu(newMenu)
        // 滚动到屏幕最上方
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [location?.pathname])


    return (
        <div className='container-sider'>
            {
                navMenu?.map((item: MenuType) => {
                    return <NavItem key={item?.id} title={item?.title} icon={item?.icon} highlight={item?.highlight} disable={item?.disable} url={item?.url} />
                })
            }
        </div>
    )
}

export default Sider