// 题目类型
export enum QuesType {
    'radio' = '单选题',
    'checkbox' = '多选题',
    'answer' = '问答题',
    // 'practice' = '实操题'
    'acm' = '函数脚本类实操题',
    'project' = '工程项目类实操题',
    'ccm' = '编码测验题'
}

// 题目难度
export enum DifficultyType {
    'low' = '简单',
    'middle' = '中等',
    'high' = '困难'
}

// 实验室的状态
export enum LabStatus {
    init = 'INIT',        // 初始化
    created = 'CREATED',  // 已发布
    under = 'UNDER'       // 下架
}

/**
 * 实验室类型
 * @description 实验室类型，分为项目实验室0、个人实验室1、机构实验室2(暂时未使用)、教培实验室3、官方实验室10
 */
export enum LabType {
    PROJECT,
    PERSONAL,
    ORGAN,
    EDUCATION,
    OFFICIAL = 10,
}

// 考试相关---------------------------------------

/**
 * 考试学生状态                或考试状态
 * 
 * NOT_START(0, "未开始")     考试前
 * IN_PROGRESS(1, "进行中")   考试中
 * END(2, "已结束")           考试后
 */
export enum ExamUserStatus {
    NOT_START,
    IN_PROGRESS,
    END
}

/**
 * 批量考试状态
 * 
 *  UNCREATED(0, "编辑中"),
 *  CREATED(1, "已发布"),
 *  UNDER(2, "已下架"),
 *  IN_EXAM(3, "考试中"),
 *  IN_SCORE(50, "判分中"),
 *  FINISH_SCORE(5, "已判分"),
 *  PUBLISH_SCORE(70, "已公布成绩"),
 */
export enum BatchExamStatus {
    UNCREATED,
    CREATED,
    UNDER,
    IN_EXAM,
    IN_SCORE = 50,
    FINISH_SCORE = 5,
    PUBLISH_SCORE = 70
}

/**
 * 点对点考试状态
 * 
 *  UNCREATED(0, "编辑中"),
 *  CREATED(1, "已发布"),
 *  UNDER(2, "已下架"),
 *  IN_EXAM(3, "考试中"),
 *  WAIT_SCORE(4, "待判分"),
 *  IN_SCORE(50, "判分中"),
 *  FINISH_SCORE(5, "已判分"),
 */
export enum PointExamStatus {
    UNCREATED,
    CREATED,
    UNDER,
    IN_EXAM,
    WAIT_SCORE,
    IN_SCORE = 50,
    FINISH_SCORE = 5
}

