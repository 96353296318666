import React, { useState } from 'react';
import './index.less'
import StudyDetailCourse from '../../../StudyDetailsCourse';
import CourseDetail from '../../../CourseDetail';

const ImageVerify: React.FC<any> = (props) => {
  const { onPreviewClick } = props
  console.log(props.courseCode);


  const [isShow, setIsShow] = useState(false)

  return (
    <div className='image-verify' >
      <div className='title'>
        <div className='title-line'></div>
        博客预览
      </div>
      {/* <div className='couse-name'>SOFA原理学习--sofa rpc入门示例</div> */}
      <div className='verify-content' onClick={() => {
        onPreviewClick()
        setIsShow(true)
      }}>
        <div className='content'>
          <CourseDetail code={props.courseCode} statu={true}></CourseDetail>
        </div>
        <div className='preview' >点击预览</div>
      </div>
    </div>
  );
};

export default ImageVerify;