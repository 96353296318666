import React from 'react'
import "./index.less"
import RichEditor from '../../../../../../components/RichEditor'
interface ContainerLeftProps {
  value: any       // acm题目内容
}

const ContainerLeft: React.FC<ContainerLeftProps> = (props) => {

  const { value } = props

  return (
    <div className='container'>
      <div className='container-element container-element-richEditor'>
        <RichEditor defaultValue={value} readOnly isShow={true} autoHeight={{ min: 0, max: 1000 }}></RichEditor>
      </div>
    </div>
  )
}

export default ContainerLeft;
