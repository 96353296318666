import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { getDetailCourse } from '../../../../api/modules/study';
import './index.less'
import * as url from '../../../../utils/url';

const Publish: React.FC<any> = (props) => {
  const { current, data, labValue } = props

  const [copyShow, setCopyShow] = useState(true)

  const [courseValue, setCourseValue] = useState<any>()

  //课程节数
  const [courseNum, setCourseNum] = useState(0)

  // const location = useLocation()

  // const path = window.location.origin;

  const copeValue = () => {
    let Url = document.getElementById("url1")?.innerText

    let oInput = document.createElement('input');
    oInput.value = Url ?? '';
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
  }

  const fetchCourseDetail = () => {
    console.log(data);
    if (data) {
      getDetailCourse({
        code: data
      }).then(res => {
        setCourseValue(res)
        setCourseNum(res?.commitNum)
      }).catch(err => {
        console.log(err);

      })
    }
  }




  useEffect(() => {
    if (current === 3) {
      // getStatus()

      fetchCourseDetail()
    }
  }, [current])


  // 展开
  const [iszk, setIszk] = useState(false)

  const zkref = useRef(null)
  const [temp, setTemp] = useState(false)

  // 课程简介是否展开
  const zk = () => {
    setTemp(!temp)
  }

  useEffect(() => {
    if (courseValue) {
      console.log(courseValue);

      const el: any = zkref?.current
      if (el.clientWidth >= 500) {
        setIszk(true)
      } else {
        setIszk(false)
      }
    }
  }, [courseValue])

  return (
    <div className='publish-course'>
      <img className='finishImg' src={require("../../../../assets/images/finish.png")} alt="" />
      <div className='publish-course-info'>
        <div className='tips'>
          <img src={require("../../../../assets/images/success.png")} alt="" />
          <p>博客已设置完成，请确认是否发布</p>
        </div>
        <div className='info'>
          <div className='info-title'>博客信息</div>
          <div className='info-i'>
            <p style={{ display: 'flex' }}><span>博客标题：</span><span className='hh'>{courseValue?.name}</span></p>
            <p style={{ display: 'flex', cursor: 'pointer', lineHeight: '22px', paddingBottom: '3px' }}>
              <span>博客简介：</span>
              {/* style={{ width: '300px', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' } */}
              <span className={!temp ? 'info-i-mo' : 'info-i-mozk'} ref={zkref} dangerouslySetInnerHTML={{ __html: courseValue?.remark?.replace(/\n/g, '<br>') }}></span>
              {
                iszk ? <span className='info-i-zk' onClick={zk}>{temp ? '收起' : '展开'}</span> : <></>
              }
              {/* <span style={{ color: '#1677ff', textDecoration: 'underline' }}>更多</span> */}
            </p>
            <p style={{ display: 'flex' }}><span>所属实验室：</span><span className='hh'>{courseValue?.labName}</span></p>
            {courseValue?.labType === 'PERSONAL' && <p style={{ display: 'flex' }}><span>投放实验室：</span><span className='hh'>{courseValue?.layLabName}</span></p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publish;