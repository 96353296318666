import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import './index.less'
import { getProblemList } from '../../../../../../api/modules/issue';
import { updateRoutes } from '../../../../../../store/modules/breadcrumbSlice';

type HotAnswerProps = {
  labcode: any //实验室编码
  code: any //课程编码
  viewStatus: any //预览状态

  [key: string]: any
}

let HotAnswer: React.FC<HotAnswerProps> = (props, ref) => {

  const { labcode, code, viewStatus } = props

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { routes } = useSelector((state: any) => state.breadcrumb)

  // 展开
  const [open, setOpen] = useState(true)

  // 问答数据，取前5条
  const [data, setData] = useState<any>([{}, {}])

  // 暴露表单句柄
  useImperativeHandle(ref, () => ({
    // 重新请求
    refresh: fetchAnswerList
  }));

  // 判断是否出现省略号，出现显示tooltip
  const onMouseEnter = (e: any, index: number) => {
    const target = e?.target
    if (target?.scrollWidth > target?.clientWidth) {
      data[index].showTooltip = true
      setData([...data])
    }
  }

  // 不显示tootip
  const onMouseLeave = (_: any, index: number) => {
    data[index].showTooltip = false
    setData([...data])
  }

  // 进入问答详情
  const onAnswerClick = (value: any) => {
    navigate(`/os/${code}/answerDetail?id=${value.id}`)
    const breadcrumRoutes = [...routes, {
      path: '',
      title: '问答详情'
    }]
    dispatch(updateRoutes({
      routes: breadcrumRoutes
    }))
  }

  // 进入问答列表
  const onMoreClick = () => {
    navigate(`/os/${code}/bloganswer`)
  }

  // 获取热门问答前5条数据
  const fetchAnswerList = () => {
    getProblemList({
      code: code,
      current: 1,
      size: 5,
      labCode: labcode
    }).then(res => {
      setData(res.rows)
    })
  }

  useEffect(() => {
    if (code) {
      fetchAnswerList()
    }
  }, [code])

  return (
    <div className="hot-answer">
      <div className='hot-answer-header'>
        <div onClick={() => setOpen(!open)}>
          <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" style={!open ? { transform: 'rotate(-90deg)' } : {}}>
            <path d="M11.7178 3.86562L6.60489 9.45664C6.45255 9.63008 6.23223 9.72969 6.0002 9.72969C5.76934 9.72969 5.54903 9.63008 5.39551 9.45664L0.282623 3.86562C0.0330134 3.60195 -0.0443303 3.21758 0.0857478 2.87773C0.215826 2.53789 0.526373 2.30117 0.888482 2.26953H11.1096C11.4729 2.3 11.7857 2.53555 11.9147 2.87656C12.0447 3.21758 11.9674 3.59961 11.7178 3.86562Z" />
          </svg>
          <span>热门问答</span>
        </div>
        {
          !viewStatus && (
            <span onClick={onMoreClick}>
              <Tooltip placement="bottomRight" title={'查看全部'} arrow={false} color='white' overlayClassName='platfrom-tooltip-1'>
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.24415 4.07618C8.40052 3.91985 8.61258 3.83203 8.83369 3.83203C9.05481 3.83203 9.26687 3.91985 9.42324 4.07618L12.7587 7.41166C12.9151 7.56804 13.0029 7.7801 13.0029 8.00121C13.0029 8.22232 12.9151 8.43438 12.7587 8.59076L9.42324 11.9262C9.26597 12.0781 9.05533 12.1622 8.83669 12.1603C8.61806 12.1584 8.40891 12.0707 8.2543 11.9161C8.0997 11.7615 8.012 11.5523 8.0101 11.3337C8.0082 11.1151 8.09225 10.9044 8.24415 10.7471L10.9901 8.00121L8.24415 5.25527C8.08782 5.0989 8 4.88684 8 4.66572C8 4.44461 8.08782 4.23255 8.24415 4.07618Z" />
                  <path d="M3.24122 4.07618C3.39759 3.91985 3.60965 3.83203 3.83076 3.83203C4.05188 3.83203 4.26394 3.91985 4.42031 4.07618L7.75579 7.41166C7.91212 7.56804 7.99994 7.7801 7.99994 8.00121C7.99994 8.22232 7.91212 8.43438 7.75579 8.59076L4.42031 11.9262C4.26304 12.0781 4.0524 12.1622 3.83376 12.1603C3.61513 12.1584 3.40598 12.0707 3.25137 11.9161C3.09677 11.7615 3.00907 11.5523 3.00717 11.3337C3.00527 11.1151 3.08932 10.9044 3.24122 10.7471L5.98715 8.00121L3.24122 5.25527C3.08489 5.0989 2.99707 4.88684 2.99707 4.66572C2.99707 4.44461 3.08489 4.23255 3.24122 4.07618Z" />
                </svg>
              </Tooltip>
            </span>
          )
        }
      </div>

      {
        open && (
          <>
            {
              data?.length ? (
                <div className='hot-answer-ul'>
                  {
                    data?.map((v: any, index: number) => (
                      <Tooltip open={v?.showTooltip ? true : false} key={index} placement="bottomRight" title={v?.title} arrow={false} color='white' overlayClassName='platfrom-tooltip-1'>
                        <div onMouseEnter={(e: any) => onMouseEnter(e, index)} onMouseLeave={(e: any) => onMouseLeave(e, index)} onClick={() => onAnswerClick(v)}>{v?.title}</div>
                      </Tooltip>
                    ))
                  }
                </div>
              ) : (
                <div className='hot-answer-empty'>
                  暂无内容
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}

HotAnswer = forwardRef(HotAnswer as any)

export default HotAnswer;


