import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Content from '../../components/Content';

type BasicLayoutProps = {
  sider?: boolean       // 是否需要侧边导航栏默认需要
}

const BasicLayout: React.FC<BasicLayoutProps> = (props) => {
  const { sider = true } = props
  
  return <Layout>
    <Header />
    <Content sider={sider} />
    <Footer />
  </Layout>
}

export default BasicLayout;