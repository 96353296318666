import { useCallback, useEffect, useRef } from "react";
import { repoExamLog } from "../api/modules/exam";
import { useSearchParams } from "react-router-dom";

// 禁用复制粘贴剪切
export const useDisableCopyPaste = () => {

    const [search] = useSearchParams()
    let examRecordCode = search.get('examRecordCode') || ''

    const handle = useCallback((e: any) => {
        if (e.type === 'copy') {
            repoExamLog({
                action: 'COPY',
                examRecordCode
            }).catch(() => {})
        } else if (e.type === 'paste') {
            repoExamLog({
                action: 'PASTE',
                examRecordCode
            }).catch(() => {})
        }
        e.preventDefault()
    }, [])

    // 取消禁用
    const cancel = useCallback(() => {
        document?.removeEventListener('copy', handle)
        document?.removeEventListener('paste', handle)
        document?.removeEventListener('cut', handle)
        isDisable.current = false
    }, [])

    const isDisable = useRef(false)

    // 禁止复制粘贴剪切
    const disable = useCallback(() => {
        if (isDisable.current) {
            cancel()
        }
        document?.addEventListener('copy', handle)
        document?.addEventListener('paste', handle)
        document?.addEventListener('cut', handle)
        isDisable.current = true
    }, [])

    // 兜底，没有销毁手动销毁
    useEffect(() => {
       return () => {
        if (isDisable.current) {
            cancel() 
        }
       } 
    }, [])

    return {
        cancel,
        disable
    }
}