interface ConfigType {
  tokenName: string
  baseURL: string | undefined //请求接口
  wsURL: string | undefined //websocket
  requestTimeOut: number,
  openURL: string | undefined,
  ptyURL: string | undefined
  ojURL: string | undefined
  reportURL: string | undefined      // 数据上报服务
  codeplayerURL: string | undefined  // codeplayerurl
  yunyanURL: string | undefined      // 云燕
  kaiyuanURL: string | undefined     // 开源学堂
  yunyanURLTop: string | undefined   // 云燕顶级域名
  kaiyuanURLTop: string | undefined  // 开源学堂顶级域名
  codeTestURL: string | undefined    // 开源学堂顶级域名
  monacoCDN: string | undefined      // 编辑器cdn
  difyURL: string | undefined        // 生成式对话
}

const config: ConfigType = {
  tokenName: 'accessToken',
  // 蚂蚁环境
  // baseURL: 'https://community.oscollege.net/api/v0.3',
  // openURL: 'https://openapi.oscollege.net',
  // wsURL: 'wss://community.oscollege.net',
  // 测试环境
  baseURL: process.env.REACT_APP_API_URL,
  openURL: process.env.REACT_APP_API_URL_OPEN,
  wsURL: process.env.REACT_APP_WS_URL,
  ptyURL: process.env.REACT_APP_PTY_URL,
  ojURL: process.env.REACT_APP_OJ_URL,
  reportURL: process.env.REACT_APP_REPORT_URL,
  codeplayerURL: process.env.REACT_APP_CODEPLAYER,
  yunyanURL: process.env.REACT_APP_YUNYAN,
  kaiyuanURL: process.env.REACT_APP_KAIYUAN,
  // yunyanURL: 'http://xxx.yun.localhost:3000',
  // kaiyuanURL: 'http://xxx.xxx.localhost:3000',
  yunyanURLTop: process.env.REACT_APP_YUNYAN_YOP,
  kaiyuanURLTop: process.env.REACT_APP_KAIYUAN_TOP,
  codeTestURL: process.env.REACT_APP_CodeTest_URL,
  monacoCDN: process.env.REACT_APP_MONACO_CDN,
  difyURL: process.env.REACT_APP_DIFY,

  // 本地环境
  // baseURL: 'http://192.168.24.170:8080/api/v0.3',
  // openURL: 'https://openapi.grjky.com',
  // wsURL: 'wss://192.168.24.170:8080',
  requestTimeOut: 1000 * 60 * 5
}

export default config
