import { Tooltip } from 'antd'
import React, { Fragment, useState, useEffect } from 'react'
// import SvgIcon from '../../SvgIcon'
import './index.less'

export type TreeProps = {
  theme?: 'dark' | 'light' //主题
  treeData?: Array<any> //tree数据
  selectedKey?: any //选中的key
  onSelected?: (value: any) => void //选中的标题
  [other: string]: any
}

const Tree: React.FC<TreeProps> = (props) => {

  const {
    theme = 'dark',
    treeData = [],
    onSelected
  } = props

  // 折叠的index
  const [foldKeys, setFoldKeys] = useState<number[]>([])

  // 目录数据
  const [data, setData] = useState<any>([])

  // 标题1-6
  const [depths, setDepths] = useState<number[]>([])

  // 高亮的key
  const [currentKey, setCurrentKey] = useState()

  // 判断是否出现省略号，出现显示tooltip
  const onMouseEnter = (e: any, index: number) => {
    const target = e?.target
    if (target?.scrollWidth > target?.clientWidth) {
      data[index].showTooltip = true
      setData([...data])
    }
  }

  // 不显示tootip
  const onMouseLeave = (_: any, index: number) => {
    data[index].showTooltip = false
    setData([...data])
  }

  // 选中
  const onSelect = (value: any) => {
    // setCurrentKey(value?.position?.start?.line)
    // 用id确定选中
    setCurrentKey(value?.id)
    onSelected?.(value)
  }

  // 展开折叠
  const onFold = (index: number) => {
    foldKeys.includes(index) ? foldKeys.splice(foldKeys.indexOf(index), 1) : foldKeys.push(index)
    setFoldKeys([...foldKeys])
  }

  // 寻找type为text的标签
  const findText: any = (arr: any) => {
    const obj = arr[0]
    if (obj?.type === 'text') {
      return obj?.value
    } else if (obj?.children?.length) {
      return findText(obj?.children)
    }
  }

  useEffect(() => {
    treeData?.forEach((v: any) => v.hidden = false)
    if (foldKeys?.length) {
      foldKeys?.forEach((_index: any) => {
        const depth = treeData[_index]?.depth
        for (let index = _index + 1; index < treeData?.length; index++) {
          const element = treeData[index];
          if (element?.depth > depth) {
            element.hidden = true
          } else {
            break;
          }
        }
      })
    }
    setData([...treeData])
  }, [treeData, foldKeys])

  // 有几种标题，用于控制左边距
  useEffect(() => {
    let _depths = treeData?.map((v: any) => v?.depth)
    // 去重
    _depths = _depths?.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    _depths?.sort((a, b) => a - b)
    setDepths(_depths)
  }, [treeData])

  return (
    <div className={`blog-tree ${theme === 'dark' ? 'dark-tree' : 'light-tree'}`}>
      {
        data?.map((v: any, index: number) => (
          // <Fragment key={v?.position?.start?.line}>
          <Fragment key={v?.id}>
            {
              !v?.hidden ? (
                <div className={`indent-level-${depths?.indexOf(v?.depth) + 1} ${currentKey === v?.id ? 'blog-tree-selected' : ''}`}>
                {/* <div className={`indent-level-${depths?.indexOf(v?.depth) + 1} ${currentKey === v?.position?.start?.line ? 'blog-tree-selected' : ''}`}> */}
                  <span className='tree-arrow'>
                    {
                      v?.depth < data[index + 1]?.depth ? (
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style={foldKeys?.includes(index) ? { transform: 'rotate(-90deg)' } : {}} onClick={() => onFold(index)}>
                          <rect opacity="0.01" width="16" height="16" />
                          <path d="M11.7801 5.26735L7.81758 9.56044L3.85467 5.26735C3.65032 5.04594 3.30517 5.0321 3.08376 5.23644C2.86234 5.44079 2.8485 5.78594 3.05285 6.00735L7.41649 10.7346C7.51975 10.8466 7.6651 10.9102 7.8174 10.9102C7.96969 10.9102 8.11504 10.8466 8.2183 10.7346L12.5819 6.00735C12.7863 5.78594 12.7724 5.44079 12.551 5.23644C12.3296 5.0321 11.9845 5.04594 11.7801 5.26735" />
                        </svg>
                      ) : <></>
                    }
                  </span>
                  <Tooltip open={v?.showTooltip ? true : false} key={`tree-text-${index}`} placement="bottomLeft" arrow={false} title={findText(v?.children)} color={'white'} overlayClassName='platfrom-tooltip-1'>
                    <span className='tree-text' onMouseEnter={(e: any) => onMouseEnter(e, index)} onMouseLeave={(e: any) => onMouseLeave(e, index)} onClick={() => onSelect(v)}>{findText(v?.children)}</span>
                  </Tooltip>
                </div>
              ) : <></>
            }
          </Fragment>
        ))
      }
    </div>
  )
}

export default Tree
