import React from 'react';
import { Layout } from 'antd';
import './index.less'
import Header from '../../components/Header';
import { Outlet } from 'react-router-dom';

const BasicLayout: React.FC = () => (
  <Layout>
    <Header type='lab'/>
    <div className='lab-layout-outlet'>
      <Outlet />
    </div>
  </Layout>
);

export default BasicLayout;