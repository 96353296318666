import React, { useEffect, useState } from 'react'
import './index.less'
import RemarkLeft from './components/RemarkLeft'
import RemarkRigth from './components/RemarkRigth'
import * as url from '../../utils/url'
import { getProblemDetaile, issueDetail } from '../../api/modules/issue'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ExclamationCircleFilled } from '@ant-design/icons';
import JoinModal from '../../pages/StudyLabCourse/components/ImportModal'
import { ifJoin, jionLabs, labsList } from '../../api/modules/product'
import { Modal, message } from 'antd'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { changeIsLogin } from '../../store/modules/loginSlice'
import { getUserInfo } from '../../api/modules/person'
import VerifyLoading from '../../pages/ProductCourse/components/VerifyLoading'

type RemarkProps = {
    value?: any
}

const RemarkAnswer: React.FC<RemarkProps> = (props) => {
    const labcode = url.getLabCodeFromHost()
    const [search] = useSearchParams()
    const newLabcode = search.get('labCode') || ''
    const code = search.get('code') || ''
    let id = search.get('id') || ''

    const { coucode } = useParams()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { userInfo } = useSelector((state: any) => state.loginUser)

    //课程是否处于克隆中
    const [lodingValue, setLodingValue] = useState(false)

    //issue详情
    const [issueDetails, setIssueDetails] = useState()

    //是否有表单需要填写
    const [isOpenJoin, setIsOpenJoin] = useState({
        isJoin: false,
        isOpenFrom: false,
    })

    //表单需要填写的内容
    const [openFromValue, setOpenFromValue] = useState([])

    // 是否点击加入实验室
    const [clickJion, setclickJion] = useState(false)

    const getDetail = () => {
        getProblemDetaile({
            labCode: newLabcode ? newLabcode : labcode,
            code: code ? code : coucode,
            id: id
        }).then(res => {
            setIssueDetails(res)
        })
    }

    //用户是否加入实验室
    const getJion = () => {
        ifJoin({ labCode: newLabcode ? newLabcode : labcode }).then(res => {
            setIsOpenJoin({
                isJoin: res.isJoin,
                isOpenFrom: res.isOpenFrom,
            })
        })
    }

    const gitNo = () => {

        Modal.confirm({
            title: '暂无权限',
            icon: <ExclamationCircleFilled />,
            content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
            okText: '去设置  ',
            centered: true,
            width: 400,
            cancelText: '我再想想',
            onOk() {
                navigate(`/personal`)
            },
            onCancel() {

            },
        })
    };

    //点击新建Issue
    const creatIssue = () => {
        if (isOpenJoin.isJoin) {
            let v = false
            getUserInfo().then(res => {

                res?.thirdUsers?.forEach((item: any) => {
                    if (item.success) {
                        v = true
                    } else {
                        gitNo()
                    }
                })
                return v
            })
        } else {
            jionLab()
            return false
        }
    }

    //未加入实验室弹窗
    const jionLab = () => {
        const token = Cookies.get('token')
        if (!token || !userInfo?.userId) {
            dispatch(changeIsLogin({ isLogin: true }))
            return
        }
        Modal.confirm({
            title: '加入实验室',
            icon: <ExclamationCircleFilled />,
            content: '您还未加入实验室无法进入学习，请先加入实验室～',
            okText: '加入实验室',
            centered: true,
            cancelText: '我再看看',
            onOk() {
                joinLab()
            },
            onCancel() {

            },
        })
    };

    //加入实验室
    const joinLab = () => {
        const token = Cookies.get('token')
        if (!token || !userInfo?.userId) {
            dispatch(changeIsLogin({ isLogin: true }))
            return
        }
        if (!isOpenJoin.isJoin) {
            if (isOpenJoin.isOpenFrom) {
                labsList({ labCode: labcode }).then(res => {
                    setOpenFromValue(res)
                    setclickJion(true)
                })
            } else {
                jionLabs({ labCode: labcode, recordList: [] }).then(() => {
                    message.success('加入成功！')
                    getJion()
                })
            }
        }
    }
    useEffect(() => {
        getDetail()
    }, [Cookies.get('token')])

    useEffect(() => {
        if (labcode || newLabcode) {
            // console.log(labcode);
            getJion()
        }
    }, [labcode, newLabcode, Cookies.get('token')])

    return (
        <div className='remark-answer'>
            {lodingValue && <VerifyLoading data={'课程加载中......'}></VerifyLoading>}
            <JoinModal labCode={labcode ?? ''} show={clickJion && isOpenJoin.isOpenFrom} value={openFromValue} changeClick={(e: any) => {
                if (e) {
                    setclickJion(false)
                    getJion()
                } else {
                    setclickJion(false)
                }
            }}></JoinModal>
            <RemarkLeft value={issueDetails} ifJoin={labcode ? isOpenJoin.isJoin : true} getValue={() => {
                getDetail()
            }} joinLab={jionLab} gitNo={gitNo}></RemarkLeft>
            <RemarkRigth value={issueDetails} ifJoin={labcode ? isOpenJoin.isJoin : true} getValue={() => {
                getDetail()
            }} joinLab={jionLab} gitNo={gitNo} setLodingValue={(value: any) => {
                setLodingValue(value)
            }}></RemarkRigth>
        </div>
    )
}

export default RemarkAnswer