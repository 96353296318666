import React, { useState, useEffect } from 'react'
import "./index.less"
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux';
import { updateRoutes } from '../../../../store/modules/breadcrumbSlice';

type ListItemProps = {
  value?: any
}

const ListItem: React.FC<ListItemProps> = (props) => {

  const { value } = props

  const navigate = useNavigate()

  const [messageIcon, setMessageIcon] = useState(false)


  const dispatch = useDispatch()
  return (
    <div className='issueItem' onClick={() => {
      navigate(`/os/is/rm?id=${value.id}`)
      dispatch(updateRoutes({
        routes: [
          {
            path: '/os/is',
            title: 'issue'
          },
          {
            path: ``,
            title: 'issue详情'
          }
        ]
      }))
    }}>
      <div className='issueItem-top'>
        <div className='issueItem-top-left'>
          <div>
            {value?.statusCode === 'open' && <img src={require('../../../../assets/images/issue/stutaIcon3.png')} style={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '10px' }} alt="" />}
            {value?.statusCode === 'closed' && <img src={require('../../../../assets/images/issue/stutaIcon1.png')} style={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '10px' }} alt="" />}
          </div>
          <div style={{ maxWidth: '750px', lineHeight: '24px', wordWrap: 'break-word' }}>
            <span className='issueItem-question'>{value.title}</span>
            {
              value?.showLabelAndColor.map((item: any) => {
                return <span className='issueItem-lable' style={{ background: item?.color === '#fff' || !item?.color ? ' #63BFDC' : `#${item?.color}` }}>
                  {item?.name}
                </span>
              })
            }
          </div>
        </div>
        {value?.answerCount > 0 && <div className='issueItem-top-rigth' onMouseEnter={() => {
          setMessageIcon(true)
        }}
          onMouseLeave={() => setMessageIcon(false)}>
          <div>
            {messageIcon ? <img src={require('../../../../assets/images/issue/message1.png')} style={{ width: '16px', height: '16px', marginRight: '4px' }} alt="" /> : <img src={require('../../../../assets/images/issue/message2.png')} style={{ width: '16px', height: '16px', marginRight: '4px' }} alt="" />}
          </div>
          <div>{value?.answerCount}</div>
        </div>}
      </div>
      <div className='issueItem-Time' >
        <div className='userName'>{value?.userInfo?.displayName}</div>
        <div className='itemTime'>{value?.createDateTime}</div>
      </div>
    </div >
  )
}

export default ListItem;
