import React from 'react';
import './index.less'

const VerifyLoading: React.FC<any> = (props) => {
  const { btnText, data, cancel, cancelClick } = props
  return (
    <div className='verify-loading'>
      <div className='loading-line'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <h1><span>{data}</span></h1>
      {cancel && <div className='cancelBtn' onClick={() => {
        cancelClick()
      }}>{btnText ? btnText : '后台导入'}</div>}
    </div>
  );
};

export default VerifyLoading;