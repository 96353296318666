import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import Cookies from 'js-cookie'
import { message, Modal } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import './index.less'
import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import TableLoading from '../../components/TableLoading' //加载框
import VerifyLoading from '../ProductCourse/components/VerifyLoading' //克隆加载框
import Blog from './components/Blog' //博客详情
import Course from './components/Course' //课程详情
import JoinModal from '../StudyLabCourse/components/ImportModal' //加入实验室
import { updateRoutes } from '../../store/modules/breadcrumbSlice'
import { changeIsLogin } from '../../store/modules/loginSlice'
import { getDetailCourse, existOpen, getStudyWorkspace, getWorkspace, courseClone, getCourseDetailAndPro } from '../../api/modules/study'
import { ifJoin, jionLabs, labsList } from '../../api/modules/product'
import { generateRandomTimeString } from '../../utils/randomTime'
import { generateHomeUrl, getSecondHost, getStudy } from '../../utils/url'
import { LabStatus } from '../../utils/globalConst'
import BehaviorCenter from '../../utils/userBehaviorCenter'

type CourseDetailProps = {
  code?: string //课程编码
  statu?: boolean //预览状态
}

// 课程类型 BLOG：博客 COURSE：课程  EXPERIENCE：快速体验课程
const courseTypes = {
  COURSE: 'COURSE',
  BLOG: 'BLOG',
  EXPERIENCE: 'EXPERIENCE'
}

const CourseDetail: React.FC<CourseDetailProps> = (props) => {
  const { code, statu } = props

  const [params] = useSearchParams()

  // 预览：true：预览状态
  const viewStatu = params.getAll("statu")[0]

  //Pod.yaml路径
  const location = params.get("location")


  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { userInfo } = useSelector((state: any) => state.loginUser)

  const { courseSpeed } = useSelector((state: any) => state.courseSpeed)

  // 课程编码
  const { coucode = code } = useParams()

  // 加载效果
  const [loading, setLoading] = useState(true)

  // 课程详情信息
  const [courseInfo, setCourseInfo] = useState<any>()

  // 课程学习进度
  const [nowSpeed, setNowSpeed] = useState('')

  // 进入工作台克隆加载
  const [lodingValue, setLodingValue] = useState(false)

  // 获取课程类型，用于判别显示博客详情、课程详情
  const fetchDetailCourse = () => {
    getCourseDetailAndPro({ code: coucode ?? code }).then(res => {
      res.template = res?.template ? JSON.parse(res?.template) : ''
      setCourseInfo(res)
      setLoading(false)
      // 实验室下架
      if (!(viewStatu || statu) && res?.labStatus !== LabStatus.created) {
        message.destroy()
        message.warning('实验室未发布，无法访问！')
        window.open(generateHomeUrl('center', res?.labType), '_self')
        return
      }
      // 课程下架
      if (res?.statusCode !== 1 && !(viewStatu || statu)) {
        onWarning(res?.type)
      }
    }).catch((err) => {
      setLoading(false)
      if (err.status === 403) {
        navigate(`/404NotFound`)
      }
    })
  }

  // 课程下架提醒  博客类型  课程类型 快速体验
  const onWarning = (type: 'BLOG' | 'COURSE' | 'EXPERIENCE' = 'COURSE') => {
    Modal.warning({
      title: '提示',
      content: `当前${type === 'COURSE' ? '课程' : type === 'BLOG' ? '博客' : '快速体验内容'}已下架，若需学习，请同创建者联系~`,
      onOk: () => {
        const url = getStudy()
        if (url) {
          window.open(url, '_self')
        }
        // window.open(window.location.origin, '_self')
      },
      centered: true,
      okText: '我知道了'
    })
  }

  // 获取课程数据
  useEffect(() => {
    fetchDetailCourse()
  }, [Cookies.get('token')])

  // 面包屑数据
  useEffect(() => {
    if (!viewStatu && !statu && courseInfo?.type) {
      dispatch(updateRoutes({
        routes: [
          {
            path: `/os`,
            title: '实验室'
          },
          {
            path: `/os/${code || coucode}`,
            title: `${courseInfo?.type === courseTypes.BLOG ? '博客' : courseInfo?.type === courseTypes.COURSE ? '课程' : '快速体验内容'}详情`
          }
        ]
      }))
    }
  }, [courseInfo])

  // 学习进度
  useEffect(() => {
    setNowSpeed(courseSpeed)
  }, [courseSpeed])

  // 加入实验室前，记录当前触发的小节名称，加入实验室后，直接跳转工作台
  const joinChapter = useRef('')

  // 跳转工作台：判断登录 => 判断是否加入实验室 => 判断当前工作台是否已开启 => 进入工作台
  const ifOpenWorkSpace = (name?: any) => {
    const token = Cookies.get('token')
    // || !userInfo?.userId
    if (!token) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    ifJoin({ labCode: courseInfo?.labCode }).then(res => {
      if (res?.isJoin) {
        existOpen({
          code: coucode,
          modal: 'read'
        }).then(res => {
          if (res?.exist) {
            Modal.warning({
              title: '温馨提示',
              icon: <ExclamationCircleFilled />,
              content: <p >当前课程工作台已开启，请到对应标签页进行学习!</p>,
              okText: '我已知晓',
              centered: true,
              onOk() { }
            })
          } else {
            gotoWorkSpace(typeof name === 'string' ? name : '')
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        Modal.confirm({
          title: '加入实验室',
          icon: <ExclamationCircleFilled />,
          content: '您还未加入实验室无法开始学习，请先加入实验室～',
          okText: '加入实验室',
          cancelText: '我再看看',
          centered: true,
          onOk() {
            joinChapter.current = name
            joinLab()
          },
          onCancel() {
          },
        });
      }
    })
  }

  //发布问答

  // 前往工作台：判断博客、课程 => 是否需要克隆课程 => 判断学习、预览 => 进入工作台
  const gotoWorkSpace = async (name?: any) => {
    console.log(name);

    if (typeof document.createElement === 'function') {
      // 删除所有<a>元素
      let links = document.querySelectorAll(".link-class")
      for (let i = links.length - 1; i >= 0; i--) {
        links[i].parentNode?.removeChild(links[i]);
      }
    }
    // 博客：无需克隆课程（进入详情时已经直接克隆）
    // 课程：第一次需先克隆
    if (!nowSpeed && courseInfo?.type === courseTypes.COURSE) {
      try {
        setLodingValue(true)
        await courseClone({ code: coucode ?? code })
        setLodingValue(false)
      } catch (error) {
        setLodingValue(false)
        message.error('拉取课程失败')
        return
      }
    }
    if (!viewStatu) {
      getStudyWorkspace({
        code: coucode,
        userid: userInfo.userId,
        modal: 'read'
      }).then(res => {
        const { openApiAddr, access, repo } = res
        const url = `${openApiAddr}/?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(name ? name : '')}&time=${generateRandomTimeString()}${location ? `&location=${location}` : ''}`
        let link = document.createElement('a');
        link.classList.add("link-class");
        link.href = url;
        link.target = 'page';
        let event = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': true
        });
        link.dispatchEvent(event);
      })
    } else {
      getWorkspace({
        code: coucode,
        userid: userInfo.userId,
        modal: 'read'
      }).then(res => {
        const { openApiAddr, access, repo } = res
        const url = `${openApiAddr}?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(name ? name : '')}&time=${generateRandomTimeString()}&preview=${1}`
        let link = document.createElement('a');
        link.classList.add("link-class");
        link.href = url;
        link.target = 'page';
        let event = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': true
        });
        link.dispatchEvent(event);
      })
    }
  }

  /* 加入实验室 start */

  // 是否点击加入实验室
  const [clickJion, setclickJion] = useState(false)

  const [openFromValue, setOpenFromValue] = useState([])

  //是否有表单需要填写
  const [isOpenJoin, setIsOpenJoin] = useState({
    isJoin: false,
    isOpenFrom: false,
  })

  // 获取用户是否加入实验室
  const getJion = (callback?: Function) => {
    ifJoin({ labCode: courseInfo?.labCode }).then(res => {
      setIsOpenJoin({
        isJoin: res.isJoin,
        isOpenFrom: res.isOpenFrom,
      })

      window.sessionStorage.setItem('isOpenJoin', JSON.stringify({
        isJoin: res.isJoin,
        isOpenFrom: res.isOpenFrom,
      }))
      callback?.()
    })
  }

  //加入实验室
  const joinLab = () => {
    if (!isOpenJoin.isJoin) {
      if (isOpenJoin.isOpenFrom) {
        labsList({ labCode: courseInfo?.labCode }).then((res: any) => {
          setOpenFromValue(res)
          setclickJion(true)
        })
      } else {
        jionLabs({ labCode: courseInfo?.labCode, recordList: [] }).then(() => {
          message.success('加入成功！')
          getJion(() => {
            ifOpenWorkSpace(joinChapter?.current ?? '')
          })
        })
      }
    }
  }

  //判断用户是否加入实验室
  useEffect(() => {
    if (courseInfo) {
      getJion()
    }
  }, [Cookies.get('token'), courseInfo])

  /* 加入实验室 end */

  // 数据埋点
  useEffect(() => {
    if (!viewStatu && !statu && courseInfo?.labCode && userInfo.userId) {
      BehaviorCenter.addTask('labVisitCount', {
        labCode: courseInfo?.labCode,
        userId: userInfo.userId
      })
    }
  }, [statu, viewStatu, courseInfo?.labCode, userInfo?.userId])

  return (
    <div className='detailcourse' style={statu ? { paddingTop: 0 } : {}}>
      {
        statu && <div className='mask'></div>
      }
      {
        !viewStatu && !statu ? <Header /> : (
          <div className='detailcourse-preview' onClick={() => {
            window.close()
          }}>
            结束预览
          </div>
        )
      }
      <div className='detailcourse-content'>
        {
          !viewStatu && !statu ? (
            <div >
              <Breadcrumb />
            </div>
          ) : <></>
        }
        {
          loading ? (
            <div className='detailcourse-content-loading'>
              <TableLoading />
            </div>
          ) : (
            <>
              {(courseInfo?.type === courseTypes.BLOG || courseInfo?.type === courseTypes.EXPERIENCE) && <Blog data={courseInfo} viewStatus={viewStatu || statu} ifOpenWorkSpace={(value: any) => {
                ifOpenWorkSpace(value ?? '')
              }} />}
              {courseInfo?.type === courseTypes.COURSE && <Course data={courseInfo} viewStatus={viewStatu || statu} ifOpenWorkSpace={(value: any) => {
                ifOpenWorkSpace(value ?? '')
              }} />}
            </>
          )
        }
        <JoinModal labCode={courseInfo?.labCode ?? ''} show={clickJion && isOpenJoin.isOpenFrom} value={openFromValue} changeClick={(e: any) => {
          if (e) {
            setclickJion(false)
            getJion(() => {
              ifOpenWorkSpace(joinChapter?.current ?? '')
            })
          } else {
            setclickJion(false)
          }
        }}></JoinModal>

        {lodingValue && <VerifyLoading data={'课程加载中......'}></VerifyLoading>}
      </div>
    </div>
  );
}

export default CourseDetail;
