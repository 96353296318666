import React, { useState, useEffect } from 'react'
import "./index.less"
import IssueList from './components/issueList';



const IssuePage: React.FC = () => {





  return (
    <div className='issuePage'>
      <div className='issuePage-main'>
        <IssueList></IssueList>
      </div>
    </div>
  )
}

export default IssuePage;
