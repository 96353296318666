import React, { useEffect, useState } from 'react';
import './index.less';
import { Button, Checkbox, Form, Input, Modal, Radio, Space, message } from 'antd';
import { getGithubValue, jionLabs } from '../../../../api/modules/product';


type EnlargeProps = {
  show: boolean
  value: any
  changeClick: (v: any) => void;
  labCode: string
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

type inputProps = {
  value: any
}

const JoinInput: React.FC<inputProps> = (props) => {

  return (
    <Form.Item label={props.value?.name ?? ''}>
      <Form.Item
        validateTrigger="onBlur"
        noStyle
        name={props.value?.extendCode}
      >
        <Input placeholder={`请输入…`} autoComplete="off" showCount allowClear maxLength={50} onFocus={() => {

        }}
        />
      </Form.Item>
    </Form.Item>
  )
}




const JoinRadio: React.FC<inputProps> = (props) => {

  const [option, setOption] = useState([])

  useEffect(() => {
    if (props.value?.option) {
      let list = JSON.parse(props.value.option)
      setOption(list)
    }

  }, [props.value])

  return (

    <div className='onClick'>
      <Form.Item label={props.value?.name ?? ''}>
        <Form.Item
          validateTrigger="onBlur"
          noStyle
          name={props.value?.extendCode}
        >
          <Radio.Group defaultValue={1}>
            <Space direction="vertical">
              {
                option?.map((item: any) => {
                  return <Radio value={item}>{item}</Radio>
                })
              }
            </Space>
          </Radio.Group>

        </Form.Item>
      </Form.Item>
    </div>

  )
}

const JoinCheckbox: React.FC<inputProps> = (props) => {



  const [option, setOption] = useState([])

  useEffect(() => {
    if (props.value?.option) {
      let list = JSON.parse(props.value.option)
      setOption(list)
    }

  }, [props.value])

  return (
    <div className='towClick'>
      <Form.Item label={props.value?.name}>
        <Form.Item
          validateTrigger="onBlur"
          noStyle
          name={props.value?.extendCode}
          rules={[
            { type: 'array', min: 2, message: '请至少选择两个选项' },
          ]}
        >
          <Checkbox.Group>
            <Space direction="vertical">
              {
                option?.map((item: any) => {
                  return <Checkbox value={item} style={{ lineHeight: '32px' }}>
                    {item}
                  </Checkbox>
                })
              }
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Form.Item>
    </div>
  )
}


// const JoinTextArea: React.FC = () => {

//   return (
//     <Form.Item label='文本'>
//       <Form.Item
//         validateTrigger="onBlur"
//         noStyle
//       >
//         <Input.TextArea
//           style={{ resize: "none" }}
//           autoSize={{ minRows: 4, maxRows: 11 }}
//           placeholder="请输入实验室简介"
//         />

//       </Form.Item>
//     </Form.Item>
//   )
// }

const JoinModal: React.FC<EnlargeProps> = (props) => {


  // 删除图标
  const [deleteIcon, setDeleteIcon] = useState<boolean>(false);

  const [jionText, setJionText] = useState<boolean>(false);


  const [form] = Form.useForm();

  const [fields, setFields] = useState<FieldData[]>(
    [
      { name: ['gitHub'], value: '' },
      { name: ['gitHub'], value: '' },
    ]);

  const onFinish = (values: any) => {
    console.log(values);
    let list: any[] = []
    Object.entries(values).forEach(([key, value]) => {
      if (!value) {
        return
      }
      if (Array.isArray(value)) {
        // value = value.join(",")
        value = JSON.stringify(value)
      }
      list.push({ extendCode: key, optionResult: value })

    });
    if (list.length < props.value?.length) {
      setJionText(true)
      return
    }
    console.log(list);

    jionLabs({ labCode: props.labCode, recordList: list }).then((res) => {
      if (res) {
        props.changeClick(true)
        message.success('加入成功！')
      }
    })

  };

  useEffect(() => {
    if (props.value && props.show) {
      form.resetFields()
      const joinList = props.value.map((item: any) => {


        return {
          name: [item.name], value: ''
        }
      })
      console.log(joinList);

    }
  }, [props.value, props.show])


  return (
    <Modal wrapClassName={'joinModal'} open={props.show} footer={''} width={'700px'} destroyOnClose centered>
      <div className='modalAll1' style={{ maxHeight: '80vh' }}>
        <div style={{ position: 'absolute', top: '4px', right: '4px', cursor: 'pointer' }}>
          <div onClick={() => {
            props.changeClick(false)
          }} onMouseEnter={() => setDeleteIcon(true)} onMouseLeave={() => setDeleteIcon(false)}>
            {deleteIcon ? <img src={require('../../../../assets/images/login-iconActive.png')} alt="" /> : <img src={require('../../../../assets/images/login-icon.png')} alt="" />}
          </div>
        </div>
        <div className='modalAll-title'>加入实验室前，请先输入以下信息</div>
        <div style={{ width: '100%', padding: '30px 32px 10px' }}>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{}}
            fields={fields}
            layout="vertical"
            onFieldsChange={(_, allFields) => {
              setJionText(false)
            }}
          >
            {/* <JoinTextArea></JoinTextArea> */}
            {props.value.map((item: any) => {
              if (item.type === '0') {
                return <JoinInput value={item}></JoinInput>
              } else if (item.type === '1') {
                return <JoinRadio value={item}></JoinRadio>
              } else if (item.type === '2') {
                return <JoinCheckbox value={item}></JoinCheckbox>
              }
            })}
            <Form.Item>
              <div className="bottomButs">
                <div className='bottonLeft' onClick={() => {
                  props.changeClick(false)
                }}>
                  取消
                </div>

                <Button type="primary" style={{ marginLeft: '40px' }} htmlType="submit" className="bottonRigth" onClick={(e: any) => {

                }} >
                  保存
                </Button>
                {jionText &&
                  <div className='jionText'>
                    您还有未输入的内容，请完成后提交！
                  </div>
                }
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default JoinModal;