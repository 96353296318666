import React, { useEffect, useState } from 'react'
import Mdast from '@ylzcc/mdast'
import Tree from './Tree'
import './index.less'

type BlogDirectoryProps = {
  mdText?: any //markdown文本数据
  header?: any // 标题
  editMode?: any //新旧版编辑器区分
  onSelected?: (value: any) => void //选中的标题
}

const BlogDirectory: React.FC<BlogDirectoryProps> = (props) => {

  const { mdText, onSelected, header, editMode } = props

  // 展开，默认展开
  const [open, setOpen] = useState(true)

  // 目录数据
  const [data, setData] = useState<any>([])

  // 点击目录：由于自定义语法影响了line
  const onSelectedTreeNode = (value?: any) => {
    if (editMode === 'new') {
      onSelected?.(value)
    } else {
      const ast = Mdast.parse(mdText)
      const _actives: any = ast?.children?.filter((v: any) => v?.type === 'code' && (v?.properties?.ext?.includes("exec") || v?.properties?.ext?.includes("copy") || v?.properties?.ext === "validate" || v?.properties?.ext?.startsWith('active')))
      const index = _actives?.findIndex((v: any) => v?.position?.start?.line > value?.position?.start?.line)
      const line = value?.position?.start?.line - index * 2
      onSelected?.(line)
    }
  }

  // 获取标题
  useEffect(() => {
    if (editMode !== 'new') {
      if (mdText) {
        const ast = Mdast.parse(mdText)
        let _headings = Mdast.heading.list(ast)
        _headings = _headings?.filter((v: any) => v?.properties?.tag !== 's')
        _headings?.forEach((element: any) => {
          element.id = element?.position?.start?.line
        });
        setData(_headings)
      } else {
        setData([])
      }
    }
  }, [mdText, editMode])

  // 获取标题改为直接用编辑器传出来的数据
  useEffect(() => {
    if (editMode === 'new') {
      if (header) {
        const res = header.map((item: any) => {
          return {
            ...item,
            hidden: false,
            depth: item.level,
            showTooltip: false,
            type: "heading",
            children: [
              {
                type: 'text',
                value: item.text
              }
            ]
          }
        })
        setData(res)
      } else {
        setData([])
      }
    }
  }, [header, editMode])


  return (
    <>
      {
        data?.length ? (
          <div className='blog-directory'>
            <div className='blog-directory-header' onClick={() => setOpen(!open)}>
              <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" style={!open ? { transform: 'rotate(-90deg)' } : {}}>
                <path d="M11.7178 3.86562L6.60489 9.45664C6.45255 9.63008 6.23223 9.72969 6.0002 9.72969C5.76934 9.72969 5.54903 9.63008 5.39551 9.45664L0.282623 3.86562C0.0330134 3.60195 -0.0443303 3.21758 0.0857478 2.87773C0.215826 2.53789 0.526373 2.30117 0.888482 2.26953H11.1096C11.4729 2.3 11.7857 2.53555 11.9147 2.87656C12.0447 3.21758 11.9674 3.59961 11.7178 3.86562Z" />
              </svg>
              <span>章节目录</span>
            </div>
            {
              open && <Tree treeData={data} theme="light" onSelected={onSelectedTreeNode} />
            }
          </div>
        ) : <></>
      }
    </>
  )
}

export default BlogDirectory;