import React, { useState, useEffect, useRef } from 'react'
import "./index.less"

import { Modal, Pagination, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getIssueList, getProblemList } from '../../../../../../api/modules/issue';
import * as url from '../../../../../../utils/url'
import { useNavigate, useParams } from 'react-router-dom';
import { ifJoin, jionLabs, labsList } from '../../../../../../api/modules/product';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsLogin } from '../../../../../../store/modules/loginSlice';
import JoinModal from '../../../../../StudyLabCourse/components/ImportModal';
import { getUserInfo } from '../../../../../../api/modules/person';
import TableLoading from '../../../../../../components/TableLoading';
import ListItem from './listItem';
import CreateAnswer from '../../../../../../components/CreateAnswer';


type CourseAnswerListProps = {
  type?: any
  show?: any
  getValue?: () => void //回传数据
  changeShow?: (v: any) => void //打开新建弹窗
  ifOpenWorkSpace?: (value: any) => void   //加入实验室
}


const CourseAnswerList: React.FC<CourseAnswerListProps> = (props) => {

  const labcode = url.getLabCodeFromHost()

  const { coucode } = useParams()

  const { ifOpenWorkSpace, getValue, changeShow, show, type } = props

  const [currentPage, setCurrentPage] = useState(1)                  // 分页器当前页数
  const [pageSize, setPageSize] = useState(10)                       // 分页器每一页的条数
  const [pageTotal, setPageTotal] = useState(0)

  //issue列表
  const [issueList, setIssueList] = useState<any>([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // 用户登录信息
  const { userInfo } = useSelector((state: any) => state.loginUser)

  //数据是否加载完成
  const [valueLoding, setValueLoding] = useState(false)

  //是否有表单需要填写
  const [isOpenJoin, setIsOpenJoin] = useState({
    isJoin: false,
    isOpenFrom: false,
  })

  //表单需要填写的内容
  const [openFromValue, setOpenFromValue] = useState([])

  // 是否点击加入实验室
  const [clickJion, setclickJion] = useState(false)

  //退出弹窗
  const exitConfirm = () => {

    Modal.confirm({
      title: '暂无权限',
      icon: <ExclamationCircleFilled />,
      content: '请确认账号并联系该GitHub项目Owner解决此问题。',
      okText: '我再想想',
      centered: true,
      width: 400,
      cancelText: '确认退出',
      onOk() { },
      onCancel() {

      },
    })
  };

  //未绑定git
  const gitNo = () => {

    Modal.confirm({
      title: '暂无权限',
      icon: <ExclamationCircleFilled />,
      content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
      okText: '去设置  ',
      centered: true,
      width: 400,
      cancelText: '我再想想',
      onOk() {
        navigate(`/user/github`)
      },
      onCancel() {

      },
    })
  };

  // 加入实验室前，记录当前触发的小节名称，加入实验室后，直接跳转工作台
  const joinChapter = useRef('')

  //未加入实验室弹窗
  const jionLab = (chapter?: string) => {
    Modal.confirm({
      title: '加入实验室',
      icon: <ExclamationCircleFilled />,
      content: '您还未加入实验室无法新建问答，请先加入实验室～',
      okText: '加入实验室',
      centered: true,
      cancelText: '我再看看',
      onOk() {
        joinChapter.current = chapter ?? ''
        joinLab()
      },
      onCancel() {

      },
    })
  };

  //点击新建Issue
  const creatIssue = () => {
    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    if (isOpenJoin.isJoin) {
      getUserInfo().then(res => {
        res?.thirdUsers?.forEach((item: any) => {
          if (item.success) {
            //进入新建
            navigate(`/os/is/createissue`)
          } else {
            gitNo()
          }
        })
      })
    } else {
      jionLab()
    }
  }


  const pageChage = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage)
    setPageSize(pageSize)
  }


  useEffect(() => {
    getProblem()
  }, [pageSize, currentPage])

  //获取issue列表
  const getProblem = () => {
    getProblemList({
      code: coucode,
      current: currentPage,
      size: pageSize,
      labCode: labcode
    }).then(res => {
      setValueLoding(true)
      if (res?.rows?.length === 0) {
        getValue?.()
      }
      setPageTotal(res?.total)
      setIssueList(res.rows)
    })
  }

  //加入实验室
  const joinLab = () => {
    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    if (!isOpenJoin.isJoin) {
      if (isOpenJoin.isOpenFrom) {
        labsList({ labCode: labcode }).then(res => {
          setOpenFromValue(res)
          setclickJion(true)
        })
      } else {
        jionLabs({ labCode: labcode, recordList: [] }).then(() => {
          message.success('加入成功！')
          getJion(() => {
            ifOpenWorkSpace?.(joinChapter?.current ?? '')
          })
        })
      }
    }
  }

  //用户是否加入实验室
  const getJion = (callback?: Function) => {
    ifJoin({ labCode: labcode }).then(res => {
      setIsOpenJoin({
        isJoin: res.isJoin,
        isOpenFrom: res.isOpenFrom,
      })
      callback?.()
    })
  }


  useEffect(() => {
    if (labcode) {
      console.log(labcode);
      getJion()
    }
  }, [labcode,])



  return (
    <div className='courseAnswerList'>
      <JoinModal labCode={labcode ?? ''} show={clickJion && isOpenJoin.isOpenFrom} value={openFromValue} changeClick={(e: any) => {
        if (e) {
          setclickJion(false)
          getJion(() => {
            ifOpenWorkSpace?.(joinChapter?.current ?? '')
          })
        } else {
          setclickJion(false)
        }
      }}></JoinModal>
      <CreateAnswer open={show} onClose={() => {
        changeShow?.(false)
      }} reValue={() => {
        if (currentPage === 1) {
          getProblem()
        } else {
          setCurrentPage(1)
        }
      }} />
      <div className='courseAnswerList-content' style={{ flex: issueList.length > 0 ? '' : '1' }}>
        {valueLoding && issueList.length > 0 && <div style={{ overflowY: "auto", height: '100%' }}>
          {
            issueList.map((item: any, index: any) => {
              return <ListItem value={item} key={item.id} ifJoin={isOpenJoin.isJoin} jion={jionLab} ifOpenWorkSpace={(name: any) => {
                ifOpenWorkSpace?.(name)
              }}></ListItem>
            })
          }

        </div>}

        {valueLoding && issueList.length === 0 && <div className='courseAnswerList-my'>
          <img src={require("../../../../../../assets/images/creatNovalue.png")} alt="" className='labs-my-img' />
          <div className='labs-my-text'>{type === 'Blog' ? '这里还没有任何内容，快去发布问答试试吧~' : '这里还没有人提出问题噢，先去学习看看吧~'}</div>
          {type === 'Blog' && <div className='labs-btn' onClick={() => {
            const token = Cookies.get('token')
            if (!token || !userInfo?.userId) {
              dispatch(changeIsLogin({ isLogin: true }))
              return
            }
            if (!isOpenJoin.isJoin) {
              jionLab()
            } else {
              changeShow?.(true)
            }
          }}>
            新建问答
          </div>}
        </div>}
        {!valueLoding && <div className='loding1'>
          <TableLoading title='加载中...' />
        </div>}
      </div>
      {issueList.length > 0 &&
        <div className='courseAnswerList-Pagination'>
          <Pagination
            current={currentPage}
            total={pageTotal}
            showSizeChanger
            showQuickJumper={true}
            onChange={pageChage}
            style={{ textAlign: 'right' }}
            pageSize={pageSize}
          />
        </div>
      }
    </div>
  )
}

export default CourseAnswerList;
