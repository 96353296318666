
import http from "../index";

// 获取实验室权限
export async function editUserInfo(params: any) {
    return http.post(`user/info/edit`, params)
}

// 获取实验室权限
export async function getUserInfo() {
    return http.get(`user/info/detail`)
}

//我的考试
export async function getMyExam(params: any) {
    return http.post(`exam/myExam`,params)
}

//我的学习
export async function getMyStudy(params: any) {
    return http.post(`course/study/page`,params)
}
