import { lazy, ReactNode, Suspense } from 'react';
import { useRoutes, Navigate, RouteObject } from 'react-router-dom'
import BasicLayout from '../layout/BasicLayout';
import LabLayout from '../layout/LabLayout';
import superAdmin from './superAdmin';
import adminRoutes from './admin';
import courseAdminRoutes from './courseAdmin';
import PersonalCenter from '../pages/personalCenter';
import MainLayout from '../layouts/MainLayout';
import IssuePage from '../pages/IssuePage';
import NormalLayout from '../layout/NormalLayout';

import IssueRemark from '../pages/IssueRemark';
import AnswerRemark from '../pages/AnswerRemark';
import BlogCreat from '../pages/BlogCreat';
import BlogAnswer from '../pages/BlogAnswer';
import AcmWorkspace from '../pages/AcmWorkspace';
import RootLogin from '../pages/RootLogin';

// const Home = lazy(() => import('../pages/Home'))          // 主页
// const Center = lazy(() => import('../pages/Center'))      // 广场
// const Template = lazy(() => import('../pages/Template'))      // 模板

const StudyLabCourse = lazy(() => import('../pages/StudyLabCourse'))      // 实验室主页

const GithubManage = lazy(() => import('../pages/personalCenter/GitHubManage'))      // 用户中心/Github管理

const ProductCourse = lazy(() => import('../pages/ProductCourse/index')) //创建课程
const CreatLaboratory = lazy(() => import('../pages/ProductCreat'))
const AnswerExam = lazy(() => import('../pages/AnswerExam'))             //考试页面
const ExamView = lazy(() => import('../pages/ExamView'))                 //查看考卷
const ExamMark = lazy(() => import('../pages/ExamMark'))                 //考试判分
const PStartExam = lazy(() => import('../pages/PtoPExam/PStartExam'))    //点对点开始考试
const PFailExam = lazy(() => import('../pages/PtoPExam/PFailExam'))      //点对点考试失败
const PSuccessExam = lazy(() => import('../pages/PtoPExam/PSuccessExam'))  //点对点考试成功

const CreateIssue = lazy(() => import('../pages/IssuePage/components/CreateIssue'))                 //考试判分

const CourseDetail = lazy(() => import('../pages/CourseDetail')) //课程、博客详情
const Experience = lazy(() => import('../pages/Experience'))     // 快速体验课程

const Certificate = lazy(() => import('../pages/Certificate'))   // 证书领取
const DownLoadCert = lazy(() => import('../pages/Certificate/DownloadCert'))   // 证书下载

const NotFound = lazy(() => import('../pages/Result/404')) // 404
const Login = lazy(() => import('../pages/Login')) // 登录页
const LoginSign = lazy(() => import('../pages/Loginsign')) // 登录页
const LoginSlient = lazy(() => import('../pages/LoginSlient')) // 登录静默页
const OauthSlient = lazy(() => import('../pages/OauthSlient')) // 三方登录静默页

// 懒加载需要包裹错误边界
const lazyLoad = (children: ReactNode): ReactNode => {
    return <Suspense>{children}</Suspense>
}


const routes = [
    // {
    //     path: '/',
    //     element: <BasicLayout />,
    //     children: [
    //         {
    //             index: true,
    //             element: <Home/>
    //         },
    //         {
    //             path: 'center',
    //             element: <Center />
    //         },
    //         {
    //             path: 'template',
    //             element: <Template />
    //         },
    //         {
    //             path: 'personal',
    //             element: <PersonalCenter />
    //         },

    //     ]
    // },
    {
        path: '/',
        element: <RootLogin />
    },
    {
        path: '/personal',
        element: <BasicLayout sider={false} />,
        children: [
            {
                index: true,
                element: <PersonalCenter />
            },
        ]
    },

    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/loginsign',
        element: <LoginSign />
    },
    {
        path: '/login/callback',
        element: <LoginSlient />
    },
    {
        path: '/oauth/callback',
        element: <OauthSlient />
    },

    {
        path: '/user',
        element: <NormalLayout />,
        children: [
            {
                path: 'github',
                element: <GithubManage />
            }
        ]
    },
    {
        path: '/certificate',
        element: <Certificate />
    },
    {
        path: '/certificate/download',
        element: <DownLoadCert />
    },
    {
        path: '/os',
        element: <LabLayout />,
        children: [
            {
                index: true,
                element: <StudyLabCourse />
            },
            {
                path: 'ma',
                element: <StudyLabCourse />
            },
            {
                path: 'is',
                element: <IssuePage></IssuePage>
            },
            {
                path: 'is/rm',
                element: <IssueRemark></IssueRemark>
            },
            {
                path: 'is/createissue',
                element: <CreateIssue />
            }
        ]
    },
    {
        path: '/os/:coucode',
        children: [
            {
                index: true,
                element: <CourseDetail />
            },
            {
                path: 'answerDetail',
                element: <AnswerRemark />
            },
            {
                path: 'bloganswer',
                element: <BlogAnswer />
            },

        ]

    },
    {
        element: <MainLayout />,
        children: [
            {
                path: '/product-course',
                element: <ProductCourse />
            },
            {
                path: '/product-laboratory',
                element: <CreatLaboratory />
            },
            {
                path: '/blog-creat',
                element: <BlogCreat />
            },
        ]
    },
    adminRoutes,
    courseAdminRoutes,
    ...superAdmin,
    {
        path: '/experience',
        element: <Experience />
    },
    {
        path: '/answer-exam',
        element: <AnswerExam />
    },
    {
        path: '/exam-view',
        element: <ExamView />
    },
    {
        path: '/exam-stuview',
        element: <ExamView />
    },
    {
        path: '/exam-mark',
        element: <ExamMark />
    },
    {
        path: '/exam-start',
        element: <PStartExam />
    },
    {
        path: '/exam-fail',
        element: <PFailExam />
    },
    {
        path: '/exam-success',
        element: <PSuccessExam />
    },
    {
        path: '/AcmWorkspace',
        element: <AcmWorkspace />
    },
    {
        path: '/404NotFound',
        element: <NotFound />
    },
    {
        path: '*',
        redirect: '/404NotFound'
    }
]

// 转换路由数据格式
const transformRoutes = (routeList: any[]) => {
    const list: RouteObject[] = []
    routeList.forEach(route => {
        const obj = { ...route }
        if (obj.redirect) {
            obj.element = <Navigate to={obj.redirect} replace={true} />
        } else if (obj.element) {
            obj.element = lazyLoad(obj.element)
        }
        delete obj.redirect
        if (obj.children) {
            obj.children = transformRoutes(obj.children)
        }
        list.push(obj)
    })
    return list
}

const Router = () => useRoutes(transformRoutes(routes))

export { Router }