import React from 'react';
import './index.less';
import { Outlet } from "react-router-dom";

const Content: React.FC = () => {
  return (
    <div className="content-container-old">
      {/* 显示匹配到的子组件 */}
      <div className='content-container-old-content'>
        <Outlet />
      </div>
    </div>
  );
}

export default Content;