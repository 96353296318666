import { createSlice } from "@reduxjs/toolkit";
export const loginSlice = createSlice({
  name: "login", // 命名空间，在调用action的时候会默认的设置为action的前缀,保证唯一.不重名
  initialState: {
    // 是否显示登录弹框
    isLogin: false,   
    // 登入状态   是否登录
    loginIng: false,
    //用户信息
    userInfo: {
      userphone: '',        // 用户名
      userId:'',
      userInvite: false,    // 是否有邀请权限
      isLogin: 'loading',   // 是否登录   loading  加载中    true 登录状态  false  登录失败
      avatar: ''            // 用户头像
    },
  },
  reducers: {
    // reducer函数 state当前组件的数据
    //第二个参数为{payload:{},type:"""}
    changeIsLogin(state, { payload }) {
      state.isLogin = payload.isLogin;
    },
    changeLoginIng(state, { payload }) {
      state.loginIng = payload.loginIng;
    },
    changeUserInfo(state, { payload }) {
      state.userInfo = {...state.userInfo, ...payload.userInfo};
    },
  },
});
export const { changeIsLogin ,changeLoginIng,changeUserInfo} = loginSlice.actions;

export default loginSlice.reducer;
