import { useEffect, useRef, useState } from "react"

// 验证码定时器
export const useCodeTimer = (): [number, (value: number) => void, (value: number) => number] => {

    const [time, setTime] = useState(-1)
    const timer = useRef<any>()

    const keepTime = (value: number) => {
        if (value >= 0 && value <= 60) {
            setTime(value)
            timer.current = setInterval(() => setTime(pre => pre - 1), 1000)
        }
    }

    const handleTime = (value: number) => {
        return Number((value / 1000).toFixed(0))
    }

    useEffect(() => {
        if (time < 0 || time > 60) {
            clearInterval(timer.current)
            setTime(-1)
        }
    }, [time])

    useEffect(() => {
        return () => {
            clearInterval(timer.current)
        }
    }, [])

    return [time, keepTime, handleTime]
}