import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux';
import { Tooltip, message } from 'antd';
import { syncUser, syncUserStatus } from '../../../../../../api/modules/study';

type InfoProps = {
  viewStatus: any //预览状态
  data?: any //课程信息
  ifOpenWorkSpace?: (name?: any) => void //打开工作台
}

const Info: React.FC<InfoProps> = (props) => {
  const { data, viewStatus, ifOpenWorkSpace } = props

  const { courseSpeed } = useSelector((state: any) => state.courseSpeed)

  //是否显示展开
  const [isTemp, setIsTemp] = useState(false)
  const zkref = useRef(null)
  const [isZk, setIszk] = useState(true)

  // 判断展开收起
  useEffect(() => {
    if (data?.description || data?.remark) {
      const el: any = zkref?.current
      if (el && el?.clientHeight > 44) {
        setIsTemp(true)
      } else {
        setIsTemp(false)
      }
    }
  }, [data])

  // 刷新中
  const [refresh, setRefresh] = useState(false)
  // 刷新的同步状态的句柄
  const timer = useRef<any>()

  // 清除句柄
  useEffect(() => {
    return () => {
      clearTimeout(timer?.current)
    }
  }, [])

  // 刷新状态同步
  const syncStatus = async () => {
    setRefresh(true)
    const res = await syncUserStatus({ code: data?.code }).catch(() => { })
    if (res === true) {
      timer.current = setTimeout(() => {
        syncStatus()
      }, 3000)
    } else {
      setRefresh(false)
      message.destroy()
      message.success('统计数据更新成功')
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
  }

  return (
    <div className='detailcourse-content-tro'>
      <div className='detailcourse-content-tro-left'>
        <div className='detailcourse-content-tro-left-title'>
          <span>{data?.name || ''}</span>
          <span>{viewStatus ? (data?.commitNum) ?? 0 : data?.publishNum}节</span>
        </div>
        <div className='detailcourse-content-tro-left-label'>
          {
            data?.courseLabel?.map((item: string) => {
              return <span>#{item}</span>
            })
          }
        </div>
        <div className='detailcourse-content-tro-left-con' >
          <div ref={zkref} className={isTemp && isZk ? 'nozk detailcourse-content-tro-left-con-p' : 'detailcourse-content-tro-left-con-p'} dangerouslySetInnerHTML={{ __html: data?.remark?.replace(/\n/g, '<br>') }}></div>
          {
            isTemp && isZk ? <span className='detailcourse-content-tro-left-con-span' onClick={() => { setIszk(false) }}>更多</span> : <></>
          }
        </div>
      </div>

      <div className='detailcourse-content-tro-rightcon'>
        <div className='detailcourse-content-tro-right' onClick={ifOpenWorkSpace}>
          {
            courseSpeed && Cookies.get('token') ? '继续学习' : '开始学习'
          }
        </div>
        {
          Cookies.get('token') ? <div className='detailcourse-content-tro-rightcon-footer'>
            <Tooltip title={`数据每小时自动更新，手动刷新需间隔3分钟再试`} arrow={false} placement='bottom' color='#ffffff' overlayInnerStyle={{ color: '#666666' }}>
              <div className='detailcourse-right-fresh' onClick={async () => {
                if (!refresh) {
                  const res = await syncUser({ code: data?.code }).catch(() => { })
                  if (res?.success) {
                    syncStatus()
                  } else {
                    message.destroy()
                    message.warning(res?.message)
                  }
                }
              }}>
                <svg className={refresh ? 'cus-rotate' : ''} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_4124_3281)">
                    <path d="M12.1938 10.7452C11.2922 12.1064 9.75427 13.008 8.02186 13.008C5.44092 13.008 3.33728 11.0811 3.07211 8.58855L3.72619 9.24263C3.92064 9.43708 4.23884 9.43708 4.43329 9.24263C4.62775 9.04817 4.62775 8.72997 4.43329 8.53552L3.01908 7.12131C2.91302 7.01524 2.78927 6.96221 2.66553 6.97989C2.52411 6.97989 2.40036 7.03292 2.31197 7.12131L0.897761 8.53552C0.703307 8.72997 0.703307 9.04817 0.897761 9.24263C1.09222 9.43708 1.41041 9.43708 1.60487 9.24263L2.09984 8.74765C2.41804 11.7175 4.94595 13.9979 8.00418 14.0156C10.1255 14.0156 11.964 12.9196 13.0423 11.2756C13.0777 11.2048 13.113 11.0988 13.113 11.0281C13.113 10.7452 12.9009 10.5331 12.6181 10.5331C12.4413 10.4977 12.2645 10.6038 12.1938 10.7452ZM14.4389 6.76775L13.9616 7.24505C13.5903 4.29288 11.0801 1.99478 8.00418 1.99478C6.02429 1.99478 4.29187 2.94938 3.17818 4.41662C3.08979 4.50501 3.05444 4.61108 3.05444 4.7525C3.05444 5.03534 3.26657 5.24747 3.54941 5.24747C3.72619 5.24747 3.88529 5.15908 3.97368 4.99999C4.87524 3.78023 6.34248 2.98473 7.96883 2.98473C10.5498 2.98473 12.6534 4.9116 12.9186 7.40415L12.2645 6.75008C12.07 6.55562 11.7518 6.55562 11.5574 6.75008C11.3629 6.94453 11.3629 7.26273 11.5574 7.45718L12.9716 8.8714C13.0777 8.97746 13.2014 9.03049 13.3252 9.01282C13.4666 9.01282 13.5903 8.95978 13.6787 8.8714L15.0929 7.45718C15.2874 7.26273 15.2874 6.94453 15.0929 6.75008C14.8985 6.55562 14.6333 6.5733 14.4389 6.76775Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4124_3281">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>刷新</span>
              </div>
            </Tooltip>
            <span className='detailcourse-right-line'></span>
            <Tooltip title={`当前学习进度：${data?.completeDegree}`} arrow={false} placement='bottom' color='#ffffff' overlayInnerStyle={{ color: '#666666' }}>
              <span className='detailcourse-right-procress'>{data?.completeDegree}</span>
            </Tooltip>
          </div> : <></>
        }
      </div>
    </div>
  );
}

export default Info;
