import React, { useState, useEffect } from 'react'
import "./index.less"
import ListItem from './listItem';
import { Modal, Pagination, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getIssueList, getLabToken } from '../../../../api/modules/issue';
import * as url from '../../../../utils/url'
import { useNavigate } from 'react-router-dom';
import { ifJoin, jionLabs, labsList } from '../../../../api/modules/product';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsLogin } from '../../../../store/modules/loginSlice';
import JoinModal from '../../../StudyLabCourse/components/ImportModal';
import { getUserInfo } from '../../../../api/modules/person';
import TableLoading from '../../../../components/TableLoading';

const { warning, confirm } = Modal;


const IssueList: React.FC = () => {

  const labcode = url.getLabCodeFromHost()

  const [currentPage, setCurrentPage] = useState(1)                  // 分页器当前页数
  const [pageSize, setPageSize] = useState(10)                       // 分页器每一页的条数
  const [pageTotal, setPageTotal] = useState(0)

  //issue列表
  const [issueList, setIssueList] = useState([])

  //实验室是否有配置github
  const [ifGitHub, setIfGitHub] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // 用户登录信息
  const { userInfo } = useSelector((state: any) => state.loginUser)

  //数据是否加载完成
  const [valueLoding, setValueLoding] = useState(false)

  //是否有表单需要填写
  const [isOpenJoin, setIsOpenJoin] = useState({
    isJoin: false,
    isOpenFrom: false,
  })

  //表单需要填写的内容
  const [openFromValue, setOpenFromValue] = useState([])

  // 是否点击加入实验室
  const [clickJion, setclickJion] = useState(false)

  //退出弹窗
  const exitConfirm = () => {

    Modal.confirm({
      title: '暂无权限',
      icon: <ExclamationCircleFilled />,
      content: '请确认账号并联系该GitHub项目Owner解决此问题。',
      okText: '我再想想',
      centered: true,
      width: 400,
      cancelText: '确认退出',
      onOk() { },
      onCancel() {

      },
    })
  };

  //未绑定git
  const gitNo = () => {

    Modal.confirm({
      title: '暂无权限',
      icon: <ExclamationCircleFilled />,
      content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
      okText: '去设置  ',
      centered: true,
      width: 400,
      cancelText: '我再想想',
      onOk() {
        navigate(`/user/github`)
      },
      onCancel() {

      },
    })
  };

  //未加入实验室弹窗
  const jionLab = () => {
    Modal.confirm({
      title: '加入实验室',
      icon: <ExclamationCircleFilled />,
      content: '您还未加入实验室，无法新建issue，请先加入实验室~',
      okText: '加入实验室',
      centered: true,
      width: 400,
      cancelText: '我再看看',
      onOk() {
        joinLab()
      },
      onCancel() {

      },
    })
  };

  //点击新建Issue
  const creatIssue = () => {
    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    if (!ifGitHub) {
      warning({
        title: '温馨提示',
        icon: <ExclamationCircleFilled />,
        content: <p >该实验室还未完成GitHub项目和令牌配置！</p>,
        okText: '我知道了',
        centered: true,
        onOk() {
        },
      });
      return
    }
    if (isOpenJoin.isJoin) {
      getUserInfo().then(res => {
        res?.thirdUsers?.forEach((item: any) => {
          if (item.success) {
            //进入新建
            navigate(`/os/is/createissue`)
          } else {
            gitNo()
          }
        })
      })
    } else {
      jionLab()
    }
  }


  const pageChage = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage)
    setPageSize(pageSize)
  }


  useEffect(() => {
    getIssue()
  }, [pageSize, currentPage])

  //获取issue列表
  const getIssue = () => {
    getIssueList({
      code: labcode,
      current: currentPage,
      size: pageSize
    }).then(res => {
      setValueLoding(true)
      setPageTotal(res?.total)
      setIssueList(res?.rows)
    })
  }

  //加入实验室
  const joinLab = () => {
    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    if (!isOpenJoin.isJoin) {
      if (isOpenJoin.isOpenFrom) {
        labsList({ labCode: labcode }).then(res => {
          setOpenFromValue(res)
          setclickJion(true)
        })
      } else {
        jionLabs({ labCode: labcode, recordList: [] }).then(() => {
          message.success('加入成功！')
          getJion()
        })
      }
    }
  }

  //用户是否加入实验室
  const getJion = () => {
    ifJoin({ labCode: labcode }).then(res => {
      setIsOpenJoin({
        isJoin: res.isJoin,
        isOpenFrom: res.isOpenFrom,
      })
    })
  }


  useEffect(() => {
    if (labcode) {
      console.log(labcode);
      getJion()
    }
  }, [labcode,])


  useEffect(() => {
    getLabToken({ labCode: labcode }).then(res => {
      res?.forEach((item: any) => {
        if (item.success) {
          setIfGitHub(true)
        }
      })
    })
  }, [])



  return (
    <div className='issueList'>
      <JoinModal labCode={labcode ?? ''} show={clickJion && isOpenJoin.isOpenFrom} value={openFromValue} changeClick={(e: any) => {
        if (e) {
          setclickJion(false)
          getJion()
        } else {
          setclickJion(false)
        }
      }}></JoinModal>
      <div className='issueList-Heard' style={{
        marginTop: issueList.length > 0 ? '0' : "5px"
      }}>
        <div className='issueList-Heard-left'>
          <div className='line'></div>
          <div className='text'>issue列表</div>
        </div>
        {issueList.length > 0 && <div className='issueList-Heard-rigth' onClick={() => {
          creatIssue()
        }}>
          新建issue
        </div>}
      </div>
      <div className='issueList-content' style={{
        marginTop: issueList.length > 0 ? '13px' : "20px"
      }}>
        {valueLoding && issueList.length > 0 && <div style={{ overflowY: "auto", height: '100%' }}>
          {
            issueList.map((item: any) => {
              return <ListItem value={item} key={item.id}></ListItem>
            })
          }

        </div>}

        {valueLoding && issueList.length === 0 && <div className='issueList-my'>
          <img src={require("../../../../assets/images/creatNovalue.png")} alt="" className='labs-my-img' />
          <div className='labs-my-text'>这里还没有issue噢，快去新建issue试试吧~</div>
          <div className='creatBtn' onClick={creatIssue}>
            新建issue
          </div>
        </div>}
        {!valueLoding && <div style={{ marginTop: '15vh' }}>
          <TableLoading title='加载中...' />
        </div>}
      </div>
      {issueList.length > 0 &&
        <div className='issueList-Pagination'>
          <Pagination
            current={currentPage}
            total={pageTotal}
            showSizeChanger
            showQuickJumper={true}
            onChange={pageChage}
            style={{ textAlign: 'right' }}
            pageSize={pageSize}
          />
        </div>
      }
    </div>
  )
}

export default IssueList;
