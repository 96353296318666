import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { updateRoutes } from '../../store/modules/breadcrumbSlice'
import './index.less'

const Breadcrumb: React.FC = () => {
  const { routes } = useSelector((state: any) => state.breadcrumb);


  const dispatch = useDispatch();

  const navigate = useNavigate()

  const location = useLocation()

  // 当前路径
  const path = location.pathname + location.search

  // 新面包屑
  const [newRoutes, setNewRoutes] = useState<any>([])

  useEffect(() => {
    let arr = []
    for (let index = 0; index < routes?.length; index++) {
      const element = routes[index];
      arr.push(element)
      if (element.title === path) break
    }
    setNewRoutes(arr)
    dispatch(updateRoutes({ routes: arr }))
  }, [JSON.stringify(routes)])

  return (
    <div className='breadcrumb'>
      {newRoutes?.map((v: any, i: number) => {
        return (
          <div className="breadcrumb-item" key={i} onClick={() => {
            if (i !== newRoutes.length - 1 && v?.path) {
              let n = [...newRoutes]
              n = n.splice(0, n.indexOf(v) + 1)
              dispatch(updateRoutes({ routes: n }))
              navigate(v.path, { replace: true })
            }
          }}>
            {v.title}
            {i !== newRoutes.length - 1 ? <img src={require('../../assets/images/arrow-right.png')} alt="" /> : <></>}
          </div>

        )
      })}
    </div>
  )
}


export default Breadcrumb 