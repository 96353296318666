import Cookies from "js-cookie";
import * as url from "./url";
import { getTopHost } from "./url";

export type loginCookieType = {
    expire?: string
    token?: string
    sign?: string
    partner?: string
    userId?: string
    [key: string]: any
}

// 需要存储的值
export const cookiesKey = ['token', 'sign', 'partner', 'userId']

// 登录需要保存的cookie
export const loginSetCookie = (data: loginCookieType) => {
    // 默认7天后过期
    if (!data?.expire) {
        const time = new Date()
        time.setDate(time.getDate() + 7 )
        data.expire = time.toString()
    }
    const expires = new Date(data?.expire)
    cookiesKey?.forEach((item) => {
        Cookies.set(item, data[item] ?? '', { expires: expires, domain: url.getTopHost() || '' })
    })
}

// 退出登录需要清除的cookie
export const logoutRemoveCookie = () => {
    cookiesKey?.forEach((item) => {
        Cookies.remove(item)
        document.cookie = `${item}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.${getTopHost()}; path=/;"`
    })
}

// 判断这些cookie值是否存在，即是否登录
export const CookieExistsLogin = (): boolean => {
    return cookiesKey?.every((item: string) => Cookies.get(item)) 
}

// 获取cookies
export const getCookies = () => {
    const data: any = {}
    cookiesKey?.forEach((item) => {
        data[item] = Cookies.get(item)
    })
    return data
}