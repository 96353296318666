import { useDispatch } from "react-redux"
import { changeLoginIng, changeUserInfo } from "../store/modules/loginSlice"
import { loginSetCookie, logoutRemoveCookie } from "../utils/login"

// 登录
export const useLogin = () => {
    const dispatch = useDispatch()
    
    // 登录
    function login(cookieData: any, userDetail: any = {}) {
        // 设置登录需要的cookie, 因为有些已经登录了，不需要设置cookie
        if (cookieData) {
            loginSetCookie(cookieData)
        }
        // 设置store里面的数据
        dispatch(changeLoginIng({ loginIng: true }))
        dispatch(changeUserInfo({
            userInfo: {
                userphone: userDetail.nickname,
                userId: userDetail.userId,
                userInvite: userDetail.isInvite,
                isLogin: userDetail.isLogin,
                avatar: userDetail.avatar
            }
        }))
    }

    // 退出登录
    function logout() {
        // 清除登录的cookie
        logoutRemoveCookie()
        // 清除store里面的登录信息
        dispatch(changeLoginIng({ loginIng: false }))
        dispatch(changeUserInfo({ 
            userInfo: { 
                userphone: '', 
                userId: '',
                userInvite: false,
                isLogin: false,
                avatar: ''
            } 
        }))
    }
    
    return {
        login,
        logout
    }
}




