import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './utils/style.less'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from './store'
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react"
import * as buffer from "buffer";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';

if (typeof (window as any).Buffer === "undefined") {
  (window as any).Buffer = buffer.Buffer;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

    <App />
      </PersistGate>
    </Provider>

  </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
