import React from 'react';
import { Layout } from 'antd';
import './index.less'
import Header from '../../components/Header';
import { Outlet } from 'react-router-dom';

const NormalLayout: React.FC = () => (
  <Layout>
    <Header />
    <div className='normal-layout-outlet'>
      <div className='normal-layout-outlet-main'>
        <Outlet />
      </div>
    </div>
  </Layout>
);

export default NormalLayout;