import React from 'react';
import './index.less';
import { Modal } from 'antd';

type EnlargeProps = {
  message?: string
  url?: string
  ifTrue: boolean,
  changeIfTrue?: (v: any) => void;
}

const EnlargeImg: React.FC<EnlargeProps> = (props) => {
  const { message, ifTrue, url, changeIfTrue } = props
  return (
    <Modal wrapClassName={'semptyGlobal'} open={ifTrue} footer={''} width={'500px'} style={{ top: "30%", }}>
      <div style={{ position: 'absolute', top: '3px', right: '3px', cursor: 'pointer' }} onClick={changeIfTrue}>
        <img src={require('../../assets/images/login-icon.png')} alt="" />
      </div>
      <div style={{ width: '100%', height: '100%' }}>
        <img src={url} alt="" style={{ width: '100%', height: '100%', borderRadius: '2px' }} />
      </div>
    </Modal>
  );
}

export default EnlargeImg;