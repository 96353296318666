import React, { useState, useEffect, Fragment } from 'react'
import "./index.less"
import Line from './components/Line';
import ContainerLeft from './components/ContainerLeft';
import ContainerRight from './components/ContainerRight';
import ContainerCoderPlayer from './components/ContainerCodePlayer';

interface WorkContainersProps {
  value: any
  disableCopyPaste?: boolean
}

const WorkContainers: React.FC<WorkContainersProps> = (props) => {

  const { value, disableCopyPaste } = props

  //md内容
  const [mdValue, setMdValue] = useState('')

  // 容器宽度
  const [containerW, setContainerW] = useState(0)

  useEffect(() => {
    setMdValue(value?.content)

    const el = document.getElementsByClassName('container-work')[0]
    setContainerW(el?.clientWidth)
  }, [value])

  return (
    <div className='workContainers'>
      <Fragment>
        <div className='container-work' style={{width: value?.optionJson?.defaultLanguage === 'HTML+CSS+Javascript' ? '34%' : ''}}>
          {<ContainerLeft value={mdValue}></ContainerLeft>}

        </div>
        <Line state="col" move={(x: number) => {
          const el0: any = document.getElementsByClassName('container-work')[0]
          const el1: any = document.getElementsByClassName('container-work')[1]

          const element = document.getElementsByClassName("workContainers")[0] as HTMLElement

          const screenWidth = element?.offsetWidth ?? 0
          el0.style.width = containerW + x + 'px'
          el1.style.width = screenWidth - containerW - x + 'px'

        }} moveEnd={() => {
          const el: any = document.getElementsByClassName('container-work')[0]
          setContainerW(el.offsetWidth)
        }} />
      </Fragment>
      <Fragment>
        <div className='container-work' style={{width: value?.optionJson?.defaultLanguage === 'HTML+CSS+Javascript' ? '66%' : ''}}>
          {
            value?.optionJson?.defaultLanguage !== 'HTML+CSS+Javascript' ? <ContainerRight value={value} disableCopyPaste={disableCopyPaste}></ContainerRight> :
            <ContainerCoderPlayer value={value} disableCopyPaste={disableCopyPaste} />
          }
        </div>
      </Fragment>
    </div>
  )
}

export default WorkContainers;
