import React from 'react'
import './index.less'

type LoginBgProps = {
    
}

const LoginBg: React.FC<LoginBgProps> = (props) => {

    const { } = props

    return (
        <div className='loginbg'>
            <div className='loginbg-top'>
                <img style={{maxWidth: '296px'}} src={require('../../assets/images/login/left-header.png')} alt="" />
                <img style={{maxWidth: '666px'}} src={require('../../assets/images/login/left-tip.png')} alt="" />
            </div>

            <img style={{maxWidth: '360px'}} src={require('../../assets/images/login/left-footer.png')} alt="" />
        </div>
    )
}

export default LoginBg