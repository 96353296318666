import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './index.less'
import config from '../../config'
import postRobot from 'post-robot'

type CodePlayerProps = {
    style?: React.CSSProperties
    className?: string
    oid?: string
    lang?: 'html'  // 默认html
    hasOid?: boolean // 是否确认有oid，有oid不设置语言  弃用
    [key: string]: any
}

let CodePlayer: React.FC<CodePlayerProps> = (props, ref) => {

    const { style, className, oid, lang = 'html', hasOid = false } = props

    const iframeDom = useRef<HTMLIFrameElement>(null)

    const [loading, setLoading] = useState(false)
   
    useImperativeHandle(ref, () => ({
        run,
        save
    }))

    // 运行代码
    const run = async () => {
        if (iframeDom?.current?.contentWindow) {
            const res = await postRobot.send(iframeDom?.current?.contentWindow, 'run').catch(() => {})
        }
    }

    // 设置语言
    const setLang = async (lang: string) => {
        if (iframeDom?.current?.contentWindow) {
            const res = await postRobot.send(iframeDom?.current?.contentWindow, 'send', { lang, code: [''], run: false }).catch(() => {})
        }
    }

    // 保存
    const save = async () => {
        if (iframeDom?.current?.contentWindow) {
            try {
                const res = await postRobot.send(iframeDom?.current?.contentWindow, 'save')
                return res?.data
            } catch (error) {
                return error
            }
        }
    }

    // 设置初始语言
    useEffect(() => {
        // if (!loading) return
        if (!oid && lang) {
            setLang(lang)
        }
    }, [lang, oid])

    useEffect(() => {
        const runHandle = postRobot.on('run', { window }, async (event: any) => {
            const { data } = event
            if (data.save) {
                await save()
            }
            return await run()
        })
        const saveHandle = postRobot.on('save', { window }, async (event: any) => {
            return await save()
        })
        return () => {
            runHandle.cancel()
            saveHandle.cancel()
        }
    }, [])



    return (
        <div className={className ? className + ' codeplayer' : 'codeplayer'} style={style}>
            <iframe
                ref={iframeDom}
                allowFullScreen={true}
                allow="clipboard-write;clipboard-read"
                // loading="lazy"
                src={`${config.codeplayerURL}/embedded${oid ? '/' + oid : ''}`}
                frameBorder="0"
                title='codeplayer'
                className='codeplayer-iframe'
                onLoad={() => {
                    setLoading(true)
                }}
            ></iframe>
        </div>
    )
}

CodePlayer = forwardRef(CodePlayer as any)

export default CodePlayer