import React from 'react';
import { Layout, } from 'antd';
import { useSelector } from "react-redux";
import Header from '../../components/Header';
import Breadcrumb from '../../components/Breadcrumb';
import Content from '../../components/Contentzzzz';

const MainLayout: React.FC = () => {
  const { routes } = useSelector((state: any) => state.breadcrumb);
  return (
    <Layout style={{ minHeight: '100%', paddingTop: '48px' }}>
      <Header />
      {
        routes?.length ? <Breadcrumb /> : <></>
      }
      <Content />
    </Layout>
  )
};

export default MainLayout;