import { Tooltip } from 'antd';
import React, { useState } from 'react';
import './index.less'

type EnvProps = {
  template?: any
}

const Env: React.FC<EnvProps> = (props) => {

  const { template } = props

  // 展开，默认折叠
  const [open, setOpen] = useState(false)

  return (
    <div className="blog-env">
      <div className='blog-env-header' onClick={() => setOpen(!open)}>
        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" style={!open ? { transform: 'rotate(-90deg)' } : {}}>
          <path d="M11.7178 3.86562L6.60489 9.45664C6.45255 9.63008 6.23223 9.72969 6.0002 9.72969C5.76934 9.72969 5.54903 9.63008 5.39551 9.45664L0.282623 3.86562C0.0330134 3.60195 -0.0443303 3.21758 0.0857478 2.87773C0.215826 2.53789 0.526373 2.30117 0.888482 2.26953H11.1096C11.4729 2.3 11.7857 2.53555 11.9147 2.87656C12.0447 3.21758 11.9674 3.59961 11.7178 3.86562Z" />
        </svg>
        <span>环境信息</span>
      </div>

      {
        open && (
          <div className='blog-env-info'>
            <div>
              <div className='blog-env-info-left'>环境：</div>
              <div className='blog-env-info-right'>
                <Tooltip placement="bottomRight" title={template?.name} arrow={false} color='white' overlayClassName='platfrom-tooltip-1'>
                  <span>{template?.name}</span>
                </Tooltip>
              </div>
            </div>

            <div>
              <div className='blog-env-info-left'>镜像工具：</div>
              <div className='blog-env-info-right'>
                {
                  template?.containers?.map((v: any) => (
                    <Tooltip key={v?.image} placement="bottomRight" title={v?.name} arrow={false} color='white' overlayClassName='platfrom-tooltip-1'>
                      <span>{v?.name}</span>
                    </Tooltip>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Env;
