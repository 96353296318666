
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  labAuth: 'ROOT', // 当前实验室的默认权限 ROOT：创建者：ROOT , OTHER：协作者
  isAdmin: true,   // 是不是管理员，根据labAuth进行判断，没有暴露修改方法，当修改labAuth时，自动修改isAdmin
  task: {        // 任务数据
    taskQuantity: 0,
    examQuantity: 0,
    totalQuantity: 0
  },
  labType: undefined,  // 实验室类型
};

export const adminSlice = createSlice({
  name: "adminSpace", // 命名空间，
  initialState,
  reducers: {
    // reducer函数 state当前组件的数据 
    //第二个参数为{payload:{},type:"""} 
    setLabAuth(state, { payload }) {
        if (state.labAuth === payload.labAuth) return
        state.labAuth = payload.labAuth || ''
        if (state.labAuth === 'ROOT') {
          state.isAdmin = true
        } else {
          state.isAdmin = false
        }
    },
    setTask(state, { payload }) {
      state.task = {...state.task, ...payload}
    },
    setLabType(state, { payload }) {
      state.labType = payload.type
    }
  },
});
export const { setLabAuth, setTask, setLabType } = adminSlice.actions;

export default adminSlice.reducer;
