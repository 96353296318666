import React, { useEffect, useState } from 'react'
import './index.less'
import { Modal } from 'antd'
import { getInvitationCode } from '../../api/modules/common'

type HowGetCodeProps = {
    style?: React.CSSProperties
    className?: string
}

const HowGetCode: React.FC<HowGetCodeProps> = (props) => {

    const { style, className } = props

    // 如何获取分享码弹框控制
    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')

    // 获取邀请码的提示
    useEffect(() => {
        getInvitationCode({ key: 'contactUs' }).then(res => {
            setImg(res.value)
        }).catch(() => { })
    }, [])

    return (
        <>
            <div className={className ? className + ' howgetcode-title' : 'howgetcode-title'} style={style} onClick={() => { img && setShow(true) }}>如何获取邀请码?</div>
            <Modal
                open={show}
                wrapClassName={'howgetcode-modal'}
                onCancel={() => { setShow(false) }}
                footer={false}
                closable={true}
                centered={true}
                width={'380px'}
                maskClosable={false}
                destroyOnClose={true}
                zIndex={9999}
            >
                <div className='title'>联系我们</div>
                <img src={img} alt="" />
            </Modal>
        </>
    )
}

export default HowGetCode