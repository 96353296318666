import React, { useEffect, useRef, useState } from 'react';
import './index.less'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateRoutes } from '../../../../store/modules/breadcrumbSlice';
import { navigateRoutes } from '../../../../router/admin';
import { generateHomeUrl } from '../../../../utils/url';
import EnterWorkspaceLoading from '../../../../components/EnterWorkSpaceLoading';

const ProductStage: React.FC<any> = (props) => {

  const [loadIng, setLoadIng] = useState(true)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const iframeRef = useRef<any>(null)

  useEffect(() => {
    const handle = (event: any) => {
      console.log(event);

      const { data } = event
      switch (data.type) {
        case 'closeLoading':
          if (loadIng) {
            setLoadIng(false)
          }
          break
        case 'shutopen':
          dispatch(updateRoutes({
            routes: [
              {
                path: '/',
                title: '首页'
              },
            ]
          }))
          // navigate(`${navigateRoutes.blog}?labCode=${props.labCode}`)
          window.open(generateHomeUrl(`${navigateRoutes.blog.slice(1)}?labCode=${props.labCode}`, props?.labType), '_self')
          break
        case 'log':
          // 打印消息
          alert(data.data)
          break
        case 'publish':
          props.nextStep()
          break
        case 'examExit':
          props.exitExam()
          break
        case 'examUpdate':
          props.updateValue(data?.value)
          break
        case 'readClipboard':
          {
            // iframe中读取剪贴板的内容
            navigator?.clipboard?.read()?.then(async (data: any) => {
              for (const item of data) {
                for (const type of item?.types) {
                  if (type?.includes('image')) {
                    const blob = await item.getType(type)
                    iframeRef?.current?.contentWindow?.postMessage({ type: 'clipboardImage', text: blob }, '*');
                    return
                  }
                }
              }
            })
          }
          break
        default:
          break
      }
    }
    window.addEventListener('message', handle)
    return () => {
      window.removeEventListener('message', handle)
    }
  }, [])

  useEffect(() => {
    const handIframe = () => {
      // setLoadIng(false)
    }
    const iframe = document.querySelector('#my-iframe')
    iframe?.addEventListener('load', handIframe);
    return () => {
      iframe?.removeEventListener('load', handIframe)
    }
  })


  return (
    <div className='study-document-start'>
      {
        loadIng && <EnterWorkspaceLoading />
      }
      {props.data && <iframe ref={iframeRef} allowFullScreen={true} allow="clipboard-write;clipboard-read" loading="lazy" id='my-iframe' src={props.data} frameBorder="0" title='files' className='iframeItem'></iframe>}
    </div>
  )
};

export default ProductStage;