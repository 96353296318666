import React, { useState, useEffect } from 'react'
import "./index.less"
import { useSearchParams } from 'react-router-dom'
import { Layout } from 'antd';
import WorkHeard from './components/WorkHeard'
import WorkContainers from './components/WorkContainers'
import { getExamInfo, getExamOne, questionBankInfo } from '../../api/modules/exam';
import { useDisableCopyPaste } from '../../hooks/disableCopyPaste';


const AcmWorkspace: React.FC = () => {

  const [params] = useSearchParams();

  // 禁用复制粘贴剪切
  const { disable, cancel } = useDisableCopyPaste()

  //问题code
  let questionCode = params.getAll("questionCode")[0]

  //考试记录code
  let examRecordCode = params.getAll("examRecordCode")[0]
  let bank = params.getAll("bank")[0]

  //考试内容
  const [examDetail, setExamDetail] = useState<any>()

  // 禁用复制粘贴剪切
  useEffect(() => {
    if (examDetail?.examType === "POINT") {
      disable()
    }
  }, [examDetail])

  useEffect(() => {
    if (examRecordCode) {
      getExamOne({
        examRecordCode: examRecordCode,
        questionCode: questionCode
      }).then(res => {
        setExamDetail(res)
      })
    } else {
      if (bank === '1') {
        questionBankInfo({
          questionCode: questionCode
        }).then(res => {
          setExamDetail(res)
        })
      } else {
        getExamInfo({
          questionCode: questionCode
        }).then(res => {
          setExamDetail(res)
        })
      }
    }
  }, [examRecordCode, questionCode])

  return (
    <Layout style={{ background: '#181818', position: 'relative' }}>
      <div className='Layout'>
        <WorkHeard value={examDetail} examRecordCode={examRecordCode} examDetail={examDetail}></WorkHeard>
        <WorkContainers value={examDetail} disableCopyPaste={examDetail?.examType === "POINT"}></WorkContainers>
      </div>
    </Layout>
  )
}

export default AcmWorkspace;
