import React, { useEffect, useState } from "react";
import { ConfigProvider, message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import "./index.less";
import zhCN from 'antd/locale/zh_CN';

import { UploadOutlined } from "@ant-design/icons";

type imgType = {
  getImage: (v: any) => void;
  imageChange?: any;
  proportion?: number;
  shape?: boolean
};

const ImgUpload: React.FC<imgType> = (props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [iconChange, setIconChange] = useState<boolean>(false);
  const imgTypeLimit = ["image/png", "image/jpg", "image/jpeg"];
  // const imgLimitSize = 512 * 1024;

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // 取消也会运行这个函数
    // setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const imgCropProps: any = {
    // 裁切区域宽高比，width / height
    aspect: 16 / (props.proportion ? props.proportion : 9),
    minZoom: 0.3,
    cropperProps: { restrictPosition: false },
  };


  const beforeUpload = (file: any, fileList: any) => {
    // 判断文件格式
    if (imgTypeLimit.includes(file.type)) {
      setFileList(fileList);
      props.getImage(file)
    } else {
      message.error("上传的图片格式或尺寸不符合要求!");
      return Upload.LIST_IGNORE; // 不加入fileList
    }
    // 返回false表示不上传
    return false;
  };

  const deleteImg = () => {
    setFileList([]);
    setIconChange(false);
  };

  useEffect(() => {
    if (fileList.length > 0) {
      setTimeout(() => {
        setIconChange(true);
      }, 2000);
    } else {
      setIconChange(false);
    }
  }, [fileList]);

  useEffect(() => {

    if (props.imageChange) {
      deleteImg()
    }
  }, [props.imageChange])

  return (
    <div className={props.proportion ? 'imageItem imageItem-focus' : "imageItem"} style={{ width: props.proportion ? '66px' : '118px' }}>
      {iconChange ? (
        <ConfigProvider locale={zhCN}>
          <ImgCrop modalOk='确定' modalCancel="取消" {...imgCropProps} cropShape={props.shape ? 'round' : 'rect'}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              onRemove={deleteImg}
              beforeUpload={beforeUpload}

            >
              {fileList && fileList.length >= 1 ? null : (
                <div>
                  <div className="upLoadImg">
                    <UploadOutlined />
                  </div>
                  {fileList.length < 1 && "上传图片"}
                </div>
              )}
            </Upload>
          </ImgCrop>
        </ConfigProvider>
      ) : (
        <ConfigProvider locale={zhCN}>
          <ImgCrop modalOk='确定' modalCancel="取消" {...imgCropProps} cropShape={props.shape ? 'round' : 'rect'}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={beforeUpload}
            >
              {fileList && fileList.length >= 1 ? null : (
                <div>
                  <div className="upLoadImg">
                    <UploadOutlined />
                  </div>
                  {fileList.length < 1 && "上传图片"}
                </div>
              )}
            </Upload>
          </ImgCrop>
        </ConfigProvider>
      )}
    </div>
  );
};

export default ImgUpload;
