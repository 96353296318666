import { lazy } from 'react'
import { Outlet } from 'react-router-dom'
import {
    BranchesOutlined,
    CommentOutlined,
    LineChartOutlined
} from '@ant-design/icons'


const CourseAdmin = lazy(() => import('../pages/CourseAdmin'))                                // 课程工作台
const AnswerCouserList = lazy(() => import('../pages/CourseAdmin/AnswerCouserList'))          // 问答讨论
const AnswerDetail = lazy(() => import('../pages/CourseAdmin/AnswerCouserList/AnswerDetail')) // 问答讨论/问答详情
const KnowledgeGraph = lazy(() => import('../pages/CourseAdmin/KnowledgeGraph'))              // 知识图谱
const Analysis = lazy(() => import('../pages/CourseAdmin/Analysis'))                          // 学情分析
const AnalysisDetail = lazy(() => import('../pages/CourseAdmin/Analysis/AnalysisDetail'))     // 学情分析/学习详情


// 路由元信息
interface MetaType {
    title: string                // title
    icon?: React.ReactNode       // icon
    disabled?: boolean           // 路由禁用
    fold?: boolean               // 是否展开
}

const routes = {
    path: '/courseadmin',
    element: <CourseAdmin />,
    children: [
        // 知识图谱
        {
            path: 'knowledgegraph',
            meta: {
                title: '知识图谱',
                icon: <BranchesOutlined />,
                disabled: false,
                fold: false,
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: '知识图谱',
                        disabled: false,
                    } as MetaType,
                    element: <KnowledgeGraph />
                }
            ]
        },

        // 学情分析
        {
            path: 'analysis',
            meta: {
                title: '学情分析',
                icon: <LineChartOutlined />,
                disabled: false,
                fold: false,
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: '学情分析',
                        disabled: false,
                    } as MetaType,
                    element: <Analysis />
                },
                {
                    path: 'analysisdetail',
                    meta: {
                        title: '学习详情',
                        disabled: false,
                    } as MetaType,
                    element: <AnalysisDetail />
                }
            ]
        },

        // 问答讨论
        {
            path: 'discussion',
            meta: {
                title: '问答讨论',
                icon: <CommentOutlined />,
                disabled: false,
                fold: false,
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: '问答讨论',
                        disabled: false,
                    } as MetaType,
                    element: <AnswerCouserList />
                },
                {
                    path: 'answerdetail',
                    element: <AnswerDetail />,
                    meta: {
                        title: '问答详情',
                        disabled: false
                    } as MetaType,
                },
            ]
        },

    ]
}



export const courseAdminRoutes = routes.children  // 导出的路由

// 设置到后台跳转地址的统一由这里暴露
export const courseNavigateRoutes = {

    knowledgeGraph: '/courseadmin/knowledgegraph',                                       // 知识图谱

    analysis: '/courseadmin/analysis',                                                   // 学情分析
    AnalysisDetail: '/courseadmin/analysis/analysisdetail',                              // 学情分析/学习详情

    discussion: '/courseadmin/discussion',                                               // 问答讨论
    answerdetail: '/courseadmin/discussion/answerdetail',                                // 问答讨论/问答详情

}
export default routes