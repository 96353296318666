import { lazy } from 'react'

const SuperAdmin = lazy(() => import('../pages/SuperAdmin')) 
const SuperLogin = lazy(() => import('../pages/SuperLogin')) 

const routes = [
    {
        path: '/superlogin',
        element: <SuperLogin />
    },
    {
        path: '/superadmin',
        element: <SuperAdmin />
    }
]

export default routes