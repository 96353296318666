import http from "../index";

// banner
export async function getBannerList(params: any) {
  return http.get(`/config/picture/${params.path}`);
}


// 获取实验室
export async function getLabList(params: any) {
 return http.post('/lab/query', params);
}

// 获取实验技能树
export async function getTree(params?: any) {
  return http.get('/config/study/tree', {params})
}

// 登录注册
export async function getLogin(params: any) {
  return http.post('/user/login', {
    type: 0,
    phone: params.phone,
    smsCode:params.smsCode,
    source: params?.source
  });
}

// 获取验证码  类型，0登录，1绑定手机号 2 注册用户 3证书领取
export async function getSmsCode(params: any) {
  return http.post('/config/sms', {
    phone: params.phone,
    type: 0,
  });
}

// 退出登入
export async function getLogout() {
  return http.post('/user/logout');
}

// 获取用户详情
export async function getDetail() {
  return http.get('/user/detail');
}

//下发OSS密钥
export async function secret() {
  return http.get('/config/oss/secret');
}

//获取地址
export async function getBaseUrl() {
  return http.get('/config/tips?key=baseUrl');
}


// 第三方登录
// 第三方平台授权
export async function otherLogin(params: any) {
  return http.get(`/oauth/authorize/${params.type}`)
}

// 绑定手机
export async function otherBindPhone(data: any) {
  return http.post('/oauth/bind/phone', data)
}

// 第三方登录发送验证码
export async function bindSms(phone: string) {
  return http.post('/config/sms', { phone, type: 1 })
}

// 第三方平台获取access_token
export async function getAccessToken(params: any) {
  return http.post(`/oauth/callback/${params.type}`, {code: params.code, state: params.state})
}

export async function getInvitationCode(params: any) {
  return http.get(`/config/tips?key=${params.key}`)
}

// 获取验证码  0  登录   1 第三方登录    2 注册
export async function getSms(params: any) {
  return http.post('/config/sms', {
    phone: params.phone,
    type: params.type,
  });
}

// 注册
export async function register(params: any) {
  return http.post('/user/register', {
    phone: params.phone,
    smsCode: params.code,
    inviteCode: params.inviteCode,
    nickname: params.nickname
  });
}

// 获取邀请码
export async function getShareInvitationCode(isRefresh: boolean = false) {
  return http.post(`/user/invite/share`, {isRefresh})
}

// 获取邀请注册的地址
export async function getInviteRegister(data: any) {
  return http.post(`/user/invite/register`, data)
}

// 根据要邀请注册地址获取分享码
export async function getInviteCode(data: any) {
  return http.post(`/user/invite/register/code`, data)
}

//上传excel文件
export async function updateExamFile(params: any) {

  return http.post(`/question/bank/importExcel?labCode=${params.labCode}`, params.file,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal:params.signal
  })
}




// 获取课程分页
export async function getCourse(data: any) {
  return http.post(`/course/explore`, data)
}

type ShortChain = {
  callback: string,    // 回调地址
  // 实验室首页_邀请    实验室后台_邀请管理员     实验室后台_邀请注册     工作台_学习侧_邀请协同      点对点考试链接
  inviteContentType: "labIndexInvite" | "labAdminManager" | "labAdminRegister" | "wbStudyInvite" | "examPointStart"
}
// 获取短链
export async function applyShortChain<T extends ShortChain>(data: T) {
  return http.post(`/invite/content`, data)
}


export async function getConfigOne(data: any) {
  return http.get(`/sys/config/one`, data)
}


export async function joinLab(data: any) {
  return http.post(`/lab/share/join`, data)
}