import { nanoid } from "nanoid"
import { reportData } from "../api/modules/report"


// 用户行为数据上报


// profill 实现回退 50毫秒的执行时间
window.requestIdleCallback = window.requestIdleCallback || function (handler) {
    let startTime = Date.now()
    return setTimeout(function () {
        handler({
            didTimeout: false,
            timeRemaining: function () {
                return Math.max(0, 50 - (Date.now() - startTime))
            }
        })
    }, 1)
}

// 任务事件id   实验室访问次数
export type TaskEventId = 'labVisitCount' | string

//#region 
// {
//     "eventId": "labVisitCount",
//         "data" : {
//         "labCode": "实验室编码",
//             "userId": "用户ID"
//     }
// }
//#endregion

// 任务类型
export type TaskType = {
    id: string           // 唯一标识
    event: TaskEventId   // 事件名称
    data: object         // 上报的具体数据
    time: number         // 前端触发的时间戳
}

// 任务中心
class Center {
    // 任务列表   先进先出
    private taskList: TaskType[]

    constructor() {
        this.taskList = []
    }

    // 添加一个上报任务
    public addTask(event: TaskType['event'], data: TaskType['data']) {
        const task = {
            id: nanoid(),
            event,
            data,
            time: Date.now()
        }
        this.taskList.push(task)
        this.execTask()
    }

    // 执行上报任务
    private execTask() {
        const handle = (deadline: IdleDeadline) => {
            while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && this.taskList.length) {
                const task = this.taskList.shift()
                // 上报数据
                this.reportedData(task!)
            }
            if (this.taskList?.length) {
                this.execTask()
            }
        }
        // 3秒后强制执行
        requestIdleCallback(handle, { timeout: 3000 })
    }

    // 上报数据  上报失败重新加入到任务列表
    private reportedData(task: TaskType) {
        // console.log('上报的数据', task)
        reportData({
            eventId: task.event,
            data: task.data
        }).then((res) => {
            // 上报成功
            // console.log('上报成功', task)
        }).catch((err) => {
            // console.log('上报失败', err)
            // 上报失败3秒后再重试
            // setTimeout(() => {
            //     this.taskList.push(task)
            //     this.execTask()
            // }, 3000);
        })
    }
}

// 用户行为中心处理
const BehaviorCenter = new Center()

export default BehaviorCenter
