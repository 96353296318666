import React, { useState, useEffect } from 'react'
import "./index.less"
import { useNavigate, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import { updateRoutes } from '../../../../../../store/modules/breadcrumbSlice';
import Cookies from 'js-cookie';
import { changeIsLogin } from '../../../../../../store/modules/loginSlice';
import { getWorkInvite } from '../../../../../../api/modules/study';
import { generateRandomTimeString } from '../../../../../../utils/randomTime';

type ListItemProps = {
  value?: any
  ifJoin?: any
  jion?: (value: any) => void   //加入实验室
  ifOpenWorkSpace: (value: any) => void   //进入课程
}

const ListItem: React.FC<ListItemProps> = (props) => {

  const { value, ifJoin, jion, ifOpenWorkSpace } = props

  const navigate = useNavigate()

  const { coucode } = useParams()

  const [messageIcon, setMessageIcon] = useState(false)



  const { routes } = useSelector((state: any) => state.breadcrumb);

  const joinCourse = () => {
    if (!Cookies.get('token')) {
      dispatch(changeIsLogin({ isLogin: true }))
    } else {
      if (ifJoin) {
        ifOpenWorkSpace(value?.chapter)
      } else {
        jion?.(value?.chapter)
      }
    }
  }

  const truncateString = (str: any) => {
    if (str.length > 6) {
      return str.substring(0, 6) + "...";
    } else {
      return str;
    }
  }


  const joinStudy = () => {

    if (Cookies.get('token')) {
      // jumpWork()
      const url = value.cooperateUrl
      let link = document.createElement('a');

      link.classList.add("link-class");
      link.href = url;
      link.target = 'page';
      let event = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      link.dispatchEvent(event);
    } else {
      dispatch(changeIsLogin({ isLogin: true }))
    }
  }

  //被邀请者跳转工作台
  const jumpWork = () => {
    getWorkInvite({
      repo: decodeURIComponent(value?.repo),
      sign: decodeURIComponent(value?.sign),
    }).then(res => {
      const { openApiAddr, access, partner, courseName } = res
      const url = `${openApiAddr}?modal=0&repo=${value?.repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(value?.chapter) ?? ''}&courseName=${encodeURIComponent(courseName)}&time=${generateRandomTimeString()}`
      let link = document.createElement('a');

      link.classList.add("link-class");
      link.href = url;
      link.target = 'page';
      let event = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      link.dispatchEvent(event);
    })
  }

  const dispatch = useDispatch()
  return (
    <div className='issueItem' onClick={() => {
      navigate(`/os/${coucode}/answerDetail?id=${value.id}`)
      const breadcrumRoutes = [...routes, {
        path: '',
        title: '问答详情'
      }]
      dispatch(updateRoutes({
        routes: breadcrumRoutes
      }))
    }}>
      <div className='issueItem-image'>
        {value?.user?.avatar || value?.user?.avatar?.custom ? <img src={value?.user?.avatar?.custom || value?.user?.avatar} style={{ width: '32px', height: '32px', borderRadius: '50%' }} alt="" /> : <img style={{ width: '32px', height: '32px', borderRadius: '50%' }} src={require('../../../../../../assets/images/signlogo.png')} alt="" />}
      </div>
      <div className='issueItem-content'>
        <div className='issueItem-content-left'>
          <div className='issueItem-content-left-name'>
            <span>{value?.user?.display_name || value?.user?.displayName}</span>
            <span style={{ marginLeft: '10px' }}>{value.createDateTime}</span>
          </div>
          <div className='issueItem-content-left-text'>
            <span>{value?.title}</span>
          </div>
          {(value?.type?.title || value.learn || value?.labels?.length > 0) && <div className='lable'>
            {value?.type?.title && <span style={{ color: '#faad14' }}>#{value?.type?.title}#</span>}
            {value.learn && <span style={{ marginLeft: '4px', color: '#faad14' }}>#影响学习进度#</span>}
            {value?.labels.map((item: any) => {
              return <span className='spanLable'>{item}</span>
            })}
          </div>}
          {value?.cooperateUrl && value?.courseDetail?.type !== 'BLOG' && <div className='content-address'>
            {value?.courseDetail?.type !== 'BLOG' && <div style={{ marginRight: '30px' }}>
              <span>来自：</span>
              <span onClick={(e) => {
                joinCourse()
                e.stopPropagation()
              }}>{truncateString(value?.chapter)}</span>
            </div>}
            {value?.cooperateUrl && <div>
              <span>协同链接：</span>
              <span onClick={(e) => {
                joinStudy()
                e.stopPropagation()
              }}>点击进入协同</span>
            </div>}
          </div>}
        </div>
        {value?.answerCount > 0 && <div className='issueItem-content-rigth' onMouseEnter={() => {
          setMessageIcon(true)
        }}
          onMouseLeave={() => setMessageIcon(false)}>
          <div>
            {messageIcon ? <img src={require('../../../../../../assets/images/issue/message1.png')} style={{ width: '16px', height: '16px', marginRight: '4px' }} alt="" /> : <img src={require('../../../../../../assets/images/issue/message2.png')} style={{ width: '16px', height: '16px', marginRight: '4px' }} alt="" />}
          </div>
          <div >{value?.answerCount}</div>
        </div>}
      </div>

    </div>
  )
}

export default ListItem;
