import React, { useEffect, useState } from 'react'
import './index.less'
import All from './All'
import { useSearchParams } from 'react-router-dom'
import LoginBg from '../../components/loginBg'
import Cookies from 'js-cookie'
import { getDetail } from '../../api/modules/common'
import { handleRedirect } from '../../utils/url'

const record: { [key: string]: string } = {
    'oscollege.net': '浙ICP备2022034019号-2',
    'cloudlab.top': '浙ICP备2022034019号-4'
}

const Login: React.FC = () => {

    const [search] = useSearchParams()

    const repo = search.get('repo') || ''

    const sign = search.get('sign') || ''
    const modal = search.get('modal') || ''

    useEffect(() => {
        if (Cookies.get('token') && !(repo && sign && modal)) {
            isLogin()
        }
    }, [])

    const isLogin = async () => {
        const res = await getDetail().catch(() => { })
        if (!res) return
        const { isLogin } = res
        if (!isLogin) return
        const url = await handleRedirect(window.location.href)
        window.open(url, '_self')
    }

    const hostName = window.location.hostname;
    // 默认第一个
    let recordText = record['oscollege.net']
    for (const key in record) {
        if (hostName.includes(key)) {
            recordText = record[key]
        }
    }


    return (
        <div className='login'>
            <div className='login-left'>
                <LoginBg></LoginBg>
            </div>
            <div className='login-right'>
                <All />
                <div className='login-right-gov'>渡鸦（杭州）互联网有限责任公司&nbsp;&nbsp;&nbsp;&nbsp;<a target='_blank' href='https://beian.miit.gov.cn/'>{recordText}</a></div>
            </div>
        </div>
    )
}

export default Login