
import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  ws: null, //建立的连接
  lockReconnect: false, //是否真正建立连接
  timeout: 15000, //15秒一次心跳
  timeoutObj: null, //心跳心跳倒计时
  serverTimeoutObj: null, //心跳倒计时
  timeoutnum: null, //断开 重连倒计时
  msg: null, //接收到的信息
  needReconnect: false //是否需要重连
}

export const websocketSlice = createSlice({
  name: "websocketSpace",
  initialState,
  reducers: {
    //初始化ws 用户登录后调用，重新进入
    setWebSocket(state, { payload }) {
      state.ws = payload.ws
    },

    // 重连
    setNeedReconnect(state, { payload }) {
      state.needReconnect = payload.needReconnect;
    },

    // 更新接收消息
    receiveData(state, { payload }) {
      state.msg = payload.msg
    },

  },
});
export const { setWebSocket, setNeedReconnect,  receiveData } = websocketSlice.actions;

export default websocketSlice.reducer;
