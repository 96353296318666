import React, { useState, useEffect } from 'react'
import "./index.less"
import Remark from '../../components/Remark';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../components/Breadcrumb';





const IssueRemark: React.FC = () => {


  const { routes } = useSelector((state: any) => state.breadcrumb);



  return (
    <div className='issue-remark'>

      <Breadcrumb />

      <div className='issue-remark-main'>
        <Remark></Remark>
      </div>
    </div>
  )
}

export default IssueRemark;
