import React, { useState, useEffect, useRef } from 'react'
import "./index.less"
import * as url from '../../../../utils/url'
import RemarkItem from './components/RemarkItem';
import { Modal, Pagination, message } from 'antd';
import RichEditor from '../../../RichEditor';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { commitAnswer, compentAnswers, getAnswerList, getProblemAnswer } from '../../../../api/modules/issue';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../../api/modules/person';
import Cookies from 'js-cookie';
import { changeIsLogin } from '../../../../store/modules/loginSlice';

type RemarkLeftProps = {
  value?: any
  getValue?: () => void //重新请求数据
  joinLab?: () => void   //加入实验室
  gitNo?: () => void    //添加权限
  ifJoin?: any
}
const { warning } = Modal;

const RemarkLeft: React.FC<RemarkLeftProps> = (props) => {
  const { userInfo } = useSelector((state: any) => state.loginUser)
  const { value, ifJoin = true, getValue, joinLab, gitNo } = props
  const labcode = url.getLabCodeFromHost()
  const [search] = useSearchParams()

  const newLabcode = search.get('labCode') || ''
  const code = search.get('code') || ''
  let id = search.get('id') || ''

  const { coucode } = useParams()

  // 路由导航
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState(1)                  // 分页器当前页数
  const [pageSize, setPageSize] = useState(10)                       // 分页器每一页的条数
  const [pageTotal, setPageTotal] = useState(0)

  // 富文本编辑器
  const editor = useRef<any>(null)

  //监听评论框是否有值
  const [ifValue, setIfValue] = useState(false)

  //评论
  const [compentList, setCompentList] = useState([])

  //是否处于评论中
  const [compentIng, setCompentIng] = useState(false)

  //获取评论列表
  const getAnsList = () => {
    getProblemAnswer(
      {
        id: id,
        current: currentPage,
        size: pageSize
      }
    ).then(res => {
      setCompentList(res.rows)
      setPageTotal(res.total)
    })
  }


  //回复
  const remark = () => {
    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    if (compentIng) return
    setCompentIng(true)
    const content = editor?.current.getHtml()
    commitAnswer({
      code: code || coucode,
      labCode: labcode ?? newLabcode,
      id: id,
      data: content
    }).then(() => {
      getAnsList()
      message.success('评论成功！')
      setIfValue(false)
      editor?.current.setValue()
      getValue?.()
      setCompentIng(false)
      scroll()
      setCurrentPage(1)
    }).catch(err => {
      console.log(err);
      if (err.status === 404) {
        warning({
          title: '温馨提示',
          icon: <ExclamationCircleFilled />,
          content: <p >噢噢!该问答已删除，去看看其他待解决的问答吧~</p>,
          okText: '我知道了',
          centered: true,
          onOk() {

            localStorage.setItem("courseTab", "2");
            navigate(-1)
          },
        });
      } else {
        message.warning(err.message)
      }
      setCompentIng(false)
    })
  }

  //滚动
  const scroll = () => {
    const element = document.querySelectorAll(".remark-answer"); // 替换为实际的元素 ID
    const newelement = document.querySelectorAll<HTMLElement>(".questList-text")

    if (element && newLabcode) {
      element[0]?.parentElement?.scrollTo({ top: newelement[0]?.offsetTop - 60, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: newelement[0]?.offsetTop, behavior: 'smooth' });
    }
  }

  //首次进来滚动


  //滚动
  const scrollFirst = () => {
    const element = document.querySelectorAll(".remark-answer"); // 替换为实际的元素 ID

    if (element && newLabcode) {
      element[0]?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const pageChage = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage)
    setPageSize(pageSize)
  }

  useEffect(() => {
    getAnsList()

  }, [pageSize, currentPage])


  useEffect(() => {
    scrollFirst()
  }, [])
  return (
    <div className='remarkAnser-left'>
      <div className='remark-title'>
        {value?.title}
      </div>
      <div className='questList'>
        <div className='questList-my'>
          <RemarkItem ifQuest={true} value={value}></RemarkItem>
        </div>
        <div className='questList-other'>
          <div className='questList-text'>
            <span>全部评论</span><span>共{value?.answerCount}条</span>
          </div>
          {compentList.length === 0 &&
            <div className='novalue'>
              <img src={require("../../../../assets/images/creatNovalue.png")} alt="" className='labs-my-img' />
              <div className='labs-my-text'>这里没有评论，都要长草啦！</div>

            </div>}
          {
            compentList.map((item: any) => {
              return <div className='questList-other-item'>
                <RemarkItem value={item}></RemarkItem>
              </div>
            })
          }
        </div>


      </div>
      {pageTotal > 0 && <div className='remark-Pagination'>
        <div className='remark-Pagination-btn' onClick={() => {
          localStorage.setItem("courseTab", "2");
          navigate(-1)
        }}>
          返回列表
        </div>
        <Pagination
          current={currentPage}
          total={pageTotal}
          showQuickJumper={true}
          showSizeChanger={false}
          onChange={pageChage}
          style={{ textAlign: 'right' }}
          pageSize={pageSize}
        />
      </div>}
      <div className='remark-comment'>
        <div className='remark-comment-logo'>
          <img className='header-container-content-right-login-user-logo' src={userInfo?.avatar ? userInfo?.avatar : require('../../../../assets/images/signlogo.png')} alt="" />
          <img src={value?.userInfo?.avatar} alt="" style={{ width: '28px', height: '28px', marginRight: '4px', marginTop: '2px' }} />
        </div>
        <div className='remark-comment-content'>
          <div>回复问答</div>
          <div style={{
            marginTop: '8px', border: '0.5px solid #e5e5e5',
            borderRadius: '4px',
            // zIndex: '9999',
            position: 'relative'
          }}>
            <RichEditor ref={editor} ifAnser={true} editorHeight={120} isShow={true} getHtml={() => {
              const value = editor?.current?.getHtml()
              if (value) {
                setIfValue(true)
              } else {
                setIfValue(false)
              }
            }}></RichEditor>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div className='btn-remark' style={{
              background: ifValue && Cookies.get('token') ? '#1677FF' : '#CCCCCC',
              cursor: ifValue && Cookies.get('token') ? 'pointer' : 'not-allowed'
            }} onClick={() => {
              if (ifValue) {
                remark()
              }
            }}>回复</div>
          </div>
        </div>

      </div>
      {newLabcode && <div className='remark-comment-none'></div>}
    </div>
  )
}

export default RemarkLeft;


