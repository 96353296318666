import React, { useEffect, useState } from 'react'
import './index.less'
import { Button, Modal } from 'antd'
import { repoExamLog } from '../../api/modules/exam'
import { useSearchParams } from 'react-router-dom'

type ExamWarnProps = {

}

const ExamWarn: React.FC<ExamWarnProps> = (props) => {

    const { } = props

    const [search] = useSearchParams()
    let examRecordCode = search.get('examRecordCode') || ''

    const [open, setOpen] = useState(false)

    useEffect(() => {
        const handle = () => {
            if (document.visibilityState === 'hidden') {
                setOpen(true)
                repoExamLog({
                    action: 'LEAVE',
                    examRecordCode
                }).catch(() => {})
            }
            if (document.visibilityState === 'visible') {
                repoExamLog({
                    action: 'STAY',
                    examRecordCode
                }).catch(() => {})
            }
        }
        window.addEventListener('visibilitychange', handle)
        return () => {
            window.removeEventListener('visibilitychange', handle)
        }
    }, [])


    return (
        <Modal
            open={open}
            wrapClassName={'examwarn-modal'}
            onCancel={() => setOpen(false)}
            footer={false}
            closable={false}
            centered={true}
            width={400}
            maskClosable={false}
            destroyOnClose={true}
        >
            <p className='examwarn-title'>
                <img src={require('../../assets/images/warn-modal.png')} alt="" />
                <span>警告！！！</span>
            </p>
            <div className='examwarn-content'>
                为确保考试成绩的真实性，考试过程中禁止<span className='red'>切屏、缩小窗口、复制粘贴</span>等违规操作。
                你刚刚的操作已经触发了告警，本次先给予警告。<span>请严肃考试纪律，以免影响考试结果。</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Button type='primary' onClick={() => { setOpen(false) }}>诚信参考</Button>
            </div>
        </Modal>
    )
}

export default ExamWarn