import http from "../index";

// 三方登录相关

// 获取第三方平台授权
export async function getPlatformAuth(type: string, params: any) {
    return http.get(`/oauth/authorize/${type}`, params)
}

// 获取第三方平台access-token
export async function getAuthToken(data: any) {
    return http.post(`/oauth/callback/`, data)
}

// 验证手机号是否绑定
export async function getPhoneIsBind(params: any) {
    return http.get(`/oauth/verify/phone`, params)
}

// 绑定手机号
export async function bindPhone(data: any) {
    return http.post(`/oauth/bind/phone`, data)
}