import React, { useState, useEffect } from 'react'
import "./index.less"

import { useSelector } from 'react-redux';
import Breadcrumb from '../../components/Breadcrumb';
import RemarkAnswer from '../../components/RemarkAnswer';
import Header from '../../components/Header';





const AnswerRemark: React.FC = () => {


  const { routes } = useSelector((state: any) => state.breadcrumb);



  return (
    <div className='answer-remark'>
      <Header />
      <Breadcrumb />

      <div className='answer-remark-main'>
        <RemarkAnswer></RemarkAnswer>
      </div>
    </div>
  )
}

export default AnswerRemark;
