
import { createSlice } from "@reduxjs/toolkit";

export const activeSlice = createSlice({
  name: "activeSpace", // 命名空间，在调用action的时候会默认的设置为action的前缀,保证唯一.不重名
  initialState: {
    activeIng:0
  },
  reducers: {
    // reducer函数 state当前组件的数据 
    //第二个参数为{payload:{},type:"""} 
    // 更新路由
    updateActive(state, { payload }) {
      state.activeIng = payload.activeIng
    }
  },
});
export const { updateActive } = activeSlice.actions;

export default activeSlice.reducer;
