import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, message } from 'antd';
import Publish from './components/Publish'; //发布课程
import { getLabDetail, saveCourse, publishCourse, getLabType } from '../../api/modules/product'
import './index.less'
import { useDispatch, useSelector } from 'react-redux';
import { updateRoutes } from '../../store/modules/breadcrumbSlice';
import { getCourseInfo, getWorkspace } from '../../api/modules/study';
import ImageVerify from './components/ImageVerify';
import ProductStage from './components/productStage';
import Cookies from 'js-cookie';
import * as url from '../../utils/url'
import PerfectBlog from './components/PerfectCourse';
import { updateBlogSetp } from '../../store/modules/courseStepSlice';
import { navigateRoutes } from '../../router/admin';
import { generateHomeUrl, generateLabHomeUrl } from '../../utils/url';

interface CourseSaveData {
  code: string;
  labCode: string;
  logoUrl: string;
  name: string;
  remark: string;
  courseType: string;
  mirror: string;
  [props: string]: any
}



const BlogCreat: React.FC = () => {
  const [params] = useSearchParams();
  // 实验室id
  let labCode = params.getAll("transId")[0]



  let courseCode = params.getAll("courseCode")[0]

  let currentStep = params.getAll("stepCurrent")[0]

  let route = params.getAll("route")[0]

  // 判断是否处于保存状态
  const [isSave, setIsSave] = useState(false)


  const [ifPublish, setIfPublish] = useState('')

  const { blogSetp } = useSelector((state: any) => state.blogSetp);

  // 当前step
  const [current, setCurrent] = useState(blogSetp);

  // 发布loading
  const [loading, setLoading] = useState(false);

  //机构
  const [repo, setRepo] = useState('')

  //凭证
  const [access, setAccess] = useState('')


  //凭证
  const [sign, setSign] = useState('')

  //工作台地址
  const [openUrl, setOpenUrl] = useState('')

  //判断是否只处于编辑课程内容

  const [editCourse, setEditCourse] = useState(false)

  const { routes } = useSelector((state: any) => state.breadcrumb);



  const dispatch = useDispatch()

  //实验室详情
  const [labValue, setLabValue] = useState<any>({
    code: '',
    name: ''
  })


  //校验是否成功
  const [verifyState] = useState(true)


  const [paramsSave, setParamsSave] = useState<CourseSaveData>({
    code: '',
    labCode: '',
    logoUrl: '',
    name: '',
    remark: '',
    courseType: '2001',
    mirror: '',
    courseLabel: [],
    template: ''
  })

  const navigate = useNavigate()




  const exerciseSteps = [
    {
      title: '创作工作台',
      content: <ProductStage labType={labValue?.type} labCode={labCode} webUrl={openUrl} webRepo={repo} webAccess={access} webSign={sign} data={openUrl ? `${openUrl}/?modal=1&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&key=${access}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}` : ''} nextStep={() => {
        if (courseCode) {
          getCourseInfo({
            courseCode
          }).then(res => {
            console.log(res);
            if (res.status === "UNCREATED" || res.status === "IMPORT_SUCCESS" || res.status === 'IMPORT_FAIL') {
              next()
            } else {
              next('update')
            }
          })
        }
      }} />,
    },
    {
      title: '填写博客信息',
      content: <PerfectBlog text={ifPublish === "UNCREATED" ? '创建博客' : '编辑博客'} nextStep={() => {
        next()
      }} previousStep={() => {
        prev()
      }}></PerfectBlog>
    },
    {
      title: '博客预览',
      content: <ImageVerify courseCode={courseCode} onPreviewClick={() => {
        window.open(generateLabHomeUrl(labCode, `os/${courseCode}?statu=${true}`, labValue?.type))
        // window.open(`${window.location.protocol}//${labCode}.${url.getSecondHost()}${window.location.port ? ':' + window.location.port : ''}/os/${courseCode}?statu=${true}`)
      }} />,
    },
    {
      title: '发布博客',
      content: <Publish current={current} data={courseCode} labValue={labValue?.code} verifyState={verifyState} />,
    }
  ];


  // 下一步
  const next = (v?: any) => {

    // 配置课程内容，提交github，校验课程
    
    if (v === 'update') {
      dispatch(updateBlogSetp({
        blogSetp: current + 2
      }))
      setCurrent(current + 2)

    } else {
      dispatch(updateBlogSetp({
        blogSetp: current + 1
      }))
      setCurrent(current + 1)

    }

  };

  // 请求开放平台接口
  const getOpen = (code: string) => {
    getWorkspace({
      code: code,
      modal: 'write'
    }).then(res => {
      console.log(res, '对接开放平台');

      setSign(res.sign)
      setRepo(res.repo)
      setOpenUrl(res.openApiAddr)
      setAccess(res.access)
    })
  }

  // 上一步
  const prev = () => {
    if (current === 1) {
      const breadcrumRoutes = [...routes]
      breadcrumRoutes.splice(breadcrumRoutes.length - 1, 1, {
        path: '',
        title: '创建博客'
      })
      dispatch(updateRoutes({
        routes: breadcrumRoutes
      }))
    }
    if (current === 2) {
      console.log(ifPublish);

      if (ifPublish === "UNCREATED") {
        dispatch(updateBlogSetp({
          blogSetp: current - 1
        }))
        setCurrent(current - 1)

      } else {
        dispatch(updateBlogSetp({
          blogSetp: current - 2
        }))
        setCurrent(current - 2)
      }

    } else {
      dispatch(updateBlogSetp({
        blogSetp: current - 1
      }))
      setCurrent(current - 1)
    }


  };

  // 发布
  const onPublish = () => {
    setLoading(true)

    publishCourse({ code: courseCode }).then(res => {
      dispatch(updateRoutes({
        routes: [
          {
            path: '/',
            title: '首页'
          },
        ]
      }))
      // navigate(`${navigateRoutes.blog}?labCode=${labCode}`)
      window.open(generateHomeUrl(`${navigateRoutes.blog.slice(1)}?labCode=${labCode}`, labValue?.type), '_self')
      dispatch(updateBlogSetp({
        blogSetp: 0
      }))
      setLoading(false)
      message.success('发布成功，快去开启你的学习之旅吧！')
    }).catch(err => {
      setLoading(false)
      message.error('发布失败')
    })
  }

  const getLab = () => {
    getLabDetail({
      labCode
    }).then(res => {
      setLabValue(res)
    })
  }

  //获取课程详情
  const fetchCourseDetail = () => {
    if (courseCode) {
      getCourseInfo({
        courseCode
      }).then(res => {
        console.log(res);
        // 兼容旧版本
        let img = ''
        try {
          img = JSON.parse(res.image)?.image
        } catch (error) {
          img = res.image
        }
        setIfPublish(res.status)
        setParamsSave({
          code: res.code,
          labCode: res.labCode,
          logoUrl: res.logoUrl,
          name: res.name,
          remark: res.remark,
          courseType: res.courseType.categoryCode,
          mirror: img,
          courseLabel: res.courseLabel,
          template: res.template
        })
      })
    } else {
      getCoursType()
    }
  }

  //校验是跳转

  const getCoursType = () => {
    getLabType().then((res: any) => {
      setParamsSave({

        code: '',
        labCode: '',
        logoUrl: '',
        name: '',
        remark: '',
        courseType: res[3].children[0].categoryCode,
        mirror: '',
        courseLabel: [],
        template: ''
      })
    })
  }





  useEffect(() => {
    if (Cookies.get('token')) {
      getLab()
      fetchCourseDetail()
    } else {
      navigate(`/`)
    }
  }, [])

  useEffect(() => {
    if (current === 0) {
      getOpen(courseCode)
    }
  }, [current])


  useEffect(() => {
    if (!Cookies.get('token')) {
      navigate(`/`)
    }
  }, [Cookies.get('token')])

  useEffect(() => {
    if (route) {
      dispatch(updateRoutes({
        routes: [
          {
            path: route,
            title: '实验室后台'
          },
          {
            path: '/',
            title: ifPublish === "UNCREATED" ? '创建博客' : '编辑博客'
          },
        ]
      }))
    }
  }, [ifPublish, route])
  // steps数据
  return (
    <div className='create-course' style={{ width: '100%', background: current === 1 ? '' : '#ffffff', margin: current === 0 ? '10px 30px 30px 30px' : '16px 0px 30px 0px', border: current === 0 ? '0.5px solid #e5e5e5' : '' }}>
      <div className='create-course-main'>
        <div className="steps-content">{exerciseSteps[current].content}</div>
        {current !== 1 && <div className="steps-action">

          {current > 0 && (
            <Button onClick={() => prev()} disabled={loading} className="botton1">
              上一步
            </Button>
          )}

          {current < exerciseSteps.length - 1 && (
            <Button type="primary" onClick={() => next()} >
              下一步
            </Button>
          )}
          {current === exerciseSteps.length - 1 && (
            <Button type="primary" loading={loading} onClick={() => onPublish()}>
              发布
            </Button>
          )}
        </div>}
      </div>
    </div>
  );
}

export default BlogCreat;