import http from "../index";
import config from "../../config";

const openURL = config.openURL

// 获取当前状态
export async function getRepoStatus(params: any) {
  return http.get(`${openURL}/repo/status`, {
    repo: params.repo,
    key: params.key,
    sign: params.sign
  });
}

// 获取单个活动详情
export async function getActivityInfo(id: string, params: any) {
  return http.get(`${openURL}/activity/${id}`, params)
}
