import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import CodePlayer from '../../../../../../components/CodePlayer'
import { useSearchParams } from 'react-router-dom'

type ContainerCoderPlayerProps = {
    value: any       // 实验室数据
    disableCopyPaste?: boolean
}

const ContainerCoderPlayer: React.FC<ContainerCoderPlayerProps> = (props) => {

    const [params] = useSearchParams();

    //题目类型 add：添加考试的试卷题目； mark：评分模式； view：查看模式； exam：考试中； preview：预览
    const status = params.getAll("status")[0]

    const { value, disableCopyPaste } = props

    const [oid, setOid] = useState()
    const codeplayerRef = useRef()

    useEffect(() => {
        if (value) {
            // 评分、查看模式：显示用户提交的代码
            if (status === 'mark' || status === 'view') {
                setOid(value?.userAnswer || '')
            } else {
                if (value?.userOptionJson?.language && value?.userOptionJson?.cache?.hasOwnProperty(value?.userOptionJson?.language)) {
                    setOid(value.userOptionJson.cache[value?.userOptionJson?.language] || '')
                } else {
                    setOid(value?.codeList?.[0]?.runCode || '')
                }
            }
        }
    }, [value])



    return (
        <div className='container-coder-player'>
            <CodePlayer ref={codeplayerRef} oid={oid} hasOid={true}/>
        </div>
    )
}

export default ContainerCoderPlayer