import config from "../../config";
import http from "../index";

// 获取课程类型
export async function getCourseType() {
  return http.get(`/config/course/type`);
}

// 获取实验室环境列表
export async function getImageLab(params:any) {
  return http.get(`/image/lab?labId=${params.labId}`);
}

// 获取实验室详情
export async function getLabDetail(params:any) {
  return http.get(`/lab/${params.labCode}`);
}

// 保存课程
export async function saveCourse(params:any) {
  return http.post(`/course/save`, {
      code: params.code,
      labCode: params.labCode,
      logoUrl: params.logoUrl,
      name: params.name,
      remark: params.remark,
    categoryCodes: params.courseType,
    categoryCode:params.courseType[0],
    image: params.mirror,
    shaded: params.shaded,
    isUpdate: params.isUpdate,
    labelCodes: params.labelCodes,
    template:params.template
  });
}


// 发布课程
export async function publishCourse(params:any) {
  return http.post(`/course/publish/${params.code}`);
}

//课程git Book检验
export async function crawlGitBook(params:any) {
  return http.post(`git-book/crawl`,params);
}

//课程git Book检验
export async function verifyGit(params:any) {
  return http.post(`/git-book/link/verify`,params);
}


// 实验室编码查重
export async function verify(params:any) {
  return http.post(`/lab/verify`, {
    code: params.code,
    labId: params.labId,
    name: params.name,
    isUpdate:params.isUpdate
  });
}

// 实验室编码查重
export async function saveLab(params:any) {
  return http.post(`lab/save`, {
    code: params.code,
	docUrl: params.docUrl,
	labId: params.labId,
	logoUrl: params.logoUrl,
	name: params.name,
	projectUrl: params.projectUrl,
	remark: params.remark,
    visibility:'PUBLIC',
    categories: params.categories,
    shaded: params.shaded,
    isUpdate: params.isUpdate,
    officialUrl: params.officialUrl,
    stars: params.stars,
    commits: params.commits,
    issues: params.issues,
    forks: params.forks,
    sampleCourse: params.sampleCourse,
    capacityList: params.capacityList,
    blogList: params.blogList,
    cooperateDesc: params.cooperateDesc,
    contactUs: params.contactUs,
  });
}

//生成课程编码
export async function courseCode(params:any) {
  return http.get(`course/verify?code=${params.code}&name=${params.name}&labCode=${params.labCode}&isUpdate=${params.isUpdate}`)
}

//实验室课程
export async function labCourse(params:any) {
  return http.post(`course/page`, {
    current: params.current,
    labCode: params.labCode,
    size:params.size,
    keywords: params?.keywords
  });
}

//图片上传
export async function imageUpload(params:any) {
  return http.post(`config/file/upload`, params.file,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

//校验url地址可行性
export async function validUrl(params:any) {
  return http.get(`config/valid/url?target=${params.url}`);
}

//题目型验证
export async function verifyCourse(params:any) {
  return http.post(`/course/verify`, {
    courseId: params.courseId,
    draft: false,
    jsonIndex: params.jsonIndex,
    labId: params.labId,
    type: params.type
  });
}

// 删除实验室
export async function deleteLab(params:any) {
  return http.delete(`lab/${params.code}`);
}

//获取实验室分类
export async function getLabType() {
  return http.get(`config/category`);
}

//获取课程标签
export async function getLabels() {
  return http.get(`config/labels`);
}

// 课程上下架
export async function delistCourse(params: any) {
  return http.post(`/course/under/${params.code}`);
}


// 加入协同合作实验室
export async function shareJoin(params: any) {
  return http.get(`/lab/share/join?labCode=${params.labCode}&shareCode=${params.shareCode}`);
}

// 分享实验室
export async function shareLab(params: any) {
  return http.post(`/lab/share`, params);
}

// 移除协同
export async function removeUser(params: any) {
  return http.post(`/lab/share/remove`, params);
}

//分页查询协同
export async function shareSearch(params: any) {
  return http.post(`/lab/share/user/page`, params);
}

// 移除实验室人员
export async function reomveLabpeo(params: any) {
  return http.post(`/lab/share/remove`, params)
}

// 更改课程为编辑状态
export async function changeStatus(params: any) {
  return http.post(`/course/status/${params.code}`)
}

// 查询课程在线人数
export async function queryOline(params: any) {
  return http.get(`${config.openURL}/repo/online?repo=${params.repo}`)
}

// 导入项目信息
export async function getGithubValue(params: any) {
  return http.get(`config/touch/github?target=${params.url}`)
}


// new导入项目信息
export async function verifyProjectUrl(params: any) {
  return http.get(`/lab/verify/projectUrl?target=${params.url}`)
}





// 查询实验环境
export async function getExperEnv(params: any) {
  return http.get(`${config.ptyURL}/cmd/template/list`, {
    owner: params?.owner,
    lab: params?.lab,
    custom: params?.custom || false
  })
}

// 模板查询
export async function getTemplate(params: any) {
  return http.get(`${config.ptyURL}/cmd/templates`, {
    owner: params?.owner,
    lab: params?.lab
  })
}

// 模板自定义内容格式校验
export async function verifyTemplate(template: string) {
  return http.post(`${config.ptyURL}/cmd/template/verify`, { template })
}

// 自定义环境保存
export async function saveEnv(params: any) {
  return http.post(`${config.ptyURL}/cmd/template/save`, {
      owner: params?.owner,
      lab: params?.lab,
      name: params?.name,
      template: params?.template,
      create: params?.create,
      id: params?.id || undefined
  })
}

// 获取端口图片
export async function getPortImg() {
  return http.get(`/config/picture/icon`)
}

// 创建课程
export async function createCourse(params: any) {
  return http.post(`/course/save`, params)
}


// 创建课程前配置端口
export async function savePort(params: any) {
  return http.post(`${config.ptyURL}/cmd/config/course`, params)
}



// 获取环境详情
export async function getEnvDetail(params: any) {
  return http.get(`${config.ptyURL}/cmd/template/get`, params)
}

//分页查询博客
export async function searchBlogs(params: any) {
  return http.post(`lab/blog/page`, params);
}

//博客修改
export async function updateBlogs(params: any) {
  return http.post(`lab/blog/update`, params);
}

//博客新增
export async function addBlogs(params: any) {
  return http.post(`lab/blog/save`, params);
}

// 删除博客
export async function deleteBlog(params:any) {
  return http.delete(`lab/blog/delete/${params.blogId}`);
}

//判断用户是否加入实验室
export async function ifJoin(params:any) {
  return http.get(`lab/user/isJoin?labCode=${params.labCode}`);
}
//加入实验室
export async function jionLabs(params:any) {
  return http.post(`lab/user/join`,params);
}

//列表查询实验室信息
export async function labsList(params:any) {
  return http.post(`lab/info/extend/list?labCode=${params.labCode}`,);
}

//查询新手任务
export async function guideTaskDetail(params:any) {
  return http.get(`lab/guideTask/detail/${params.code}?dictCode=${params.dictCode}`)
}

//查询新手任务
export async function guideTask(params:any) {
  return http.get(`lab/guideTask/${params.code}`)
}

//改变实验室状态
export async function changeLab(params:any) {
  return http.post(`/lab/change/status/${params.code}?status=${params.status}`)
}

//查询实验室统计数据
export async function labStats(params:any) {
  return http.get(`lab/stats/${params.code}`)
}

//实验室课程
export async function allCourse(params:any) {
  return http.post(`course/page`,params );
}
