
import { Button, Form, Input, Select, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { courseCode, imageUpload } from "../../../../api/modules/product";
import ImgUpload from "../../../../components/ImgUpload";
import "./index.less";
import { getBannerList } from "../../../../api/modules/common";
import EnlargeImg from "../../../../components/EnlargeImg";

import LogoSelect from "../../../../components/LogoSelect";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { layLabs, saveBlog } from "../../../../api/modules/blog";
import { useDispatch, useSelector } from "react-redux";
import { getCourseInfo } from "../../../../api/modules/study";
import { setAdminCrumb } from "../../../../store/modules/breadcrumbSlice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

type TypeCourseForm = {
  nextStep: () => void
  previousStep: () => void
  text?: any;
}

const PerfectBlog: React.FC<TypeCourseForm> = (props) => {

  const location = useLocation()


  const { admin } = useSelector((state: any) => state.breadcrumb)


  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [params] = useSearchParams();

  const [blogsValue, setBlogsValue] = useState<any>()


  let BlogLabCode = params.getAll("transId")[0]

  // 是否为个人实验室
  const [ifPeople, setIfPeople] = useState(true)



  let blogsCode = params.getAll("courseCode")[0]
  // 判断是否处于保存状态
  const [isSave, setIsSave] = useState(false)

  //logo弹窗
  const [logoMadol, setLogoMadol] = useState(false)

  // 删除图标
  const [deleteIcon, setDeleteIcon] = useState<boolean>(false);

  //博客标签
  // const [labelCodeList, setLabelCodeList] = useState<any[]>()

  const [fields, setFields] = useState<FieldData[]>(
    [
      { name: ['name'], value: '' },
      { name: ['remark'], value: '' },
      { name: ['labCode'], value: '' },
      { name: ['logoUrl'], value: '' },
    ]);

  const [labList, setLabList] = useState<any>([])

  //图片放大弹窗
  const [openImg, setOpenImg] = useState(false)

  //图片放大弹窗
  const [openImgUrl, setOpenImgUrl] = useState('')


  const [form] = Form.useForm();

  //当前选择图片
  const [imgActive, setImgActive] = useState(0);


  //投放实验室弹窗
  const [addressModal, setAddressModalOpen] = useState(false);

  //默认图片储存
  const [defaultImg, setDefaultImg] = useState<any[]>([])

  //所有默认图片
  const [bannerImg, setBannerImg] = useState<any[]>([])

  //是否显示上传的图片
  const [banners, setBanners] = useState(false)

  //控制刷新图片转动
  const [isRotating, setIsRotating] = useState(false);


  //当前的默认图片位置
  const [defaulIndex, setDefaulIndex] = useState(2)


  //博客名称校验
  const [nameValue, setNameValue] = useState(true); // 受控的校验状态
  const [valueName, setValueName] = useState(''); // 校验的提示信息

  const [remarkValue, setRemarkValue] = useState(true); // 受控的校验状态
  const [remarkValueHelp, setRemarkValueHelp] = useState(''); // 校验的提示信息

  //权限icon变化
  const [addressValue, setAddressValue] = useState<boolean>(true);


  //是否校验非空
  const [validfiled, setValidfiled] = useState(false)

  //是否显示上传的图片
  const [uploadShow, setUploadShow] = useState(false)

  const [imageHover, setImageHover] = useState(false)


  const handleName = async () => {
    // step1. 非空校验
    if (validfiled) {
      if (fields[0].value === undefined || !fields[0].value.trim()) {

        setNameValue(false);
        setValueName('请输入博客标题');
        throw new Error('请输入博客标题');
      }
    }
    const patrn = new RegExp("^[a-zA-Z0-9 \\u4e00-\\u9fa5\\_\\-]+$")

    if (fields[0].value) {
      if (fields[0].value.length > 2 && fields[0].value.length < 51 && patrn.test(fields[0].value)) {
        try {
          let res = await courseCode({
            code: blogsCode,
            labCode: BlogLabCode,
            name: fields[0].value,
            isUpdate: true
          }); // todo 后端校验请求
          if (res) {
            setNameValue(true);
            setValueName('');
            return res;
          }
        } catch (e) {
          setNameValue(false);
          setValueName('已有重复标题');
          throw new Error('已有重复标题'); // ↑ 错误信息会显示手动设置的 help（不会显示 Error 中的值）
        }
      } else {
        setNameValue(false);
        setValueName('请输入3-50字的中文、英文、数字');
        throw new Error('请输入3-50字的中文、英文、数字'); // ↑ 错误信息会显示手动设置的 help（不会显示 Error 中的值）
      }
    }
  }

  const handRemark = async () => {
    if (validfiled) {
      if (fields[1].value === undefined || !fields[1].value.trim()) {
        setRemarkValue(false);
        setRemarkValueHelp('请输入博客简介');
        throw new Error('请输入博客简介');
      } else if (fields[1].value.length < 10) {
        setRemarkValue(false);
        setRemarkValueHelp('请输入10个字以上博客简介');
        throw new Error('请输入10个字以上博客简介');
      }

    }
  }






  const getImage = (e: any) => {
    const formData = new FormData();
    formData.append('file', e)
    imageUpload({ file: formData }).then((res) => {
      setImgActive(3)
      let uploadImage = JSON.parse(JSON.stringify(defaultImg));
      if (uploadImage[3]) {
        uploadImage.pop()
        uploadImage.push(res.url)
      } else {
        uploadImage.push(res.url)
      }
      setDefaultImg(uploadImage)
      setUploadShow(false)
    })
  };

  //图片删除图标
  const deteleImage = () => {
    let uploadImage = JSON.parse(JSON.stringify(defaultImg));
    setTimeout(() => {
      uploadImage.pop()
      setImgActive(0)
      setDefaultImg(uploadImage)
    }, 300);
    let labValue = JSON.parse(JSON.stringify(fields));
    labValue[2].value = defaultImg[0]
    setFields([...labValue])
    setUploadShow(true)
  }

  //加载自定义图片
  const getUserImg = () => {
    let imgValue = JSON.parse(JSON.stringify(defaultImg));
    let runing = true

    if (blogsValue?.logoUrl && banners && imgValue.length < 4) {
      setBanners(false)
      defaultImg.forEach((item: any, index: number) => {
        if (blogsValue.logoUrl) {
          if (item === blogsValue.logoUrl) {
            setImgActive(index)
            runing = false
          } else {
            if (runing) {
              imgValue.push(blogsValue.logoUrl)
              let array = imgValue.filter((item: any, index: number) => {
                return imgValue.indexOf(item) === index;  // 因为indexOf 只能查找到第一个  
              });
              setDefaultImg(array)

              setImgActive(3)

            }
          }
        }
      })
    }
  }

  //替换默认图片
  const changeBannerImg = () => {
    const defaulList = [...defaultImg]
    let imgIndex = 0
    for (let i = defaulIndex + 1; i < defaulIndex + 4; i++) {
      if (i < bannerImg.length) {
        const value = bannerImg[i];
        defaulList.splice(imgIndex, 1, value);
        imgIndex++
        setDefaulIndex(i)
      } else {
        const value = bannerImg[i - bannerImg.length];
        defaulList.splice(imgIndex, 1, value);
        imgIndex++
        setDefaulIndex(i - bannerImg.length)
      }

    }
    setDefaultImg(defaulList)
  }

  //刷新图片转动
  const rotateClick = () => {
    setIsRotating(true);
    setTimeout(() => {
      changeBannerImg()
      setIsRotating(false);
    }, 1000);
  };

  //上传图片
  const reUpload = () => {
    let load = document.getElementById("upload")
    const antup = load?.getElementsByTagName("input")[0]
    antup?.click()

  }
  //点击放大图片
  const addOpen = (url: any) => {
    setOpenImg(true)
    setOpenImgUrl(url)
  }



  const onFinish = (values: any) => {
    let shaded
    values['logoUrl'] = defaultImg[imgActive]
    if (imgActive === 3) {
      shaded = false
    } else {
      shaded = true
    }
    setIsSave(true)
    saveBlog({
      name: values['name'],
      code: blogsCode,
      layCodes: values['labCode'] ? [values['labCode']] : [],
      remark: values['remark'],
      logoUrl: values['logoUrl'],
      isUpdate: true,
      shaded: shaded
    }).then(res => {
      message.success('保存成功！')
      setIsSave(false)

      props.nextStep()
    })
  };



  // 获取banner 
  const fetchBanner = () => {
    getBannerList({
      path: 'product'
    }).then(res => {
      const bannerImg: any = []
      const imageList = res.course.map((item: any, index: number) => {
        bannerImg.push(item.image)
        if (index < 3) {
          return item.image
        } else {
          return undefined
        }
      }).filter((item: any) => item !== undefined);

      setBannerImg(bannerImg)
      setDefaultImg([...imageList])
      //默认保存实验室图片
      setBanners(true)
    })
  }



  const getBlogvalue = () => {
    getCourseInfo({
      courseCode: blogsCode
    }).then(res => {
      setBlogsValue(res)
      let labValue = JSON.parse(JSON.stringify(fields));
      labValue[0].value = res.name
      labValue[1].value = res.remark
      labValue[2].value = res.layLabCode
      setFields([...labValue])
    })
  }

  const getLabList = () => {
    layLabs(
      blogsCode
    ).then(res => {
      let courseType: any[] = [];
      res.forEach((item: any) => {
        courseType.push({ label: item.name, value: item.code })
      })
      setIfPeople(true)
      setLabList(courseType)
    }).catch(err => {
      setIfPeople(false)
    })
  }


  useEffect(() => {
    fetchBanner()
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])

  useEffect(() => {
    if (uploadShow) {

      let labValue = JSON.parse(JSON.stringify(fields));
      labValue[2].value = defaultImg[0]
      setFields([...labValue])
      setUploadShow(false)
    }

  }, [defaultImg, fields, uploadShow])





  useEffect(() => {
    let imgValue = JSON.parse(JSON.stringify(defaultImg));
    imgValue.pop()
    let bannerValue = JSON.parse(JSON.stringify(bannerImg));
    let imgIndex = 0
    let ifImg = true

    if (imgValue.length > 0 && bannerValue.length > 0 && blogsValue?.logoUrl) {
      bannerValue.forEach((item: any, index: number) => {
        if (item === blogsValue.logoUrl) {
          console.log('logo相同');
          imgValue.forEach((aItem: any) => {
            if (aItem !== blogsValue.logoUrl) {
              imgIndex = index
            }
          })
          const aList = bannerValue.splice(imgIndex, 1)[0];
          bannerValue.unshift(aList)
          const arr2 = bannerValue.slice(0, 3)
          setDefaultImg(arr2)
          setBannerImg(bannerValue)


          ifImg = false
        }
      })
    }
    if (ifImg) {
      getUserImg()
    }
  }, [blogsValue?.logoUrl, banners])

  useEffect(() => {
    if (blogsCode) {
      if (admin?.length > 0) {
        dispatch(setAdminCrumb([admin[0],
        {
          title: '编辑博客信息',
          url: location.pathname + location.search
        }
        ]))
      }
      getBlogvalue()
      getLabList()
    }
  }, [blogsCode])

  return (
    <div className="createblog">
      <div className="createblog-name">
        <div className="createblog-name-line"></div>
        <div className="createblog-name-text" onClick={() => {


        }}>{props.text}</div>
      </div>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        fields={fields}
        style={{ maxWidth: 700 }}
        scrollToFirstError
        onFieldsChange={(_, allFields) => {
          setFields(allFields);
        }}
      >

        <Form.Item label='博客标题'>
          <Form.Item
            name='name'
            validateTrigger="onBlur" // 输入完成, 失去焦点后开始校验
            validateStatus={nameValue ? 'success' : 'error'}
            help={valueName}
            rules={[
              { validator: handleName },
            ]}
            noStyle
          >
            <Input placeholder="请输入博客标题" showCount maxLength={50} autoComplete="off" onFocus={() => {
              setValidfiled(false)
            }}
            />
          </Form.Item>
          <div className="labelAfter">*</div>
        </Form.Item>
        <Form.Item label={'博客简介'}>
          <Form.Item

            name='remark'
            validateTrigger="onBlur" // 输入完成, 失去焦点后开始校验
            validateStatus={remarkValue ? 'success' : 'error'}
            help={remarkValueHelp}
            rules={[
              { validator: handRemark },
            ]}
            noStyle
          >
            <Input.TextArea
              style={{ height: 100, resize: "none" }}
              placeholder="请输入博客简介"
              showCount maxLength={200}
              onFocus={() => {
                setValidfiled(false)
              }}
            />
          </Form.Item>
          <div className="labelAfter">*</div>
        </Form.Item>
        {ifPeople &&
          <Form.Item label="投放实验室">
            <Form.Item name="labCode" rules={[{ required: true, message: '请选择投放实验室' }]} noStyle>
              <Select
                placeholder="请选择投放实验室"
                allowClear
                options={labList}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >

              </Select>
            </Form.Item>
            <div className="labelAfter" style={{ left: "-25px" }}>*</div>
            {addressValue ? (
              <img
                src={require("../../../../assets/images/laboratoryIcon.png")}
                alt=""
                style={{
                  position: "absolute",
                  top: "6px",
                  width: "16px",
                  height: "16px",
                  marginBottom: "2px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onMouseEnter={() => setAddressValue(false)}
                onMouseLeave={() => setAddressValue(true)}
              />
            ) : (
              <img
                src={require("../../../../assets/images/laboratoryActiveIcon.png")}
                alt=""
                style={{
                  position: "absolute",
                  top: "6px",
                  width: "16px",
                  height: "16px",
                  marginBottom: "2px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onMouseEnter={() => setAddressValue(false)}
                onMouseLeave={() => setAddressValue(true)}
                onClick={() => setAddressModalOpen(true)}
              />
            )}
          </Form.Item>
        }

        <Form.Item label={'博客logo'}>
          <Form.Item
            name='logoUrl'
            noStyle
          >
            <div className="imageLogos">
              {defaultImg.map((v, index) => {
                return (
                  <div
                    className="logoItem"
                    key={index}
                    onClick={() => {
                      setImgActive(index)
                    }}
                    onMouseEnter={() => {
                      if (index === 3) {
                        setImageHover(true)
                      }
                    }
                    }
                    onMouseLeave={() => {
                      if (index === 3) {
                        setImageHover(false)
                      }
                    }}
                  >
                    <img src={v} alt="" style={{ width: '100%', height: '100%' }} />
                    {imgActive === index ? (
                      <img
                        src={require("../../../../assets/images/logoActive.png")}
                        alt=""
                        className="activeImage"
                      />
                    ) : (
                      <></>
                    )}
                    {(index === 3 && imageHover) ? <div className="hoverAction">
                      <div onClick={() => {
                        deteleImage()
                      }}>
                        <img
                          src={require("../../../../assets/images/rubbish.png")}
                          alt=""
                          style={{ width: '16px', height: '16px' }}
                        />
                      </div>
                      <div onClick={() => {
                        reUpload()
                      }}>
                        <img
                          src={require("../../../../assets/images/note.png")}
                          alt=""
                          style={{ width: '16px', height: '16px' }}
                        />
                      </div>
                      <div onClick={() => {
                        addOpen(v)
                      }}>
                        <img
                          src={require("../../../../assets/images/openBig.png")}
                          alt=""
                          style={{ width: '16px', height: '16px' }}
                        />
                      </div>
                    </div> : <></>}
                  </div>
                );
              })}
              <div id="upload" style={defaultImg.length === 4 ? { position: 'absolute', bottom: '10000px' } : {}}>
                <ImgUpload getImage={(e: any) => getImage(e)} imageChange={uploadShow} ></ImgUpload>
              </div>
              {
                imgActive === 4 ?
                  <img
                    src={require("../../../../assets/images/logoActive.png")}
                    alt=""
                    className="activeImage"
                  />
                  : <></>
              }
            </div>
            <div className="updateIcon" onClick={rotateClick}>
              <img id="updateImg" src={require("../../../../assets/images/updateImg.png")} alt="" style={{ width: '15px', height: '14px' }} className={isRotating ? 'rotate' : ''} />
              <p className="updateIcon-text">换一换</p>
            </div>
          </Form.Item>
          <div className="labelAfter">*</div>
        </Form.Item>
        <Form.Item>
          <div style={{ marginTop: '16px' }}>
            <Button className="botton1" onClick={() => {
              props.previousStep()
            }}>
              上一步
            </Button>
            <Button type="primary" htmlType="submit" className="botton2" loading={isSave} onClick={() => {
              setValidfiled(true)
            }}>
              下一步
            </Button>
          </div>
        </Form.Item>
      </Form>
      <EnlargeImg ifTrue={openImg} url={openImgUrl} changeIfTrue={() => {
        setOpenImg(false)
      }}></EnlargeImg>
      <LogoSelect logoUrl={defaultImg[0]} logoList={bannerImg} logoShow={logoMadol} logoChange={() => {
        setLogoMadol(false)
      }} seleLgog={(e) => {
        setValidfiled(false)
        let logo = JSON.parse(JSON.stringify(defaultImg))
        logo[0] = e
        setDefaultImg(logo)
      }}></LogoSelect>
      <Modal wrapClassName={'wenhaoModal'} open={addressModal} footer={''} width={'411px'} style={{ top: "40%" }}>
        <div style={{ position: 'absolute', top: '4px', right: '4px', cursor: 'pointer' }}>
          <div onClick={() => setAddressModalOpen(false)} onMouseEnter={() => setDeleteIcon(true)} onMouseLeave={() => setDeleteIcon(false)}>
            {deleteIcon ? <img src={require('../../../../assets/images/login-iconActive.png')} alt="" /> : <img src={require('../../../../assets/images/login-icon.png')} alt="" />}
          </div>
        </div>

        <div className="powerClass">
          <p>1.实验室地址是专属于该实验室的访问地址，具有唯一性;</p>
          <p style={{ marginLeft: '10px' }}>地址不含空格、不含除“-”和“_”外的特殊字符。</p>
          <p>2.实验室地址创建完成后不可修改。</p>
        </div>
      </Modal>
    </div>
  );
};

export default PerfectBlog;


