import React from 'react';
import './index.less';

const record: {[key: string]: string} = {
  'oscollege.net': '浙ICP备2022034019号-2',
  'cloudlab.top': '浙ICP备2022034019号-4'
}

const Footer: React.FC = () => {

  const hostName = window.location.hostname;
  // 默认第一个
  let recordText = record['oscollege.net']
  for (const key in record) {
    if (hostName.includes(key)) {
      recordText = record[key]
    }
  }

  return (
    <div className="footer-container">
      <div className='footer-container-main'>
        <div className='tabs'>
          {/* <div className='tab'>
              <div className='title'>总部地址</div>
              <div className='item'>
                <p>中国杭州市西湖区</p>
                <p>西溪路569号蚂蚁A空间</p>
                <p>电话：(+86)571-2688-8888</p>
                <p>电话：(+86)571-8643-2799</p>
              </div>
            </div> */}
          <div className='tab'>
            <div className='title'>新手入门</div>
            <div className='item'>
              <a href='https://a4uo85.helplookapp.com/docs/novice-manual' target="_blank">使用教程</a>
              <a>用户协议</a>
              <a>客服与帮助</a>
            </div>
          </div>
          {/* <div className='tab'>
            <div className='title'>帮助文档</div>
            <div className='item'>
              <a href='https://a4uo85.helplookapp.com/docs/novice-manual' target="_blank">新手使用手册</a>
              <a>课程发布流程</a>
              <a>课堂规则</a>
            </div>
          </div> */}
          <div className='tab'>
            <div className='title'>关于我们</div>
            <div className='item'>
              <a>简介</a>
            </div>
          </div>
        </div>
        <div className='company'>渡鸦（杭州）互联网有限责任公司&nbsp;&nbsp;&nbsp;&nbsp;<a target='_blank' href='https://beian.miit.gov.cn/'>{recordText}</a></div>
      </div>
    </div>
  );
}

export default Footer;