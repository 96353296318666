import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from "react-redux"
import Cookies from 'js-cookie'
import './index.less'
import Info from './components/Info' //课程信息显示
import CourseAnswerList from './components/CourseAnswerList' //问答区
import DirectoryOne from './components/DirectoryOne'
import DirectoryTwo from './components/DirectoryTwo'
import DirectoryThree from './components/DirectoryThree'
import { updateCourseSpeed } from '../../../../store/modules/courseSpeed'
import { getCourseStatus, getStudyCourseStatus } from '../../../../api/modules/study'

type CourseProps = {
  viewStatus: any //预览状态
  data?: any //课程信息
  ifOpenWorkSpace?: (name?: any) => void //打开工作台
}

const Course: React.FC<CourseProps> = (props) => {
  const { data, viewStatus, ifOpenWorkSpace } = props

  const dispatch = useDispatch()

  //判断在哪个目录下
  const [tabActive, settabActive] = useState(1)

  //目录层级
  const [courseIndex, setCourseIndex] = useState<number>(1)

  // 课程列表
  const [courseList, setCourseList] = useState<any>()

  //判断课程目录结构
  const changeCourseList = (value: any) => {
    // 现在改为通过类型判断
    const type = value[0]?.describe?.type ?? ''
    // 对应阶段 章 节
    switch (type) {
      case 'stage':
        setCourseIndex(3)
        break
      case 'chapter':
        setCourseIndex(2)
        break
      case 'text':
      case 'video':
      default:
        setCourseIndex(1)
        break
    }

    // 原来判断目录层级的方法
    // value?.forEach((item: any) => {
    //   if (item?.children?.length > 0) {
    //     item?.children?.forEach((items: any) => {
    //       if (items?.children?.length > 0) {
    //         setCourseIndex(3)
    //       } else {
    //         setCourseIndex(2)
    //       }
    //     })
    //   } else {
    //     setCourseIndex(1)
    //   }
    // })
  }

  // 获取用户课程信息的学习进度(登录状态下)
  const fetchCourseStatus = () => {
    getCourseStatus({ code: data?.code }).then((res: any) => {
      // 目录节点里显示当前进度
      dispatch(updateCourseSpeed({
        courseSpeed: res?.speed ?? ''
      }))
    }).catch(() => {
      dispatch(updateCourseSpeed({
        courseSpeed: ''
      }))
    })
  }

  const getStatus = () => {
    if (!viewStatus) {
      getStudyCourseStatus({ code: data?.code }).then((res: any) => {

        dispatch(updateCourseSpeed({
          courseSpeed: res.speed ?? ''
        }))

      }).catch(() => {
        dispatch(updateCourseSpeed({
          courseSpeed: ''
        }))
      })
      return
    }
    getCourseStatus({ code: data?.code }).then((res: any) => {

      dispatch(updateCourseSpeed({
        courseSpeed: res.speed ?? ''
      }))
    }).catch(() => {
      dispatch(updateCourseSpeed({
        courseSpeed: ''
      }))
    })
  }

  useEffect(() => {
    // 区分预览状态，拿到课程目录数据
    const dir = viewStatus ? data?.commitDir : data?.courseDir
    setCourseList(dir || [])
    changeCourseList(dir || [])
  }, [data])

  // 获取用户课程信息的学习进度(登录状态下)
  useEffect(() => {
    if (Cookies.get('token') && data?.code) {
      getStatus()
    }
  }, [data, Cookies.get('token')])

  // 重新获取学习进度
  const handleVisibilityChange = () => {
    if (!document.hidden && !viewStatus) {
      if (Cookies.get('token')) {
        getStatus()
      }
    }
  }

  // 课程详情：页面重新出现时，处理学习进度
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }
  }, [])

  // 问答详情：返回列表 => 需定位在“问答区”的tab
  useEffect(() => {
    if (localStorage.getItem("courseTab")) {
      settabActive(2)
    }
    localStorage.setItem("courseTab", "");
  }, [localStorage.getItem("courseTab")])

  return (
    <>
      <Info data={data} viewStatus={viewStatus} ifOpenWorkSpace={(...args) => {
        ifOpenWorkSpace?.(...args)
        // 重新获取状态，避免被浏览器拦截无法及时更新状态
        handleVisibilityChange()
      }} />
      <div className='detailcourse-content-tabs' >
        <div className='detailcourse-content-tabs-item' style={{ color: tabActive === 1 ? ' #1677ff' : '#333333', }}
          onClick={() => {
            settabActive(1)
          }}
        >
          课程目录
          {
            tabActive === 1 && <div className='line'></div>
          }
        </div>
        <div className='detailcourse-content-tabs-item' style={{
          marginLeft: '320px', color: !viewStatus ? tabActive === 2 ? ' #1677ff' : '#333333' : '#ccc',
          cursor: !viewStatus ? 'pointer' : 'not-allowed'
        }}
          onClick={() => {
            if (!viewStatus) {
              settabActive(2)
            }
          }}
        >
          问答区
          {
            tabActive === 2 && <div className='line'></div>
          }
        </div>
      </div>
      {
        tabActive === 1 && (courseList?.length ?
          (
            <div className='detailcourse-content-course'>
              {
                courseIndex !== 1 ? courseList?.map((item: any) => {
                  if (courseIndex === 3) {
                    return <DirectoryThree course={item} key={item.index} />
                  } else if (courseIndex === 2) {
                    return <DirectoryTwo course={item} key={item.index} />
                  } else {
                    return <></>
                  }
                }) : <DirectoryOne course={courseList} code={data?.code} />
              }
            </div>
          ) :
          (
            <div className='detailcourse-content-nodata'>
              <img style={{ width: '200px', height: '200px', marginBottom: '30px' }} src={require('../../../../assets/images/labNovalue.png')} alt="" />
              <div>暂无内容</div>
            </div>
          )
        )
      }
      {
        tabActive === 2 && <CourseAnswerList ifOpenWorkSpace={(name: any) => {
          ifOpenWorkSpace?.(name)
          // 重新获取状态，避免被浏览器拦截无法及时更新状态
          handleVisibilityChange()
        }}></CourseAnswerList>
      }
    </>
  );
}

export default Course;
