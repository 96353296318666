import React, { useState, useEffect } from 'react'
import "./index.less"

import { useDispatch, useSelector } from 'react-redux';
import * as url from '../../utils/url'
import Breadcrumb from '../../components/Breadcrumb';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Header from '../../components/Header';

import { updateRoutes } from '../../store/modules/breadcrumbSlice';
import { useParams } from 'react-router-dom';
import JoinModal from '../StudyLabCourse/components/ImportModal';
import Cookies from 'js-cookie';
import { changeIsLogin } from '../../store/modules/loginSlice';
import { ifJoin, jionLabs, labsList } from '../../api/modules/product';
import { Modal, message } from 'antd';
import CourseAnswerList from '../CourseDetail/components/Course/components/CourseAnswerList';





const BlogAnswer: React.FC = () => {

  const labcode = url.getLabCodeFromHost()


  const { routes } = useSelector((state: any) => state.breadcrumb);

  // 用户登录信息
  const { userInfo } = useSelector((state: any) => state.loginUser)

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)   // 新建问答弹框显示

  // 是否点击加入实验室
  const [clickJion, setclickJion] = useState(false)

  //表单需要填写的内容
  const [openFromValue, setOpenFromValue] = useState([])

  //是否有表单需要填写
  const [isOpenJoin, setIsOpenJoin] = useState({
    isJoin: false,
    isOpenFrom: false,
  })

  const { coucode } = useParams()

  //判断是否要有新建问答标签
  const [ifCreat, setIfCreat] = useState(true)

  //未加入实验室弹窗
  const jionLab = () => {
    Modal.confirm({
      title: '加入实验室',
      icon: <ExclamationCircleFilled />,
      content: '您还未加入实验室无法新建问答，请先加入实验室～',
      okText: '加入实验室',
      centered: true,
      cancelText: '我再看看',
      onOk() {
        joinLab()
      },
      onCancel() {

      },
    })
  };

  //加入实验室
  const joinLab = () => {
    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    if (!isOpenJoin.isJoin) {
      if (isOpenJoin.isOpenFrom) {
        labsList({ labCode: labcode }).then(res => {
          setOpenFromValue(res)
          setclickJion(true)
        })
      } else {
        jionLabs({ labCode: labcode, recordList: [] }).then(() => {
          message.success('加入成功！')
          getJion()
        })
      }
    }
  }

  //用户是否加入实验室
  const getJion = () => {
    ifJoin({ labCode: labcode }).then(res => {
      setIsOpenJoin({
        isJoin: res.isJoin,
        isOpenFrom: res.isOpenFrom,
      })
    })
  }


  useEffect(() => {
    if (labcode) {
      console.log(labcode);
      getJion()
    }
  }, [labcode,])

  useEffect(() => {
    if (routes?.length > 1) {
      const breadcrumRoutes = [routes[0], routes[1], {
        path: `/os/${coucode}/bloganswer`,
        title: '问答区'
      }]
      dispatch(updateRoutes({
        routes: breadcrumRoutes
      }))
    }
  }, [])

  return (
    <div className='blog-remark'>
      <Header />
      <Breadcrumb />
      <JoinModal labCode={labcode ?? ''} show={clickJion && isOpenJoin.isOpenFrom} value={openFromValue} changeClick={(e: any) => {
        if (e) {
          setclickJion(false)
          getJion()
        } else {
          setclickJion(false)
        }
      }}></JoinModal>
      <div className='blog-remark-main'>
        <div className='blog-remark-main-top'>
          <div className='blog-remark-main-top-left'>
            <div className='line'></div>
            <div>博客问答</div>
          </div>
          {ifCreat && <div className='blog-remark-main-top-rigth' onClick={() => {
            const token = Cookies.get('token')
            if (!token || !userInfo?.userId) {
              dispatch(changeIsLogin({ isLogin: true }))
              return
            }
            if (!isOpenJoin.isJoin) {
              jionLab()
            } else {
              setShow(true)
            }

          }}>
            新建问答
          </div>}
        </div>
        <CourseAnswerList show={show} type={'Blog'} changeShow={(v: any) => {
          setShow(v)
        }} getValue={() => {
          setIfCreat(false)
        }} ></CourseAnswerList>

      </div>
    </div>
  )
}

export default BlogAnswer;
