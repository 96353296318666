import React from 'react';
import './index.less';
import { Outlet } from "react-router-dom";
import Sider from '../Sider';

type ContentProps = {
  sider?: boolean       // 是否需要侧边导航栏默认需要
}

const Content: React.FC<ContentProps> = (props) => {

  const { sider = true } = props

  return (
    <div className="content-container">
      <div className='content-container-content'>
        {sider && <Sider />}
        <div className='content-container-content-main'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Content;