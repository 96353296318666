import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import DirectoryOne from '../DirectoryOne'

export type DirectoryTwoProps = {
    id?: string,
    section?: string,
    title?: string,
    course?: any,
}

const DirectoryTwo: React.FC<DirectoryTwoProps> = (props) => {

    const { course } = props

    const [isopen, setIsopen] = useState(true)

    const parent = useRef(null)
    const list = useRef(null)

    // console.log(course);


    const open = () => {
        setIsopen(!isopen)
    }

    useEffect(() => {
        const elpa: any = parent.current
        const elch: any = list.current
        const height = elch.offsetHeight
        elpa.style.height = height + 40 + 'px'
    }, [isopen])


    return (
        <div className='two' ref={parent}>
            <div className='two-section' onClick={open}>
                <span className='towTitle'>{course.title}</span>
                <div className='two-section-btn'>
                    <div className='two-section-btn-img' >
                        <img src={require('../../../../../../assets/images/bluearrow-b.png')} alt="" className={isopen ? "change0" : "change180"} />
                    </div>
                </div>
            </div>
            <div className='two-one' style={{ display: isopen ? 'block' : 'none' }} ref={list}>
                {

                    <DirectoryOne course={course?.children} />

                }
            </div>
        </div>
    )
}

export default DirectoryTwo