import React from 'react'
import './index.less'
import DirectoryTwo, { DirectoryTwoProps } from '../DirectoryTwo'

type DirectoryThreeProps = {
    id?: string,
    stage?: string,
    title?: string,
    course?: any,
    path?: any
}

const DirectoryThree: React.FC<DirectoryThreeProps> = (props) => {
    const { course } = props



    return (
        <div className='three'>
            <div className='three-stage'>
                <span>{course?.index + 1}</span>
                <span className='titleThree'>{course?.title}</span>
            </div>
            <div className='three-two'>
                {
                    course?.children?.map((item: DirectoryTwoProps, index: number) => {
                        return (
                            <DirectoryTwo course={item} key={index} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DirectoryThree