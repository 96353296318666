import React, { useState, useEffect } from 'react'
import "./index.less"
import RichEditor from '../../../../../RichEditor'


type RemarkItemProps = {
  ifQuest?: boolean   //是否为标题题目
  value?: any
}

const RemarkItem: React.FC<RemarkItemProps> = (props) => {

  const { value, ifQuest } = props

  return (
    <div className='remark-item'>
      <div className='userImg'>
        <img src={value?.userInfo?.avatar} alt="" style={{ width: '28px', height: '28px', marginRight: '4px', marginTop: '2px', borderRadius: '50%' }} />
      </div>
      <div className='userText' style={{ borderColor: ifQuest ? 'rgba(22,119,255,0.30)' : '#E5E5E5' }}>
        <div className='userText-userName' style={{ borderColor: ifQuest ? 'rgba(22,119,255,0.30)' : '#E5E5E5', background: ifQuest ? 'rgba(22,119,255,0.10)' : '#F5F6FA' }}>
          <span>{value?.userInfo?.displayName
          }</span>
          <span style={{
            color: '#999999',
            marginLeft: '10px'
          }}>{value?.createDateTime}</span>
        </div>
        <div className='userText-value'>
          <RichEditor readOnly={true} autoHeight={
            {
              min: 120,
              max: 500
            }
          } isShow={true} defaultValue={value?.content}></RichEditor>
        </div>
      </div>
    </div>
  )
}

export default RemarkItem;
