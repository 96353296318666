import React, { useEffect, useRef, useState } from 'react'
import { Button, message } from 'antd'
import Editor from '@ylzcc/editor'
import { Markdown } from '@ylzcc/textreader'
import Info from './components/Info' //课程详情
import Env from './components/Env' //环境信息
import BlogDirectory from './components/BlogDirectory' //章节目录
import HotAnswer from './components/HotAnswer' //热门问答
import './index.less'
import { getFSSGitlab, getFSSResource, getTemplateEnv } from '../../../../api/modules/blog'
import config from '../../../../config'
import { courseClone, getStudyCourseStatus, getStudyWorkspace } from '../../../../api/modules/study'
import { useDispatch, useSelector } from 'react-redux'
import CreateAnswer from '../../../../components/CreateAnswer'
import { CookieExistsLogin } from '../../../../utils/login'
import { changeIsLogin } from '../../../../store/modules/loginSlice'
import { ifJoin } from '../../../../api/modules/product'
import { getActivityInfo } from '../../../../api/modules/acmExam'

type BlogDetailProps = {
  data?: any //课程信息
  viewStatus: any //预览状态
  ifOpenWorkSpace?: (name?: any) => void //打开工作台
}

const BlogDetail: React.FC<BlogDetailProps> = (props) => {

  const { data, viewStatus, ifOpenWorkSpace } = props
  const dispatch = useDispatch()

  const { userInfo } = useSelector((state: any) => state.loginUser)

  const [show, setShow] = useState(false) // 显示发布问答

  const markdownRef = useRef<any>()
  const hotAnswerRef = useRef<any>()

  // markdown文本数据
  const [mdText, setMDText] = useState<any>()

  // 环境信息
  const [template, setTemplate] = useState<any>()

  // 选中的章节目录 => Markdown文本需要滚动到该标题
  const onSelectedDirectory = (value: any) => {
    if (data?.courseDir?.length && data?.courseDir[0]?.describe?.editMode === 'new') {
      const el = document.getElementById(value.id)
      el?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    } else {
      markdownRef?.current?.getPostion(value, 'blog')
    }
  }

  // base64数据转换
  const base64ToText = (base64: any) => {
    try {
      const binary = atob(base64);
      const uint8Array = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        uint8Array[i] = binary.charCodeAt(i);
      }
      const decoder = new TextDecoder();
      return decoder.decode(uint8Array);
    } catch (error) {
      return ''
    }
  }

  // 获取markdown文本数据
  const fetchFSSResource = () => {
    if (viewStatus) {
      getFSSResource({ repo: data?.repo, path: 'summary/index.md' }).then(res => {
        setMDText(res)
      }).catch(() => { })
    } else {
      getFSSGitlab({ repo: data?.repo, path: 'summary/index.md' }).then(res => {
        if (res?.content) {
          setMDText(base64ToText(res?.content))
        }
      }).catch(() => { })
    }
  }

  // 设置md要使用的本地存储
  const setStorage = (res: any) => {
    window.localStorage.setItem('mark', 'false')
    window.localStorage.setItem('modal', '0')
    window.localStorage.setItem('template', res?.template?.experEnv ? 'true' : 'false')
    window.localStorage.setItem('activeUrl', `${config.openURL}/fs/read?repo=${res.repo}&path=${encodeURIComponent(res?.stat[0]?.path || '')}&sign=''`)
  }

  // 与环境的相关操作 => 进入工作台
  const openWork = () => {
    // Modal.confirm({
    //   title: '加入实验室',
    //   icon: <ExclamationCircleFilled />,
    //   content: '您还未启动环境，请先启动环境后再点击执行。',
    //   okText: '启动环境',
    //   centered: true,
    //   width: 400,
    //   cancelText: '我知道了',
    //   onOk() {
    ifOpenWorkSpace?.()
    //   },
    //   onCancel() {

    //   },
    // })
  };

  // 进入博客详情 => 学习记录（克隆课程）
  const study = () => {
    courseClone({ code: data?.code }).then(res => {
      getStudyWorkspace({
        code: data?.code,
        userid: userInfo.userId,
        modal: 'read'
      })
    }).catch(() => {
      message.error('拉取课程失败')
    })
  }

  // 获取用户课程信息的学习进度(登录状态下)
  const fetchCourseStatus = () => {
    getStudyCourseStatus({ code: data?.code }).then((res: any) => {

      study()
    }).catch(() => { })
  }

  // 获取环境信息
  const fetchExperEnv = () => {
    getTemplateEnv({
      location: data?.template?.experEnv,
      lab: data?.labCode
    }).then(res => {
      setTemplate(res)
    }).catch(() => {

    })
  }

  //发布前判断
  const creatChange = () => {
    if (!CookieExistsLogin()) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }
    ifJoin({ labCode: data?.labCode }).then(res => {
      if (res?.isJoin) {
        setShow(true)
      } else {
        ifOpenWorkSpace?.()
      }
    })
  }

  useEffect(() => {
    if (data?.repo) {
      fetchFSSResource()
      setStorage(data)
      fetchCourseStatus()
    }
  }, [data])

  useEffect(() => {
    if (data?.template?.experEnv) {
      fetchExperEnv()
    }
  }, [data?.template?.experEnv])

  // 标题
  const [header, setHeader] = useState<any>(null)

  return (
    <div className='blog-detail-layout'>
      <div className="blog-detail">
        <div className='blog-detail-left'>
          <Info data={data} />
          {
            mdText ? (
              <>
                <div className='line'></div>
                <div className='blog-md'>
                  {
                    data?.courseDir?.length && data?.courseDir[0]?.describe?.editMode === 'new' ? (
                      <Editor setHeader={setHeader} editable={false} showCatalogue={false} markdownText={mdText} templateInfo={template} onExec={ifOpenWorkSpace} />
                    ) : (
                      <Markdown ref={markdownRef} location={true} type="text" mdText={mdText} getInstruct={(type: string) => {
                        if (type === 'copy') {
                          message.destroy()
                          message.success('复制成功')
                        } else {
                          openWork()
                        }
                      }}
                        preActive={(data: any) => {
                          openWork()
                          return true
                        }}
                        getActivity={async (id: string) => {
                          const initData = await getActivityInfo(id, {
                            file: 'activity.json',
                            path: 'summary',
                            repo: data?.repo
                          }).catch(() => { })
                          return initData
                        }}
                      />

                    )
                  }
                </div>
              </>
            ) : <></>
          }

        </div>
        <div className='blog-detail-right'>
          {
            template ? (
              <div className='blog-detail-right-startEnv'>
                <Button type='primary' onClick={ifOpenWorkSpace}>启动环境</Button>
              </div>
            ) : <></>
          }
          <div className='blog-detail-right-bar' style={{ paddingTop: template ? '80px' : '10px' }}>
            {
              template ? <Env template={template} /> : <></>
            }
            <BlogDirectory editMode={data?.courseDir?.length ? data?.courseDir[0]?.describe?.editMode : ''} mdText={mdText} header={header} onSelected={onSelectedDirectory} />
            <HotAnswer ref={hotAnswerRef} labcode={data?.labCode} code={data?.code} viewStatus={viewStatus} />
          </div>
        </div>
      </div>
      <div className='blog-detail-layout-footer'>
        <img src={require('../../../../assets/images/createanswer.png')} alt="" />
        <span>学习有疑问？</span>
        <span className='span' style={{ cursor: viewStatus ? 'default' : 'pointer' }} onClick={() => {
          if (viewStatus) return
          creatChange()
        }}>发布问答</span>
      </div>
      <CreateAnswer open={show} onClose={() => { setShow(false) }} reValue={() => {
        hotAnswerRef?.current?.refresh?.()
      }} />
    </div>
  );
}

export default BlogDetail;
