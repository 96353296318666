import React from 'react'
import './index.less'
import { useNavigate } from 'react-router-dom'

interface NavItemProps {
    icon: React.ReactNode    // 图标
    title: string            // 标题
    highlight?: boolean      // 是否高亮
    disable?: boolean        // 是否禁用
    url?: string             // 点击的路由地址
}

const NavItem: React.FC<NavItemProps> = (props) => {

    const navigate = useNavigate()

    const { title, icon, highlight = false, disable = false, url = '' } = props

    return <div onClick={() => {!disable && !highlight && url && navigate(url)}} className={disable ? 'navitem-dark navitem' : highlight ? 'navitem-highlight navitem' : 'navitem'}>
        {icon}
        <span className='navitem-title'>
            {title}
        </span>
    </div>
}

export default NavItem