
import { createSlice } from "@reduxjs/toolkit";

export const setpSlice = createSlice({
  name: "setpSlice", // 命名空间，在调用action的时候会默认的设置为action的前缀,保证唯一.不重名
  initialState: {
    setp: 1,
    setpCode: '',
    blogSetp:''
  },
  reducers: {
    // reducer函数 state当前组件的数据 
    //第二个参数为{payload:{},type:"""} 
    // 更新路由
    updateCurrentSetp(state, { payload }) {
      state.setp = payload.setp
    },
    updateCourseCode(state, { payload }) {
      state.setpCode = payload.setpCode
    },
    updateBlogSetp(state, { payload }) {
      state.blogSetp = payload.blogSetp
    }
  },
});
export const { updateCurrentSetp ,updateCourseCode,updateBlogSetp} = setpSlice.actions;

export default setpSlice.reducer;
