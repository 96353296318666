import React from 'react'
import './index.less'

type PriPolicyProps = {
    style?: React.CSSProperties
    className?: string
}

const PriPolicy: React.FC<PriPolicyProps> = (props) => {

    const { style, className = '' } = props

    return (
        <div className={`pripolicy ${className}`} style={style}>
          <span className='pripolicy-left'>登录即代表您已同意</span>
          <span className='pripolicy-right' onClick={() => {
            window.open(`/privacy-details`)
          }}>《服务协议和隐私政策》</span>
        </div>
    )
}

export default PriPolicy