import React, { useState } from 'react';
import './index.less';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { queryDefaultLab } from '../../api/modules/blog';
import { generateHomeUrl } from '../../utils/url';
import { navigateRoutes } from '../../router/admin';

export type ExamNoticeProps = {
  open?: boolean, //是否显示
  value?: any
  onchange: () => void
}

const ExamNotice: React.FC<ExamNoticeProps> = (props) => {
  const navigate = useNavigate()

  const { userInfo } = useSelector((state: any) => state.loginUser);

  return (
    <Modal wrapClassName={'examNotice'} open={props.open} footer={''} width={'400px'} style={{ top: "30%", }}>
      <div className='examNotice-containt'>
        <div className='examNotice-containt-top'>
          <img style={{ width: '22px', height: '22px' }} src={require('../../assets/images/notice.png')} alt="" />
          <span className='text'>考试通知</span>
        </div>
        <div className='examNotice-containt-text'>
          {userInfo?.userphone}，你有{props.value}场考试待参加，请到个人中心-我的考试进行查看。
        </div>
        <div className='examNotice-containt-btns'>
          <div className='examNotice-containt-btns-left'
            onClick={() => {
              props.onchange()
            }}
          >
            稍后再看
          </div>
          <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit" className="examNotice-containt-btns-rigth"
            onClick={async () => {
              const res = await queryDefaultLab().catch(() => {})
              if (!res?.code) return
              window.location.href = generateHomeUrl(`${navigateRoutes?.home?.slice(1)}?labCode=${res?.code}&id=4&navid=2`, res?.type)
              // navigate('/?exam=true')
              props.onchange()
            }}
          >
            去考试
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ExamNotice;