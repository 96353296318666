import React from 'react';
import './index.less';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { joinLab } from '../../api/modules/common';

type InviteModalProps = {
  url: string
  ifshow: boolean
  changeIfshow: () => Promise<void>;
  modal: string
}

const InviteModal: React.FC<InviteModalProps> = (props) => {
  const navigate = useNavigate()
  return (
    <Modal wrapClassName={'inviteModal'} open={props.ifshow} footer={''} width={'400px'} style={{ top: "30%", }}>
      <div className='invitecontant'>
        <div className='invitecontant-top'>
          <img style={{ width: '22px', height: '22px' }} src={require('../../assets/images/inviteNoti.png')} alt="" />
          <span className='invitecontant-top-text'>{props?.modal === '1' ? '我需要你的才华相助！' : '你的好友邀请你一起学习'}</span>
          {/* <span className='invitecontant-top-text'>你的好友邀请你一起学习</span> */}
        </div>
        <div className='invitecontant-text'>
          {props?.modal === '0' ? '点击【一起学习】即可参与~' : '点击【一起创作】和我一起创作吧~'}
        </div>
        <div className='invitecontant-btns'>
          <div className='botton1' onClick={() => {
            props.changeIfshow()
          }}>{props?.modal === '0' ? '不想参加' : '婉拒了'}</div>
          <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit" className="botton2"
            onClick={ async () => {
              await props.changeIfshow()
              window.open(props.url)
              navigate(`/`)
            }}
          >
            {props?.modal === '0' ? '一起学习' : '一起创作'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default InviteModal;