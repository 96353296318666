import { combineReducers } from "redux";

import breadcrumbSlice from "../modules/breadcrumbSlice";
import courseSpeed  from "../modules/courseSpeed";
import loginSlice from "../modules/loginSlice";
import activeSlice  from "../modules/productActive";
import websocketSlice from "../modules/websocketSlice"
import setpSlice  from "../modules/courseStepSlice";
import adminSlice  from "../modules/adminSlice";

export default combineReducers({
  breadcrumb: breadcrumbSlice,
  loginUser:loginSlice,
  webSocket: websocketSlice,
  courseSpeed: courseSpeed,
  activeIng: activeSlice,
  setp: setpSlice,
  blogSetp:setpSlice,
  admin: adminSlice,
});

// 需要本地缓存的key
export const whitelist = [
  'breadcrumb',
  'webSocket',
  'courseSpeed',
  'activeIng',
  'setp',
  'blogSetp',
]
