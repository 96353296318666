
import { configureStore } from '@reduxjs/toolkit'
import storage from "redux-persist/lib/storage"
import { persistStore, persistReducer } from "redux-persist"
import rootReducer, { whitelist } from './reducers'

const persistConfig = {
  key: "root",
  storage,
  whitelist
}


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})


export const persistor = persistStore(store)