import React, { useEffect, useState } from 'react';
import './index.less';
import { Modal } from 'antd';

type LogoType = {
  logoUrl?: ''
  logoList: any
  logoChange: () => void;
  logoShow: any
  seleLgog: (v: any) => void;
}

const LogoSelect: React.FC<LogoType> = (props) => {

  const [hoverActive, setHoverActive] = useState(100);

  const [selectActive, setSelectActive] = useState(0);

  const [logosSelect, setLogosSelect] = useState(0)

  useEffect(() => {
    if (props.logoUrl && props.logoShow) {
      props.logoList?.forEach((e: any, index: any) => {
        if (e === props.logoUrl) {
          setSelectActive(index)
          if (index > 5) {
            setLogosSelect(index - 5)
          }
        }
      })
    }
  }, [props.logoUrl, props.logoShow])

  return (
    <Modal wrapClassName={'logoSelect'} open={props.logoShow} footer={''} width={'100%'} style={{ top: "10%" }}>
      <div className='allLogo'>
        <div className='topLogo'>
          <div className={selectActive > 0 ? 'leftSelect' : 'leftSelectNo'} onClick={() => {
            if (selectActive > 0) {
              setSelectActive(selectActive - 1)
              if (selectActive === logosSelect) {
                setLogosSelect(logosSelect - 1)
              }
              if (selectActive - 5 > logosSelect) {
                setLogosSelect(selectActive - 6)
              }
              if (logosSelect > selectActive) {
                setLogosSelect(selectActive - 1)
              }
            }
          }}>
            {selectActive > 0 ?
              <img className='bigLogo' style={{ width: '48px', height: '49px', transform: 'rotate(180deg)' }} src={require('../../assets/images/nextIcon.png')} alt="" /> :
              <img className='bigLogo' style={{ width: '48px', height: '49px' }} src={require('../../assets/images/noValue.png')} alt="" />
            }
          </div>
          <img className='bigLogo' src={props.logoList[selectActive]
          } alt="" />
          <div className={selectActive === props?.logoList?.length - 1 ? 'rigthSelectNo' : 'rigthSelect'} onClick={() => {
            if (selectActive < props?.logoList?.length - 1) {
              setSelectActive(selectActive + 1)
              if (selectActive + 1 - logosSelect > 5) {
                setLogosSelect(selectActive + 1 - 5)
              }
              if (logosSelect > selectActive + 1) {
                setLogosSelect(selectActive + 1)
              }
            }
          }}>
            {selectActive === props?.logoList?.length - 1 ?
              <img className='bigLogo' style={{ width: '48px', height: '49px', transform: 'rotate(180deg)' }} src={require('../../assets/images/noValue.png')} alt="" /> :
              <img className='bigLogo' style={{ width: '48px', height: '49px', }} src={require('../../assets/images/nextIcon.png')} alt="" />
            }
          </div>
        </div>
        <div className='logoChange'>
          <div className={logosSelect > 0 ? 'leftSelect' : 'leftSelectNo'} onClick={() => {
            if (logosSelect > 0) {
              if (logosSelect > 6) {
                setLogosSelect(logosSelect - 6)
              } else {
                setLogosSelect(0)
              }

            }
          }}>
            {logosSelect > 0 ?
              <img className='bigLogo' style={{ width: '20px', height: '20px', transform: 'rotate(180deg)' }} src={require('../../assets/images/nextIcon.png')} alt="" /> :
              <img className='bigLogo' style={{ width: '20px', height: '20px' }} src={require('../../assets/images/noValue.png')} alt="" />
            }

          </div>
          <div className='imageLsit'>
            <div className='imageLsits' style={{ transition: '0.3s', left: logosSelect * -138 + 'px' }}>
              {props.logoList.map((v: string, index: number) => {
                return (
                  <div className='item' key={index} onClick={() => {
                    setSelectActive(index)
                  }} onMouseEnter={() => setHoverActive(index)}
                    onMouseLeave={() => setHoverActive(100)}>
                    <div style={{ width: '128px', height: '72px', borderRadius: '10px' }}></div>
                    <img src={v} style={{ width: '128px', height: '72px', transition: '0.3s', borderRadius: '10px', position: 'absolute', bottom: index === hoverActive || index === selectActive ? '10px' : '0', border: index === selectActive ? '2px solid #1677ff' : '' }} alt="" />
                  </div>
                )
              })}
            </div>
          </div>
          <div className={logosSelect < props?.logoList?.length - 6 ? 'rigthSelect' : 'rigthSelectNo'} onClick={() => {
            if (logosSelect < props?.logoList?.length - 6) {
              if (logosSelect < props?.logoList?.length - 12) {
                setLogosSelect(logosSelect + 6)
              } else {
                setLogosSelect(props?.logoList?.length - 6)
              }

            }
          }}>
            {logosSelect === props?.logoList?.length - 6 ?
              <img className='bigLogo' style={{ width: '20px', height: '20px', transform: 'rotate(180deg)' }} src={require('../../assets/images/noValue.png')} alt="" /> :
              <img className='bigLogo' style={{ width: '20px', height: '20px', }} src={require('../../assets/images/nextIcon.png')} alt="" />
            }
          </div>
        </div>
        <div className='bottoms'>
          <div className='bottomLeft' onClick={props.logoChange}>取消</div>
          <div className='bottomRigth' onClick={() => {
            props.seleLgog(props.logoList[selectActive])
            props.logoChange()
          }}>确认</div>
        </div>
      </div>
    </Modal>
  );
}

export default LogoSelect;